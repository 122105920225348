<template>
  <v-container>
    <v-row>
      <v-col class="pa-0">
        <v-card>
          <v-form ref="KanbanTaskEditForm" v-model="formValid">
            <base-loading :is-loading="isLoading" />
            <v-alert v-if="successMessage" type="success">
              {{ successMessage }}
            </v-alert>
            <v-alert v-if="errorMessage" type="error">
              {{ errorMessage }}
            </v-alert>
            <v-card-title>
              <v-col cols="12" md="12">
                <h1 class="ml-12">Edit Task</h1>
              </v-col>
              <v-col cols="12" md="12">
                <h2>{{ task.userName }}</h2>
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-model="task.description"
                label="Description"
                outlined
              ></v-textarea>
              <v-col cols="12" md="12" v-if="this.me.role === 'admin'">
                <user-edit-picker
                  :label="'Assigned Consultant'"
                  :initial="task.assignedTo"
                  :chosen-user.sync="task.assignedTo"
                  :role="'consultant'"
                  outlined
                />
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!formValid"
                color="primary"
                class="mx-2"
                @click="[updateKanbanTask(task), refreshClientData()]"
              >
                Save
              </v-btn>
              <v-btn depressed color="error" @click="editTask()"> Close </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import UserEditPicker from "../User/UserEditPicker.vue";

export default {
  name: "TaskEdit",
  props: ["task"],
  components: {
    UserEditPicker,
  },
  data() {
    return {
      me: "",
      successMessage: "",
      errorMessage: "",
      formValid: true,
      isLoading: false,
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
  },
  methods: {
    editTask() {
      this.$emit("closed");
    },
    async refreshClientData() {},
    async updateKanbanTask(task) {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      try {
        const updateTask = await self.$apollo.mutate({
          mutation: gql`
            mutation updateKanbanTask($kanbanTask: KanbanTaskUpdateInput!) {
              updateKanbanTask(kanbanTask: $kanbanTask) {
                id
                updated
              }
            }
          `,
          variables: {
            kanbanTask: {
              id: task.id,
              description: task.description,
              assignedTo: task.assignedTo,
            },
          },
        });
        if (!updateTask) {
          throw "Something went wrong when Updating the kanbanTask!";
        }
        self.successMessage = `Task Updated Successfully`;
        self.$eventHub.$emit("kanbanTask-updated");
        if (updateTask.errors && updateTask.errors.length > 0) {
          throw updateTask.errors[0];
        }
      } catch (e) {
        self.errorMessage = `Task Not Updated / ${e.message}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
