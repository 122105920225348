<template>
  <v-autocomplete
    v-model="chosenDependentRelationship"
    v-bind="$attrs"
    :items="formattedDependentRelationships"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "DependentRelationshipPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenDependentRelationship: "",
    isLoading: false,
    formattedDependentRelationships: [
      { text: "Spouse", value: "Spouse" },
      { text: "Mother", value: "Mother" },
      { text: "Father", value: "Father" },
      { text: "Son", value: "Son" },
      { text: "Daughter", value: "Daughter" },
      { text: "Grand-Parent", value: "Grand-Parent" },
      { text: "Brother", value: "Brother" },
      { text: "Sister", value: "Sister" },
      { text: "Brother-in-law", value: "Brother-in-law" },
      { text: "Sister-in-law", value: "Sister-in-law" },
      { text: "Grand Child", value: "Grand Child" },
      { text: "Fiance", value: "Fiance" },
      { text: "Common Law Spouse", value: "Common Law Spouse" },
      { text: "Traditional Marriage", value: "Traditional Marriage" },
      { text: "Married in Community of Property", value: "Married in Community of Property" },
      { text: "Marriage out of community of property without accrual", value: "Marriage out of community of property without accrual" },
      { text: "Marriage out of community of property with accrual", value: "Marriage out of community of property with accrual" },
      { text: "StepSon", value: "StepSon" },
      { text: "StepDaughter", value: "StepDaughter" },
      { text: "Legal Guardian", value: "Legal Guardian" },
    ],
  }),
  watch: {
    chosenDependentRelationship(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-dependent-relationship",
          this.chosenDependentRelationship
            ? this.chosenDependentRelationship
            : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenDependentRelationship = this.initial)
      : (this.chosenDependentRelationship = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
