<template>
  <div>
    <div>
      <v-form>
        <p class="text-right">
          Client:
          <strong>
            {{ user.firstName }}
            {{ user.lastName ? user.lastName : "" }}
            {{ user.brokerCommission }}
          </strong>
        </p>

        <v-text-field v-model="broker" label="Broker" outlined></v-text-field>
        <div
          v-for="(item, index) in formData"
          :key="index"
          class="bordered-block"
        >
          <base-product-picker
            :chosen-product.sync="item.product"
            :initial="item.product"
            label="Product"
            required
            outlined
            :rules="[(v) => !!v || 'Product is required']"
          />

          <v-text-field
            v-model="item.premium"
            label="Product Premium"
            outlined
            type="number"
            prefix="R"
          ></v-text-field>

          <v-text-field
            v-model="item.commission"
            label="Commission"
            outlined
            type="number"
            prefix="R"
          ></v-text-field>

          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                v-if="formData.length > 1"
                fab
                x-small
                @click="removeField(index)"
                v-on="on"
                color="error"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <span>Remove product and broker commission</span>
          </v-tooltip>
        </div>
      </v-form>
    </div>

    <div class="mt-2 mr-2">
      <v-row>
        <div>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn fab x-small @click="addField" v-on="on" color="primary">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add product and broker commission</span>
          </v-tooltip>
        </div>
      </v-row>
    </div>
    <div class="text-right">
      <v-btn color="primary" @click="save">Save</v-btn>
    </div>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "Commission",
  props: {
    userProduct: {
      type: Object,
      default: function () {
        return null;
      },
    },

    user: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },

  data() {
    return {
      formData: [],
    };
  },
  computed: {
    broker() {
      return this.user.consultantName
        ? this.user.consultantName
        : "Not yet assigned";
    },
  },
  mounted() {
    // Log the entire user object
    console.log("User data:", this.user);

    // Check if brokerCommission exists and is in the expected format
    if (this.user.brokerCommission && this.user.brokerCommission.length > 0) {
      const firstEntry = this.user.brokerCommission[0][0]; // Accessing the first object
      this.formData = [
        {
          product: firstEntry.product || "",
          commission: firstEntry.commission || 0,
          premium: firstEntry.premium || 0,
        },
      ];
    } else {
      this.formData = [
        {
          product: "",
          commission: 0,
          premium: 0,
        },
      ];
    }

    // Log the initial formData to check if it's populated correctly
    console.log("Initial formData:", this.formData);
  },
  methods: {
    addField() {
      this.formData.push({
        product: "",
        commission: 0,
        premium: 0,
      });
    },
    removeField(index) {
      this.formData.splice(index, 1);
    },

    async save() {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: this.user.id,
              brokerCommission: this.formData,
            },
          },
        });

        if (response.errors && response.errors.length > 0) {
          throw new Error(response.errors[0].message);
        }

        if (
          response.data &&
          response.data.updateUser &&
          response.data.updateUser.id
        ) {
          this.$swal(
            "Success",
            "Product Commission has been updated",
            "success"
          );
          this.$emit("close-dialog");
        } else {
          throw new Error("Invalid response structure received!");
        }
      } catch (error) {
        console.error("An error occurred while updating the user:", error);
        this.$swal(
          "Error",
          error.message || "An unknown error occurred.",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.bordered-block {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}
</style>
