<template>
  <base-stats-card
    :color="'#feb809'"
    title="No. of Open events"
    :value="totalCount"
    :elevation="8"
    sub-icon="mdi-clock"
    :sub-text="subText"
    :is-loading="isLoading"
  />
</template>

<script>
import { GET_USERS } from "../../../../queries";
import Moment from "moment";
import { gql } from "apollo-boost";
export default {
  name: "TotalOpenEventsStatisticsCard",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    isLoading: false,
    subText: "",
    events: "",
  }),
  apollo: {
    users: {
      query: GET_USERS,
      variables() {
        return {
          limit: this.limit,
          page: this.page,
          query: { progress: "Call Back - Quoted" },
        };
      },
      update: (data) => {
        const records = data.users.records.map((record) => ({
          role: record.role,
          id: record.id,
          telNumber: record.telNumber,
          idNumber: record.idNumber,
          firstName: record.firstName,
          lastName: record.lastName,
          consultant: record.consultant,
          consultantName: record.consultantName,
          source: record.source,
          referral: record.referral,
          email: record.email,
          branch: record.branch,
          branchName: record.branchName,
          createdAt: record.createdAt,
          updatedAt: record.updatedAt,
          progress: record.progress,
          company: record.company,
          companyName: record.companyName,
        }));

        const totalCount = data.users.records.length;
        console.log(totalCount);

        return {
          records,
          totalCount,
        };
      },
    },
  },
  async created() {},
  methods: {},
};
</script>

<style lang="sass"></style>
