<template>
  <v-autocomplete
    v-model="chosenUser"
    v-bind="$attrs"
    :items="formattedUsers"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
    :initial-value="initial"
  />
</template>

<script>
import { gql } from "apollo-boost";
import { debounce } from "lodash";

export default {
  name: "UserEditPicker",
  props: {
    initial: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chosenUser: "",
      isLoading: false,
      formattedUsers: [],
    };
  },

  apollo: {
    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              role
            }
          }
        }
      `,
      variables() {
        const query = { role: "consultant" };
        return {
          limit: 50, // Adjust the limit to a reasonable value
          query,
        };
      },
      debounce: 300, // Debounce the API call with a delay of 300 milliseconds
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result({ data }) {
        const { records } = data.users;
        this.formattedUsers = records.map((user) => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user.id,
        }));
      },
    },
  },
  created() {
    this.$eventHub.$on("user-updated", this.refreshData);
  },

  beforeDestroy() {
    this.$eventHub.$off("user-updated", this.refreshData);
  },
  watch: {
    chosenUser(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:chosen-user", newValue || "");
      }
    },
  },
  mounted() {
    this.chosenUser = this.initial || "";
  },
  methods: {
    refreshData: debounce(function () {
      console.log("Refetching users");
      this.$apollo.queries.users.refetch();
    }, 300),
  },
};
</script>

<style lang="sass"></style>
