<template>
  <base-material-card
  class="mt-4"
    title="User Branches"
    color="'#feb809'"
    :elevation="8"
  >
    <base-bar
      style="height:45vh;"
      :chart-data="userBranches"
      :bind="true"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: {position:'bottom'},
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
            },
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
            },
          }]
        },
      }"
    />
  </base-material-card>
</template>

<script>
  import { gql } from 'apollo-boost'
  export default {
    name: 'UsersPerBranchGraph',
    apollo: {
      me: gql`query {
        me {
          id
          email
          role
        }
      }`,
      users: {
        query: gql`query Users($limit: Int! $skip: Int! $query: JSON!) {
          users(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              branch
              branchName
           
            }
            count
          }
        }`,
        variables () {
          const limit = 100000
          const skip = 0
          var query = { }
          return {
            limit: limit,
            skip: skip,
            query: query,
          }
        },
        watchLoading (isLoading, countModifier) {
          this.isLoading = isLoading
        },
        result (data, key) {
          var userRecords = data.data.users.records
          var groupedByUser = _.groupBy(userRecords, 'branchName')
          console.log(groupedByUser)
          this.usersGroupedByUser = groupedByUser
        },
      },
    },
    data: () => ({
      selected: '',
      users: '',
      usersGroupedByUser: [],
    }),
    computed: {
      labels () {
        return _.map(this.usersGroupedByUser, (item, index) => {
          if (index !== 'null') {
            return index
          }
          return 'Not Assigned'
        })
      },
      chartdata () {
        return _.map(this.usersGroupedByUser, (item, index) => {
          return item.length
        })
      },
      userBranches () {
        return {
          labels: this.labels,
          datasets: [
            {
              label: 'User dispertion',
              borderColor: '#888',
              backgroundColor: this.getRandomColors(),
              borderWidth: 2,
              data: this.chartdata,
            },
          ],
        }
      },
    },
    async mounted () {
    },
    methods: {
      getRandomColors () {
        return _.map(this.usersGroupedByUser, (item) => {
          var letters = '0123456789ABCDEF'.split('')
          var color = '#'
          for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)]
          }
          return color
        })
      },
    },
  }
</script>

<style lang="sass">
</style>