<template>
  <div>
    <v-menu
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          label="Pick date"
          prepend-inner-icon="mdi-calendar"
          readonly
          solo
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    initial: {
      type: Number,
      default: function () {
        return null;
      },
    },
  },
  name: "DefaultIcons",
  data() {
    return {
      date: null,

      // new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      menu2: false,
    };
  },
  methods: {
    // changeOb() {
    //   this.$emit("update:sizeObject", this.sizeObject);
    // },
  },
  computed: {},
  mounted() {
    // this.sizeObject = this.initial;
    if (this.initial && this.initial != 0) {
      this.date = new Date(
        this.initial - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    } else {
      this.date = null;
    }
  },

  watch: {
    initial(newVal) {
      // alert(newVal);
      if (this.initial && this.initial != 0) {
        this.date = new Date(newVal - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
      } else {
        this.date = null;
      }
      //Date.now()
    },
    date(newVal) {
      // Date(date)?.getTime()
      this.$emit("update:date", new Date(newVal)?.getTime());
    },
  },
};
</script>
<style lang="scss" scoped></style>
