import { loadView } from "./functions";

export const ConsultantChildren = {
  children: [
    {
      path: "dashboard",
      name: "User Dashboard",
      component: loadView("Consultant/Dashboard"),
      ...userMeta(),
    },
    {
      path: "profile",
      name: "Profile",
      component: loadView("Consultant/Profile"),
      ...userMeta(),
    },
    {
      path: "users",
      name: "Users",
      component: loadView("Consultant/Users/View"),
      ...userMeta(),
    },
    {
      path: "user/:id",
      name: "User Details",
      component: loadView("Consultant/Users/Details"),
      ...userMeta(),
    },
    {
      path: "users/leads",
      name: "ViewConsultantsLeads",
      component: loadView("Consultant/Users/Leads"),
      ...userMeta(),
    },
    {
      path: "users/dead-leads",
      name: "ViewDeadLeads",
      component: loadView("Consultant/Users/Dead"),
      ...userMeta(),
    },
    {
      path: "users/call-back-quoted",
      name: "ViewLeadsCallBackQuoted",
      component: loadView("Consultant/Users/CallBackQuoted"),
      ...userMeta(),
    },
    {
      path: "user/:id/edit",
      name: "User Edit",
      component: loadView("Consultant/Users/Edit"),
      ...userMeta(),
    },
    {
      path: "companies",
      name: "Companies",
      component: loadView("Consultant/Companies/View"),
      ...userMeta(),
    },
    {
      path: "company/:id",
      name: "Company Details",
      component: loadView("Consultant/Companies/Details"),
      ...userMeta(),
    },
    {
      path: "company/:id/edit",
      name: "Company Edit",
      component: loadView("Consultant/Companies/Edit"),
      ...userMeta(),
    },
  ],
};

function userMeta() {
  return {
    meta: { authorize: ["admin", "consultant"] },
  };
}
