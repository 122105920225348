<template>
  <v-container>
    <v-card class="my-0">
      <v-card-text>
        <v-form ref="KanbanTaskCreateForm" v-model="formValid">
          <base-loading :is-loading="isLoading" />
          <v-alert v-if="successMessage" type="success">
            {{ successMessage }}
          </v-alert>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>

          <v-card-title>
            <v-col cols="12" sm="12">
              <h1 class="ml-6">Create a new task</h1>
            </v-col>
          </v-card-title>
          <v-row class="mx-1 my-8">
            <base-kanban-picker
              :label="'Select Client'"
              :initial="kanbanTask.user"
              :chosen-user.sync="kanbanTask.user"
              outlined
            />
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" md="12">
              <v-textarea
                v-model="kanbanTask.description"
                color="primary"
                label="Description"
                counter
                clearable
                auto-grow
                rows="4"
                outlined
              />
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" md="12">
              <user-edit-picker
                :label="'Assigned Consultant'"
                :initial="kanbanTask.assignedTo"
                :chosen-user.sync="kanbanTask.assignedTo"
                :role="'consultant'"
                outlined
              />
            </v-col>
            <v-col cols="12" md="12" />
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!formValid"
          color="primary"
          class="mx-2"
          @click="[saveKanbanTask()]"
        >
          Save
        </v-btn>
        <v-btn color="error" depressed @click="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import UserEditPicker from "../User/UserEditPicker.vue";

export default {
  name: "KanbanTaskCreate",
  documents: [],
  components: {
    UserEditPicker,
  },
  data: () => {
    return {
      me: "",
      isLoading: false,
      formValid: true,
      kanbanTask: {
        user: "",
        description: "",
        assignedTo: "",
        status: "ToDo",
        createdBy: "",
      },
      successMessage: "",
      errorMessage: "",
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
  },

  computed: {},
  async mounted() {},
  methods: {
    close() {
      this.$refs.KanbanTaskCreateForm.reset();
      //
      this.$emit("closed");
    },
    async refreshClientData() {},
    async refreshPage() {},
    async saveKanbanTask() {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.KanbanTaskCreateForm.validate()) {
        try {
          const { user, description, assignedTo, status } = self.kanbanTask;
          const createdKanbanTask = await this.$apollo.mutate({
            mutation: gql`
              mutation ($kanbanTask: KanbanTaskCreateInput!) {
                createKanbanTask(kanbanTask: $kanbanTask) {
                  id
                }
              }
            `,
            variables: {
              kanbanTask: {
                user,
                description,
                assignedTo,
                status,
                createdBy: this.me.id,
              },
            },
            watchLoading(isLoading, countModifier) {
              self.isLoading = isLoading;
            },
          });
          if (!createdKanbanTask) {
            return "Something went wrong when creating the Task!";
          }
          this.$swal(
            "Success!",
            "Event was successfully created, refreshing the page.",
            "success"
          );

          self.$eventHub.$emit("kanbanTask-updated");
        } catch (error) {
          self.errorMessage = `Task Not Created / ${error.message}`;
        }
      } else {
        self.errorMessage = `Task Not Created/ All required fields must be completed.`;
      }
    },
  },
};
</script>

<style lang="sass"></style>
