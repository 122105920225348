<template>
  <div>
    <!-- Button to open the dialog -->
    <v-btn class="mt-2" small @click="dialog = true"
      >Upload documents to company</v-btn
    >

    <!-- Dialog containing the form -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload Documents</span>
        </v-card-title>

        <v-card-text>
          <p class="ml-2"><b>Step 1: Search a company name </b></p>
          <company-picker :label="'Company'" :chosen-company.sync="company" />
          <new-product-picker
            v-if="company"
            @selected-product="handleProductSelected"
          />
          <div v-if="product">
            <v-file-input
              class="ma-4"
              v-model="selectedFiles"
              :prepend-icon="null"
              label="Click here to select PDF files (max 10MB each)"
              accept=".pdf"
              multiple
              :rules="fileRules"
              outlined
            >
            </v-file-input>
            <div class="my-4">
              <div v-for="(file, index) in selectedFiles" :key="index">
                <span class="ma-4">
                  <v-icon>mdi-file</v-icon>{{ file.name }}
                  <v-icon @click="removeFile(index)">mdi-close</v-icon>
                </span>
              </div>
            </div>
            <div class="text-right">
              <v-btn
                @click="uploadFilesToPublito"
                :disabled="selectedFiles.length === 0 || uploading"
                x-small
              >
                Upload
              </v-btn>
            </div>
          </div>
          <v-progress-linear
            v-if="uploading"
            :value="totalUploadProgress"
            height="11"
            rounded
          >
            <span class="my-2">{{ totalUploadProgress }}%</span>
          </v-progress-linear>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import ClientPicker from "./Pickers/ClientPicker.vue";
import UserProductPicker from "./Pickers/UserProductPicker.vue";
import PublitioAPI from "publitio_js_sdk";
import CompanyPicker from "./Pickers/CompanyPicker.vue";
import NewProductPicker from "./Pickers/NewProductPicker.vue";

export default {
  components: {
    ClientPicker,
    UserProductPicker,
    CompanyPicker,
    NewProductPicker,
  },
  name: "CompanyUploadFile",

  data() {
    return {
      dialog: false,
      document: {
        company: null,
      },
      file: null,
      progress: null,
      company: "",
      product: "",
      maxFileSize: 10 * 1024 * 1024, // 10 MB
      productItems: [],
      publitio: null,
      selectedFile: null,
      selectedFiles: [],
      fileRules: [
        (value) => !!value || "Files are required",
        (value) =>
          value.every((file) => file.size <= 10 * 1024 * 1024) ||
          "File size must be less than 10MB each",
      ],
      uploading: false,
      uploadProgress: [],
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
  },
  computed: {
    fileTooLarge() {
      return this.file && this.file.size > this.maxFileSize;
    },
    totalUploadProgress() {
      if (this.uploadProgress.length === 0) return 0;

      const totalProgress =
        this.uploadProgress.reduce((acc, cur) => acc + cur, 0) /
        this.uploadProgress.length;

      return Math.round(totalProgress);
    },
  },
  methods: {
    formatSize(size) {
      const units = ["B", "KB", "MB", "GB", "TB"];
      let unitIndex = 0;
      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }
      return size.toFixed(2) + " " + units[unitIndex];
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    handleUserSelected(userId) {
      this.user = userId;
    },
    handleProductSelected(ProductId) {
      this.product = ProductId;
    },
    handleFileChange() {
      const fileInput = this.$refs.fileInput.$el.querySelector("input");
      const selectedFile = fileInput.files[0];
      if (selectedFile) {
        this.uploadDocument(selectedFile);
      }
    },
    async handleFileDrop(event) {
      event.preventDefault();
      const droppedFile = event.dataTransfer.files[0];
      if (droppedFile) {
        this.uploadDocument(droppedFile);
      }
    },
    async uploadFilesToPublito() {
      if (this.selectedFiles.length === 0) {
        return;
      }

      this.uploading = true;
      this.uploadProgress = new Array(this.selectedFiles.length).fill(0);
      let successfulUploads = 0;

      try {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          const file = this.selectedFiles[i];
          const uploadResult = await this.uploadFile(file);
          const document = {
            name: file.name,
            publitoDownloadUrl: uploadResult.url_download,
            publitoEmbeddUrl: uploadResult.url_embed,
            publitoUrl: uploadResult.url_preview,
            company: this.company,
            userProduct: this.userProduct,
            createdBy: this.me.id,
          };
          await this.uploadDocument(file, document);
          this.uploadProgress[i] = 100;
          successfulUploads++;
        }
        if (successfulUploads === this.selectedFiles.length) {
          this.$swal(
            "Upload Complete",
            "The document(s) were successfully uploaded",
            "success"
          );
          this.company = "";
          this.product = "";
          this.selectedFiles = [];
          this.dialog = false;
          this.$emit("upload-dialog", false);
          this.$emit("refresh");
        }
      } catch (error) {
        console.error("An error occurred during upload:", error);
        this.$swal("Upload Error", "An error occurred during upload", "error");
      } finally {
        this.uploading = false;
      }
    },
    async uploadFile(file) {
      try {
        const uploadResult = await this.publitio.uploadFile(file, "file");
        return uploadResult;
      } catch (error) {
        console.error("An error occurred during file upload:", error);
        throw error;
      }
    },
    async uploadDocument(file, document) {
      try {
        this.progress = 0;
        document.publitoDownloadUrl = document.publitoDownloadUrl;
        document.publitoEmbeddUrl = document.publitoEmbeddUrl;
        document.publitoUrl = document.publitoUrl;

        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation createDocument($document: DocumentCreateInput!) {
              createDocument(document: $document) {
                id
                name
                publitoDownloadUrl
                publitoEmbeddUrl
                publitoUrl
                userProduct
                company
                createdBy
              }
            }
          `,
          variables: {
            document,
          },
          uploadProgress: (progressEvent) => {
            this.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        });
        if (!response || response.errors) {
          console.log("error", response.errors);
          throw new Error("Something went wrong when creating the document!");
        }
        this.$emit("refresh");
        this.progress = null;
      } catch (e) {
        console.error(e);
      }
    },
  },
  created() {
    this.publitio = new PublitioAPI(
      process.env.VUE_APP_PUBLITO_API_KEY,
      process.env.VUE_APP_PUBLITO_API_SECRET
    );
  },
};
</script>

<style>
.error-text {
  color: red;
}
</style>
