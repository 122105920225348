<template>
  <v-autocomplete
    v-model="chosenIndustry"
    v-bind="$attrs"
    :items="formattedIndustries"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "IndustryPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenIndustry: "",
    isLoading: false,
    formattedIndustries: [
      { text: "Information Technology", value: "Information Technology" },
      { text: "Construction", value: "Construction" },
      { text: "Corporate Finance", value: "Corporate Finance" },
      { text: "Marketing", value: "Marketing" },
      { text: "Advertising", value: "Advertising" },
      { text: "Banking", value: "Banking" },
      { text: "Human Resources", value: "Human Resources" },
      { text: "Film and Media", value: "Film and Media" },
      { text: "Telecommunications", value: "Telecommunications" },
      { text: "Sales", value: "Sales" },
    ],
  }),
  watch: {
    chosenIndustry(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-industry",
          this.chosenIndustry ? this.chosenIndustry : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenIndustry = this.initial)
      : (this.chosenIndustry = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
