<template>
  <v-autocomplete
    v-model="chosenStatus"
    v-bind="$attrs"
    :items="formattedStatuses"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
  export default {
    name: 'StatusPicker',
    props: {
      initial: {
        type: String,
        default: function () {
          return ''
        },
      },
    },
    data: () => ({
      chosenStatus: '',
      isLoading: false,
      formattedStatuses: [
        { text: 'Admin', value: 'admin' },
        { text: 'Consultant', value: 'consultant' },
        { text: 'Lead', value: 'lead' },
         { text: 'User', value: 'user' },
      ],
    }),
    watch: {
      chosenStatus (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('update:chosen-status', this.chosenStatus ? this.chosenStatus : '')
        }
      },
    },
    async mounted () {
      this.initial ? this.chosenStatus = this.initial : this.chosenStatus = ''
    },
    methods: {
    },
  }
</script>

<style lang="sass">
</style>
