<template>
  <v-autocomplete
    v-model="chosenFicaProduct"
    v-bind="$attrs"
    :items="formattedFicaProducts"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "FicaProductPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenFicaProduct: "",
    totalRecords: 0,
    isLoading: false,
    returnedFicaProducts: [],
    formattedFicaProducts: [],
  }),
  apollo: {
    products: {
      query: gql`
        query Products($limit: Int!, $skip: Int!, $query: JSON!) {
          products(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              insurerName
            }
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          skip: 0,
           query: { description: "Fica Bundle" },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedFicaProducts = data.products.records;
        this.formattedFicaProducts = _.map(
          this.returnedFicaProducts,
          (product) => {
            return { text: `${product.name}`, value: product.id };
          }
        );
      },
    },
  },
  watch: {
    chosenFicaProduct(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-fica-product",
          this.chosenFicaProduct ? this.chosenFicaProduct : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenFicaProduct = this.initial)
      : (this.chosenFicaProduct = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
