<template>
  <v-autocomplete
    v-model="chosenInsurer"
    v-bind="$attrs"
    :items="formattedInsurers"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "InsurerPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return null;
      },
    },
  },
  data: () => ({
    chosenInsurer: null,
    totalRecords: 0,
    isLoading: false,
    returnedInsurers: [],
    formattedInsurers: [],
  }),
  apollo: {
    insurers: {
      query: gql`
        query Insurers($limit: Int!, $skip: Int!, $query: JSON!) {
          insurers(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedInsurers = data.insurers.records;
        this.formattedInsurers = _.map(this.returnedInsurers, (insurer) => {
          return {
            text: `${insurer.name}`,
            value: insurer.id,
          };
        });
      },
    },
  },
  watch: {
    initial(newValue, oldValue) {
      this.chosenInsurer = this.initial;
    },
    chosenInsurer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-insurer",
          this.chosenInsurer ? this.chosenInsurer : null
        );
      }
    },
  },
  async mounted() {},
};
</script>

<style lang="sass"></style>
