<template>
  <base-material-card
    title="Users Created At Graph"
    color="green"
    :elevation="8"
  >
    <base-bar
      style="height:45vh;"
      :chart-data="userGroupedByCreatedAt"
      :bind="true"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
      }"
    />
  </base-material-card>
</template>

<script>
import { gql } from "apollo-boost";
import moment from 'moment'
export default {
  name: "UsersCreatedAtGraph",
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          role
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int!, $skip: Int!, $query: JSON!) {
          users(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              role
              createdAt
            }
            count
          }
        }
      `,
      variables() {
        const limit = 100000;
        const skip = 0;
        var query = { role: "lead" };

        return {
          limit: limit,
          skip: skip,
          query: query,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(data, key) {
        var userRecords = data.data.users.records;
        var createdAtDates
        var groupedByCreatedAt = _.groupBy(userRecords, "createdAt");
        for (var i = 0; i < this.users.count; i++) {
         this.createdAtDates.push(moment(this.users.records[i].createdAt).format('DD/MM/YYYY hh:mm'));
        console.log(createdAtDates)}

        this.groupedByCreatedAt = groupedByCreatedAt;
      },
    },
  },
  data: () => ({
    selected: "",
    users: "",
    groupedByCreatedAt: [],
  }),
  computed: {
    labels() {
      return _.map(this.groupedByCreatedAt, (item, index) => {
        if (index !== "null") {
          return index;
        }
        return "Not Assigned";
      });
    },
    chartdata() {
      return _.map(this.groupedByCreatedAt, (item, index) => {
        return item.length;
      });
    },
    userGroupedByCreatedAt() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "Count",
            borderColor: "#888",
            backgroundColor: this.getRandomColors(),
            borderWidth: 2,
            data: this.chartdata,
          },
        ],
      };
    },
  },
  async mounted() {},
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDD");
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (isDesc !== "false") {
          return a[index] < b[index] ? -1 : 1;
        } else {
          return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    getRandomColors() {
      return _.map(this.serviceLocationCostAnalysis, (item) => {
        var letters = "0123456789ABCDEF".split("");
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      });
    },
  },
};
</script>

<style lang="sass"></style>
