<template>
  <v-autocomplete
    v-model="chosenTitleInternal"
    v-bind="$attrs"
    :items="formattedTitles"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "TitlePicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
    chosenTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      formattedTitles: [
        { text: "MR", value: "MR" },
        { text: "MRS", value: "MRS" },
        { text: "MISS", value: "MISS" },
        { text: "DR", value: "DR" },
        { text: "Other", value: "Other" },
      ],
    };
  },
  computed: {
    chosenTitleInternal: {
      get() {
        return this.chosenTitle;
      },
      set(value) {
        if (value !== this.chosenTitle) {
          this.$emit("update:chosen-title", value || "");
        }
      },
    },
  },
  watch: {
    initial: {
      immediate: true,
      handler(newValue) {
        this.chosenTitleInternal = newValue;
      },
    },
  },
  mounted() {
    this.chosenTitleInternal = this.initial;
  },
  methods: {},
};
</script>

<style lang="sass"></style>
