<template>
  <v-autocomplete
    v-model="chosenProductCategory"
    v-bind="$attrs"
    :items="formattedProductCategories"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "ProductCategoryPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenProductCategory: "",
    isLoading: false,
    formattedProductCategories: [
      { text: "Medical Aid", value: "Medical Aid" },
      { text: "Life Insurance", value: "Life Insurance" },
      { text: "Gap Cover", value: "Gap Cover" },
      { text: "Investments", value: "Investments" },
      { text: "Short Term Insurance", value: "Short Term Insurance" },
      { text: "Medical Insurance", value: "Medical Insurance" },
      { text: "Loyalty Programme", value: "Loyalty Programme" },
      {
        text: "Short Term Insurance Commercial Lines",
        value: "Short Term Insurance Commercial Lines",
      },
      {
        text: "Short Term Insurance Personal Lines",
        value: "Short Term Insurance Personal Lines",
      },
      { text: "Other", value: "Other" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenProductCategory = this.initial;
    },
    chosenProductCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-product-category",
          this.chosenProductCategory ? this.chosenProductCategory : ""
        );
      }
    },
  },
  async mounted() {},
  methods: {},
};
</script>

<style lang="sass"></style>
