export class Event {
  constructor() {
    this.id = "";
    this.type = "";
    this.comment = "";
    this.user = null;
    this.status = "open";
    this.startDate = null;
    this.endDate = null;
    this.createdBy = "";
  }
}
