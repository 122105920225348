<template>
  <v-autocomplete
    v-model="chosenRelationshipStatus"
    v-bind="$attrs"
    :items="formattedRelationshipStatuses"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "RelationshipStatusPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
    chosenRelationshipStatus: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      formattedRelationshipStatuses: [
        { text: "Married", value: "Married" },
        { text: "Single", value: "Single" },
        { text: "Divorced", value: "Divorced" },
        { text: "Widowed", value: "Widowed" },
      ],
    };
  },
  watch: {
    initial: {
      immediate: true,
      handler(newValue) {
        this.chosenRelationshipStatus = newValue;
      },
    },
    chosenRelationshipStatus(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:chosen-relationship-status", newValue || "");
      }
    },
  },
  mounted() {
    this.chosenRelationshipStatus = this.initial;
  },
  methods: {},
};
</script>

<style lang="sass"></style>
