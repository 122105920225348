<template>
  <v-autocomplete
    v-model="chosenFicaProductCategory"
    v-bind="$attrs"
    :items="formattedFicaProductCategories"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "FicaProductCategoryPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenFicaProductCategory: "",
    isLoading: false,
    formattedFicaProductCategories: [
      { text: "Id Copy", value: "Id Copy" },
      { text: "Proof of Banking", value: "Proof of Banking" },
      { text: "Proof of Address", value: "Proof of Address" },
      { text: "Contract", value: "Contract" },
      { text: "Other", value: "Other" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenFicaProductCategory = this.initial;
    },
    chosenFicaProductCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-fica-product-category",
          this.chosenFicaProductCategory ? this.chosenFicaProductCategory : ""
        );
      }
    },
  },
  async mounted() {},
  methods: {},
};
</script>

<style lang="sass"></style>
