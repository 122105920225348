<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-spacer></v-spacer>
        <div class="mx-auto text-right">
          <v-btn
            class="mx-auto"
            color="primary"
            small
            v-bind="attrs"
            @click="newEvent()"
            >New Event</v-btn
          >
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ `${isEdit ? "Edit" : "New"} Event` }} </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <base-event-type-picker
                  :chosen-event-type.sync="eventModel.type"
                  :initial="eventModel.type"
                  label="Event Type"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Event Type is required']"
                />
              </v-col>
              <v-col cols="12">
                <base-event-status-picker
                  :chosen-event-status.sync="eventModel.status"
                  :initial="eventModel.status"
                  label="Event Status"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Event Status is required']"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="eventModel.comment"
                  label="Comment"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <base-date-time-picker
                  :label="'Start Date and Time'"
                  :epoch-date-time.sync="eventModel.startDate"
                  :initial="eventModel.startDate"
                />
              </v-col>
              <v-col cols="12">
                <base-date-time-picker
                  :label="'End Date and Time'"
                  :epoch-date-time.sync="eventModel.endDate"
                  :initial="eventModel.endDate"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialog = false"> Cancel </v-btn>
          <v-btn v-if="isEdit" color="secondary" @click="updateEvent">
            Update
          </v-btn>
          <v-btn v-else color="secondary" @click="createEvent"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="mt-3">
      <v-data-table
        :headers="headers"
        :items="events.records"
        :loading="isLoading"
        :search="search"
        transition="scale-transition"
      >
        <template v-slot:top> </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" small class="mr-2" @click="editEvent(item)"
            >mdi-pencil</v-icon
          >
        </template>
        <template v-slot:item.startDate="{ item }">
          <h3 color="primary">
            Start:{{ item.startDate | getFormattedDateNoSeconds }}
            <br />
            End:{{ item.endDate | getFormattedDateNoSeconds }}
          </h3>
        </template>
      </v-data-table>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { Event } from "@/models/Event";
// import { mapState } from 'vuex'
export default {
  name: "UserEvents",
  props: {
    userId: {
      type: String,
      default: function () {
        return null;
      },
    },
    consultantId: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type",
        },
        { text: "Comment", value: "comment" },
        { text: "Status", value: "status" },
        { text: "Times", value: "startDate" },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      eventModel: new Event(),
      dialog: false,
      isEdit: false,
      search: "",
      events: [],
      totalRecords: 0,
      isLoading: false,
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  created() {
    this.eventModel.user = this.userId;
  },
  watch: {
    userId(newValue, oldValue) {
      this.eventModel.user = this.userId;
    },
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    events: {
      query: gql`
        query Events($limit: Int!, $skip: Int!, $query: JSON!) {
          events(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              type
              comment
              user
              status
              startDate
              endDate
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: { user: this.userId },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
    eventStatuses: {
      query: gql`
        query {
          __type(name: "EventStatus") {
            eventStatuses: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
  },
  methods: {
    showSnack(snackColor, snackText) {
      this.snack = true;
      this.snackColor = snackColor;
      this.snackText = snackText;
    },
    close() {},
    async createEvent() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createEvent($event: EventCreateInput!) {
              createEvent(event: $event) {
                id
              }
            }
          `,
          variables: {
            event: {
              type: self.eventModel.type,
              comment: self.eventModel.comment,
              user: self.eventModel.user,
              status: self.eventModel.status,
              startDate: self.eventModel.startDate,
              endDate: self.eventModel.endDate,
              createdBy: self.me.id,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Event!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createEvent) {
          self.createEvent.id = response?.data?.createEvent?.id;
          this.$swal("Success!", "Event was successfully created", "success");
        }
      } catch (e) {
        this.$swal(
          "Error!",
          `Something went wrong when creating the Event, check data and try again later!`,
          "error"
        );
      }
    },
    async updateEvent() {
      const self = this;
      try {
        if (
          self.eventModel.startDate &&
          self.eventModel.endDate &&
          self.eventModel.endDate < self.eventModel.startDate
        ) {
          this.$swal(
            "Error!",
            "Please check your start and end date. Your end date cannot come before your start date.",
            "success"
          );
          return;
        }
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($event: EventUpdateInput!) {
              updateEvent(event: $event) {
                id
                updated
              }
            }
          `,
          variables: {
            event: {
              id: self.eventModel.id,
              type: self.eventModel.type,
              comment: self.eventModel.comment,
              user: self.eventModel.user,
              status: self.eventModel.status,
              startDate: self.eventModel.startDate,
              endDate: self.eventModel.endDate,
            },
          },
        });
        if (!response) {
          this.showSnack("error", "Event Not Updated");
          throw "Something went wrong when updating the event!";
        }
        if (response.errors && response.errors.length > 0) {
          this.showSnack("error", "Event Not Updated");
          throw response.errors[0];
        }
        if (response && response.data.updateEvent) {
          this.$swal("Success!", "Event was successfully updated", "success");
        }
      } catch (e) {
        throw e.message;
      }
      this.showSnack("success", "Event Updated");
    },
    async newEvent() {
      if (!this.consultantId) {
        this.$swal(
          "Error!",
          "Events cannot be added until this user is assigned to a Consultant",
          "error"
        );
        return;
      }
      this.isEdit = false;
      this.eventModel = new Event();
      this.eventModel.user = this.userId;
      this.eventModel.status = "open";
      this.dialog = true;
    },
    async editEvent(item) {
      this.isEdit = true;
      this.eventModel = new Event();
      this.eventModel.id = item.id;
      this.eventModel.type = item.type;
      this.eventModel.comment = item.comment;
      this.eventModel.user = this.userId;
      this.eventModel.status = item.status;
      this.eventModel.startDate = item.startDate;
      this.eventModel.endDate = item.endDate;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
