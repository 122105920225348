const numFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "ZAR",
  currencyDisplay: "narrowSymbol",
});

const getFileAsDataURL = async (newPicture) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = reader.result;
      resolve(data);
    };
    reader.onerror = (e) => {
      console.error(e);
      reject(e);
    };
    reader.readAsDataURL(newPicture);
  });
};

const dataURItoBlob = function (dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var newBlob = new Blob([ab], { type: mimeString });
  newBlob.name = "Uploaded File";
  return newBlob;
};

const compressPDF = async (file) => {
  const pdfDataUrl = await getFileAsDataURL(file);
  const pdfData = dataURItoBlob(pdfDataUrl);

  const pdfjsLib = require("pdfjs-dist/es5/build/pdf");
  await pdfjsLib.getDocument({ data: pdfData }).promise;
  const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
  const numPages = pdf.numPages;
  const scale = 0.5; // Adjust the scale as needed

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  for (let pageNum = 1; pageNum <= numPages; pageNum++) {
    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale });

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext: context,
      viewport,
    };

    await page.render(renderContext);
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      const compressedFile = new File([blob], file.name);
      resolve(compressedFile);
    }, "application/pdf");
  });
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

module.exports = {
  formatter: numFormat,
  getFileAsDataURL,
  dataURItoBlob,
  compressPDF,
};
