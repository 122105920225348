<template>
  <div>
    <div class="text-right">
      <v-btn class="mb-2" @click="openFilterDialog">Filter by Date</v-btn>
    </div>

    <v-dialog v-model="filterDialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-form>
            <v-date-picker
              v-model="dateRange"
              range
              label="Date Range"
            ></v-date-picker>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="applyDateFilter">Apply</v-btn>
          <v-btn @click="cancelDateFilter">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="stats-card" @click="toggleMedshieldLeads">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="blue"
        class="mb-0"
        dark
      />
      <div class="stats-number">{{ medshieldCount }}</div>
      <div class="stats-label">Medshield clients</div>
      <div v-if="showMedshieldLeads">
        <p v-for="lead in medshieldLeads" :key="lead.id">
          <b>{{ lead.firstName }} {{ lead.lastName }}</b
          >, {{ lead.createdAt | getFormattedDate }}
        </p>
      </div>
    </div>

    <div class="stats-card" @click="toggleBonitasLeads">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="blue"
        class="mb-0"
        dark
      />
      <div class="stats-number">{{ bonitasCount }}</div>
      <div class="stats-label">Bonitas clients</div>
      <div v-if="showBonitasLeads">
        <p v-for="lead in bonitasLeads" :key="lead.id">
          <b>{{ lead.firstName }} {{ lead.lastName }}</b
          >, {{ lead.createdAt | getFormattedDate }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { gql } from "apollo-boost";

export default {
  name: "LeadsGraph",
  data() {
    return {
      loading: false,
      leadCount: 0,
      medshieldCount: 0,
      bonitasCount: 0,
      medshieldLeads: [],
      bonitasLeads: [],
      showMedshieldLeads: false,
      showBonitasLeads: false,
      filterDialog: false,
      dateRange: null,
      pagination: {
        page: 1,
      },
    };
  },
  created() {
    this.fetchMedshieldUsers();
    this.fetchBonitasUsers();
  },
  methods: {
    async fetchMedshieldUsers() {
      this.loading = true;

      let startDate = null;
      let endDate = null;

      if (this.dateRange && this.dateRange.length === 2) {
        const [start, end] = this.dateRange;
        startDate = new Date(start);
        endDate = new Date(end);
      } else {
        const today = new Date();
        const currentYear = today.getFullYear();
        startDate = new Date(`${currentYear}-01-01`);
        endDate = new Date(today.toISOString().slice(0, 10));
      }

      try {
        const response = await this.$apollo.query({
          query: gql`
            query Users($limit: Int, $page: Int, $query: JSON!) {
              users(limit: $limit, page: $page, query: $query) {
                records {
                  id
                  role
                  source
                  firstName
                  lastName
                  createdAt
                }
                paginator {
                  userCount
                }
              }
            }
          `,
          variables: {
            limit: 1000,
            query: {
              role: "lead",
              source: "Medshield",
              createdAt: {
                $gte: startDate.getTime(),
                $lte: endDate.getTime(),
              },
            },
            page: 1,
          },
          fetchPolicy: "network-only",
        });

        this.medshieldCount = response.data.users.paginator.userCount;
        this.medshieldLeads = response.data.users.records;

        this.loading = false;
      } catch (error) {
        console.error("Error fetching users:", error);
        this.loading = false;
      }
    },

    async fetchBonitasUsers() {
      this.loading = true;
      let startDate = null;
      let endDate = null;

      if (this.dateRange && this.dateRange.length === 2) {
        const [start, end] = this.dateRange;
        startDate = new Date(start);
        endDate = new Date(end);
      } else {
        const today = new Date();
        const currentYear = today.getFullYear();
        startDate = new Date(`${currentYear}-01-01`);
        endDate = new Date(today.toISOString().slice(0, 10));
      }

      try {
        const response = await this.$apollo.query({
          query: gql`
            query Users($limit: Int, $page: Int, $query: JSON!) {
              users(limit: $limit, page: $page, query: $query) {
                records {
                  id
                  role
                  source
                  firstName
                  lastName
                  createdAt
                }
                paginator {
                  userCount
                }
              }
            }
          `,
          variables: {
            limit: 1000,
            query: {
              role: "lead",
              source: "Bonitas",
              createdAt: {
                $gte: startDate.getTime(),
                $lte: endDate.getTime(),
              },
            },
            page: 1,
          },
          fetchPolicy: "network-only",
        });

        this.bonitasCount = response.data.users.paginator.userCount;
        this.bonitasLeads = response.data.users.records;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching users:", error);
        this.loading = false;
      }
    },

    openFilterDialog() {
      this.filterDialog = true;
    },
    applyDateFilter() {
      this.fetchMedshieldUsers();
      this.fetchBonitasUsers();
      this.filterDialog = false;
    },
    clearDateFilter() {
      this.fetchMedshieldUsers();
      this.fetchBonitasUsers();
    },
    cancelDateFilter() {
      this.clearDateFilter();
      this.filterDialog = false;
    },

    toggleMedshieldLeads() {
      this.showMedshieldLeads = !this.showMedshieldLeads;
    },

    toggleBonitasLeads() {
      this.showBonitasLeads = !this.showBonitasLeads;
    },
  },
};
</script>

<style>
.stats-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.stats-number {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.stats-label {
  font-size: 14px;
  color: #666;
}
</style>
