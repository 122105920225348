<template>
  <v-autocomplete
    v-model="chosenDocumentCategory"
    v-bind="$attrs"
    :items="formattedDocumentCategories"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
  export default {
    name: 'DocumentCategoryPicker',
    props: {
      initial: {
        type: String,
        default: function () {
          return ''
        },
      },
    },
    data: () => ({
      chosenDocumentCategory: '',
      isLoading: false,
      formattedDocumentCategories: [
        { text: 'ID copy', value: 'ID copy' },
        { text: 'Proof of Address', value: 'Proof of Address' },
        { text: 'Proof of Banking', value: 'Proof of Banking' },
        { text: 'Signed Documents', value: 'Signed documents' },
        { text: 'Policy Documents', value: 'Policy Documents' },
      ],
    }),
    watch: {
      chosenDocumentCategory (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('update:chosen-document-category', this.chosenDocumentCategory ? this.chosenDocumentCategory : '')
        }
      },
    },
    async mounted () {
      this.initial ? this.chosenDocumentCategory = this.initial : this.chosenDocumentCategory = ''
    },
    methods: {
    },
  }
</script>

<style lang="sass">
</style>
