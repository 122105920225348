<template>
  <v-card>
    <v-card-title>Sales Per Insurer</v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="salesData" class="elevation-1">
        <template v-slot:item.sales="{ item }">
          <v-chip>{{ item.sales }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Insurer", value: "insurer" },
        { text: "Sales", value: "sales" },
      ],
      salesData: [
        { insurer: "Insurer A", sales: 200 },
        { insurer: "Insurer B", sales: 150 },
        { insurer: "Insurer C", sales: 100 },
      ],
    };
  },
};
</script>
