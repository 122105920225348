export class UserProduct {
  constructor() {
    this.id = "";
    this.user = null;
    this.product = null;
    this.documents = [];
    this.createdBy = "";
    this.benefitDate = "";
    this.dateResigned = "";
    this.schemeJoin = "";
    this.membershipNumber = "";
    this.dependantCode = "";
    this.beneficiaryType ="";
    this.lateJoinerPenalty = "";
    this.totalContrib = "";
    this.inceptionDate = "";
    this.startDate = "";
    this.cancelDate = "";
    this.productStatus = null;
  }
}
