<template>
  <v-app v-if="me">
    <v-app-bar app class="header" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="user"
        ><h3 style="color: '#fff'">
          Welcome,
          <span>
            {{ user.firstName }} {{ user.lastName }} from branch
            {{ user.branchName }}</span
          >
        </h3></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="3  80"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark outlined v-bind="attrs" v-on="on" class="mr-6">
            <v-icon>mdi-bell</v-icon>
            <div class="notification-badge">
              {{ kanbanTasks && kanbanTasks.count ? kanbanTasks.count : 0 }}
            </div>
          </v-btn>
        </template>
        <v-list
          v-if="
            kanbanTasks && kanbanTasks.records && kanbanTasks.records.length > 0
          "
        >
          <v-list-item
            v-for="task in kanbanTasks.records"
            :key="task.id"
            class="mb-4"
            color="primary"
            variant="tonal"
          >
            <v-list-item-content>
              <v-list-item-title>
                <div class="d-flex align-center">
                  <input type="checkbox" @change="updateReadStatus(task.id)" />
                  <div class="ml-8 mr-4">
                    <div
                      v-if="
                        task.description.includes('New client assigned to you')
                      "
                    >
                      <span class="green--text">{{
                        task.description.replace(" assigned to you", ":")
                      }}</span>
                    </div>
                    <div v-else>
                      <span class="black--text">{{ task.description }}</span>
                    </div>
                    <div></div>
                  </div>

                  <div>
                    <div
                      v-if="
                        task.description.includes('New client assigned to you')
                      "
                    >
                      <span class="green--text">
                        {{ task.updatedAt | getFormattedDate }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="red--text ml-8" style="font-size: 12px">
                        Deadline: {{ task.deadline | getFormattedDate }}</span
                      >
                    </div>
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <div>
            <p class="ml-2 my-4">There are no notifications yet</p>
          </div>
        </v-list>
      </v-menu>

      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="280"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark outlined v-bind="attrs" v-on="on" class="mr-6">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <v-list class="ma-6">
          <!-- :uses="users"  -->
          <base-consultant-user-picker
            label="Search"
            placeholder="Search by ID Number,Name or email"
            outlined
            clearable
          />
        </v-list>
      </v-menu>
      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="280"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark outlined v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout" class="mb-3">
            <v-list-item-icon>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" dark app>
      <div>
        <v-img
          @click="$router.push('/')"
          v-if="me.branch === '60f67355297f461ee095764b'"
          :contain="false"
          max-height="150px"
          class="py-1"
          position="50% 50%"
          src="@/assets/img/full_logo_white.png"
        ></v-img>
        <v-img
          @click="$router.push('/')"
          v-else-if="me.branch === '60f6736e297f461ee095764c'"
          :contain="false"
          max-height="150px"
          position="50% 50%"
          class="py-1"
          src="@/assets/img/1calllogo.png"
        ></v-img>
        <v-img
          @click="$router.push('/')"
          v-else-if="me.branch === '60f673fb297f461ee095764e'"
          :contain="false"
          max-height="150px"
          position="50% 50%"
          class="py-1"
          src="@/assets/img/full_logo_white.png"
        ></v-img>
        <v-img
          v-else
          @click="$router.push('/')"
          :contain="false"
          max-height="150px"
          position="50% 50%"
          class="py-1"
          src="@/assets/img/1calllogo.png"
        ></v-img>
      </div>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.href"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              ><h1>{{ item.title }}</h1></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-col>
          <transition name="fade">
            <router-view />
          </transition>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
import ConsultantUserPicker from "../../components/Base/Pickers/ConsultantUserPicker.vue";
import { GET_KANBAN_TASKS } from "./../../../queries";

export default {
  components: { ConsultantUserPicker },
  data() {
    return {
      drawer: null,
      imgText: "",
      me: null,
      user: null,
      showAlert: false,
      items: [
        {
          title: "Existing Clients",
          icon: "mdi-account-multiple-check",
          href: "/consultant/users",
        },
        {
          title: "Leads",
          icon: "mdi-account-circle",
          href: "/consultant/users/leads",
        },
        {
          title: "Dead Leads",
          icon: "mdi-account-multiple-minus-outline",
          href: "/consultant/users/dead-leads",
        },
        {
          title: "Call Back Quoted Leads",
          icon: "mdi-account-multiple-plus",
          href: "/consultant/users/call-back-quoted",
        },
        {
          title: "Companies",
          icon: "mdi-office-building",
          href: "/consultant/companies",
        },
      ],
      right: null,
    };
  },
  apollo: {
    kanbanTasks: {
      query: GET_KANBAN_TASKS,
      variables() {
        return {
          limit: 1000,
          skip: 0,
          query: { assignedTo: this.me ? this.me.id : null, read: false },
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        return data.kanbanTasks || [];
      },
    },
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
            branch
            branchName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
          this.user.branch = this.me.branch;
          this.user.branchName = this.me.branchName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  computed: {
    imgIterator() {
      if (this.me.branchName) {
        let branchName = this.me.branchName;
        if (branchName.includes("Medsafu")) {
          return (this.imgText = "Medsafu");
        } else {
          return (this.imgText = "1Call");
        }
      } else {
        return (this.imgText = "Other");
      }
    },
  },

  methods: {
    async updateReadStatus(taskId) {
      const taskToUpdate = this.kanbanTasks.records.find(
        (task) => task.id === taskId
      );

      if (taskToUpdate) {
        const newTask = {
          id: taskId,
          read: true,
          showAlertMessage: false,
        };

        try {
          const response = await this.$apollo.mutate({
            mutation: gql`
              mutation updateKanbanTask($kanbanTask: KanbanTaskUpdateInput!) {
                updateKanbanTask(kanbanTask: $kanbanTask) {
                  id
                }
              }
            `,
            variables: {
              kanbanTask: newTask,
            },
          });

          if (response.data && response.data.updateKanbanTask) {
            this.$apollo.queries.kanbanTasks.refetch();
          }
        } catch (error) {
          console.log("Error updating kanban task:", error);
          this.$swal("Error!", error.message, "error");
        }
      }
    },

    myFunction: function () {
      window.open(
        "https://media.publit.io/file/medsafu/HelpDocs/Consultant-Help-document.pdf",
        "_blank"
      );
    },
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.push("/");
    },
  },
  watch: {
    kanbanTasks: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal.records && newVal.records.length > 0) {
        }
        return newVal;
      },
    },
  },
};
</script>

<style scoped>
.notification-badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
</style>
