<template>
  <v-autocomplete
    v-model="chosenEventType"
    v-bind="$attrs"
    :items="formattedEventTypes"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "EventTypePicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenEventType: "",
    isLoading: false,
    formattedEventTypes: [
      { text: "Call Back - Quoted", value: "Call Back - Quoted" },
      { text: "Call Back By Request", value: "Call Back By Request" },
      { text: "Call Back - Unavailable", value: "Call Back - Unavailable" },
      { text: "Hung Up", value: "Hung Up" },
      { text: "Voicemail", value: "Voicemail" },
      { text: "Shopping Around", value: "Shopping Around" },
      { text: "Not Interested", value: "Not Interested" },
      { text: "Converse Via Email", value: "Converse Via Email" },
      { text: "Closed - Won", value: "Closed - Won" },
      { text: "Closed Lost", value: "Closed Lost" },
      { text: "Dead", value: "Dead" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenEventType = this.initial;
    },
    chosenEventType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-event-type",
          this.chosenEventType ? this.chosenEventType : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenEventType = this.initial)
      : (this.chosenEventType = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
