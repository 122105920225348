import { loadView } from "./functions";

export const AdminChildren = {
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      component: loadView("Admin/Dashboard"),
      ...userMeta(),
    },
    {
      path: "users",
      name: "Users",
      component: loadView("Admin/Users/NewViewUsers"),
      ...userMeta(),
    },
    {
      path: "leads",
      name: "Leads",
      component: loadView("Admin/Leads/NewLeads"),
      ...userMeta(),
    },
    {
      path: "archived",
      name: "Archived",
      component: loadView("Admin/Leads/Archived"),
      ...userMeta(),
    },
    {
      path: "user/:id",
      name: "User Details",
      component: loadView("Admin/Users/Details"),
      ...userMeta(),
    },
    {
      path: "user/:id/edit",
      name: "User Details Edit",
      component: loadView("Admin/Users/Edit"),
      ...userMeta(),
    },
    {
      path: "consultants",
      name: "Consultants",
      component: loadView("Admin/Consultants/View"),
      ...userMeta(),
    },
    {
      path: "consultant/:id",
      name: "Consultant Details",
      component: loadView("Admin/Consultants/Details"),
      ...userMeta(),
    },
    {
      path: "consultant/:id/edit",
      name: "Consultant Details Edit",
      component: loadView("Admin/Consultants/Edit"),
      ...userMeta(),
    },

    {
      path: "admins",
      name: "Admins",
      component: loadView("Admin/Admins/View"),
      ...userMeta(),
    },
    {
      path: "admin/:id",
      name: "Admin Details",
      component: loadView("Admin/Admins/Details"),
      ...userMeta(),
    },
    {
      path: "admin/:id/edit",
      name: "Admin Details Edit",
      component: loadView("Admin/Admins/Edit"),
      ...userMeta(),
    },
    {
      path: "documents",
      name: "Documents",
      component: loadView("Admin/Documents/View"),
      ...userMeta(),
    },
    {
      path: "document/:id",
      name: "Document Details",
      component: loadView("Admin/Documents/Details"),
      ...userMeta(),
    },
    {
      path: "document/:id/edit",
      name: "Document Edit",
      component: loadView("Admin/Documents/Edit"),
      ...userMeta(),
    },
    {
      path: "categories",
      name: "Categories View",
      component: loadView("Admin/Categories/View"),
      ...userMeta(),
    },
    {
      path: "category/:id",
      name: "Category Details",
      component: loadView("Admin/Categories/Details"),
      ...userMeta(),
    },
    {
      path: "category/:id/edit",
      name: "Category Edit",
      component: loadView("Admin/Categories/Edit"),
      ...userMeta(),
    },
    {
      path: "products",
      name: "Products",
      component: loadView("Admin/Products/View"),
      ...userMeta(),
    },
    {
      path: "product/:id",
      name: "Product Details",
      component: loadView("Admin/Products/Details"),
      ...userMeta(),
    },
    {
      path: "product/:id/edit",
      name: "Product Edit",
      component: loadView("Admin/Products/Edit"),
      ...userMeta(),
    },
    {
      path: "ficaproducts",
      name: "FicaProducts",
      component: loadView("Admin/FicaProducts/View"),
      ...userMeta(),
    },
    {
      path: "ficaproduct/:id",
      name: "ficaProduct Details",
      component: loadView("Admin/FicaProduct/Details"),
      ...userMeta(),
    },
    {
      path: "ficaproduct/:id/edit",
      name: "FicaProduct Edit",
      component: loadView("Admin/FicaProducts/Edit"),
      ...userMeta(),
    },
    {
      path: "insurers",
      name: "Insurers",
      component: loadView("Admin/Insurers/View"),
      ...userMeta(),
    },
    {
      path: "insurer/:id",
      name: "Insurer Details",
      component: loadView("Admin/Insurers/Details"),
      ...userMeta(),
    },
    {
      path: "insurer/:id/edit",
      name: "Insurer Edit",
      component: loadView("Admin/Insurers/Edit"),
      ...userMeta(),
    },
    ,
    ////////
    {
      path: "referrals",
      name: "Lead Sources",
      component: loadView("Admin/Referral/View"),
      ...userMeta(),
    },
    {
      path: "referral/:id",
      name: "Lead Source Details",
      component: loadView("Admin/Referral/Details"),
      ...userMeta(),
    },
    {
      path: "referral/:id/edit",
      name: "Lead Source Edit",
      component: loadView("Admin/Referral/Edit"),
      ...userMeta(),
    },
    {
      path: "branches",
      name: "Branches",
      component: loadView("Admin/Branches/View"),
      ...userMeta(),
    },
    {
      path: "branch/:id",
      name: "Branch Details",
      component: loadView("Admin/Branches/Details"),
      ...userMeta(),
    },
    {
      path: "branch/:id/edit",
      name: "Branch Edit",
      component: loadView("Admin/Branches/Edit"),
      ...userMeta(),
    },
    //
    {
      path: "companies",
      name: "Companies",
      component: loadView("Admin/Companies/View"),
      ...userMeta(),
    },
    {
      path: "company/:id",
      name: "Company Details",
      component: loadView("Admin/Companies/Details"),
      ...userMeta(),
    },
    {
      path: "company/:id/edit",
      name: "Company Edit",
      component: loadView("Admin/Companies/Edit"),
      ...userMeta(),
    },
    //
    {
      path: "tasks",
      name: "Tasks",
      component: loadView("Admin/Task/View"),
      ...userMeta(),
    },
    //////
  ],
};

function userMeta() {
  return {
    meta: { authorize: ["admin"] },
  };
}
