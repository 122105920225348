<template v-if="users.records">
  <download-excel
    class="text-right"
    :data="users.records"
    :fields="fields"
    :before-generate="startDownload"
    :before-finish="finishDownload"
    worksheet="My Worksheet"
    name="MedsafuClientsList.xls"
  >
    <v-btn class="text-right" color="#0a2752" large dark>
      <h1>Download From yesterday</h1>
    </v-btn>
  </download-excel>
</template>

<script>
import { gql } from "apollo-boost";
import downloadexcel from "vue-json-excel";
export default {
  name: "UserDownloadYesterday",
  components: {
    downloadexcel,
  },
  data: () => ({
    isLoading: false,
    fields: {
      id: "id",
      firstName: "firstName",
      lastName: "lastName",
      email: "email",
      telNumber: "telNumber",
      referral: "referral",
      // consultant: "consultant",
      // consultantName: "consultantName",
    },
    users: [],
    page: 1,
  }),
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int, $page: Int, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              email
              telNumber
              branch
              referral
              consultant
              consultantName
              createdAt
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const yesterdaysDate = new Date(Date.now() - 86400000 * 2);

        return {
          limit: limit,
          page: this.page,
          query: { createdAt: { $gte: yesterdaysDate.getTime() } },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  watch: {},
  async mounted() {},
  methods: {
    async refreshClientData() {},
    startDownload() {
      this.isLoading = true;
    },
    finishDownload() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass"></style>
