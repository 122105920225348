<script>
import { Bar, mixins } from "vue-chartjs";

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "options"],
  mounted() {
    // Remove the <template> tags and render the chart directly here
    this.renderChart(
      {
        labels: this.chartData.map((dataPoint) => dataPoint.x),
        datasets: [
          {
            label: "Number of Leads",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            data: this.chartData.map((dataPoint) => dataPoint.y),
          },
        ],
      },
      this.options
    );
  },
};
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
