<template>
  <v-app v-if="me">
    <v-app-bar app class="header">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="user"
        >{{ user.firstName }} {{ user.lastName }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="240"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="light" dark outlined v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout" class="mb-3">
            <v-list-item-icon>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" dark app>
      <v-list-item @click="$router.push('/')">
         <v-list-item-content v-if="me.branch === '60f67355297f461ee095764b'">
          <v-img
            :contain="true"
            height="120"
            src="@/assets/img/full_logo_white.png"
          ></v-img>
        </v-list-item-content>
           <v-list-item-content v-else-if="me.branch === '60f6736e297f461ee095764c'">
          <v-img
            :contain="true"
            height="120"
            src="@/assets/img/full_logo_white.png"
          ></v-img>
        </v-list-item-content>
         <v-list-item-content v-else>
          <v-img
            :contain="true"
            height="120"
            src="@/assets/img/1calllogo.png"
          ></v-img>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.href"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title><h1>{{ item.title }}</h1></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-col>
          <transition name="fade">
            <router-view />
          </transition>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  data() {
    return {
      drawer: null,

      me: null,
      user: null,

      items: [
        {
          title: "Reporting",
          icon: "mdi-view-dashboard",
          href: "/user/dashboard",
        },
        {
          title: "Documents",
          icon: "mdi-book-check",
          href: "/user/documents",
        },
      ],
      right: null,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  methods: {
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
