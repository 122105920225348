<template>
  <div class="ma-4">
    <v-text-field v-model="search" append-icon="mdi-magnify" clearable outlined>
      <template #append>
        <v-btn x-small color="primary" @click="searchUsers">Search</v-btn>
      </template>
    </v-text-field>
    <v-progress-linear
      v-if="isLoading && search"
      color="amber"
      indeterminate
    ></v-progress-linear>

    <v-list
      v-if="showResults && formattedUsers.length > 0"
      class="scrollable-list"
    >
      <v-list-item
        v-for="user in formattedUsers"
        :key="user.value"
        @click="selectUser(user)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ user.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div v-if="showResults && formattedUsers.length === 0" class="no-results">
      No results found.
    </div>
  </div>
</template>
<script>
import { gql } from "apollo-boost";

export default {
  name: "ClientPicker",
  props: {
    initial: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chosenUser: null,
      search: "",
      isLoading: false,
      formattedUsers: [],
    };
  },
  computed: {
    showResults() {
      return this.formattedUsers.length > 0 && !!this.search;
    },
  },
  methods: {
    async searchUsers() {
      this.isLoading = true;
      // Perform the Apollo query here
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query Users($limit: Int, $query: JSON!) {
              users(limit: $limit, query: $query) {
                records {
                  id
                  firstName
                  lastName
                  role
                }
              }
            }
          `,
          variables: {
            limit: 9999,
            query: {
              $and: [
                { role: { $in: ["user", "lead"] } },
                {
                  $or: [
                    { firstName: { $regex: this.search, $options: "i" } },
                    { lastName: { $regex: this.search, $options: "i" } },
                  ],
                },
              ],
            },
          },
        });

        this.formattedUsers = data.users.records.map((user) => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user.id,
        }));
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        this.isLoading = false;
      }
    },
    selectUser(user) {
      // Emit the selected user's ID
      this.$emit("user-selected", user.value);
    },
    clearResults() {
      if (!this.chosenUser) {
        this.formattedUsers = [];
      }
    },
  },
};
</script>

<style scoped>
.scrollable-list {
  max-height: 200px; /* Set the maximum height for vertical scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}
</style>
