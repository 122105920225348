import { loadView } from "./functions";

export const UserChildren = {
  children: [
    {
      path: "dashboard",
      name: "User Dashboard",
      component: loadView("User/Dashboard"),
      ...userMeta(),
    },
    {
      path: "profile",
      name: "Profile",
      component: loadView("User/Profile"),
      ...userMeta(),
    },
    {
      path: "documents",
      name: "User Documents",
      component: loadView("User/Documents/View"),
      ...userMeta(),
    },
    {
      path: "document/:id",
      name: "User Document Details",
      component: loadView("User/Documents/Details"),
      ...userMeta(),
    },
    {
      path: "document/:id/edit",
      name: "User Document Edit",
      component: loadView("User/Documents/Edit"),
      ...userMeta(),
    },
    /////
  ],
};

function userMeta() {
  return {
    meta: { authorize: ["admin", "user"] },
  };
}
