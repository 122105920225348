<template>
  <div>
    <user-product-picker
      v-if="user"
      :user="user"
      @selected-user-product="handleUserProductSelected"
    />

    <div v-if="userProduct">
      <input
        type="file"
        ref="fileInput"
        accept=".pdf"
        multiple
        @change="handleFileChange"
      />

      <div class="my-4">
        <div v-for="(file, index) in selectedFiles" :key="index">
          <span class="ma-4">
            <v-icon>mdi-file</v-icon>{{ file.name }}

            <v-icon @click="removeFile(index)">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="text-right">
        <v-btn
          @click="uploadFilesToPublito"
          :disabled="selectedFiles.length === 0 || uploading"
          x-small
        >
          upload
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import PublitioAPI from "publitio_js_sdk";
import ClientPicker from "../Pickers/ClientPicker.vue";
import UserProductPicker from "../Pickers/UserProductPicker.vue";

export default {
  components: { ClientPicker, UserProductPicker },
  name: "ClientFileUploadOther",

  data() {
    return {
      user: "",
      file: null,
      progress: null,
      userProduct: "",
      maxFileSize: 10 * 1024 * 1024, // 10 MB
      productItems: [],
      publitio: null,
      selectedFile: null,
      selectedFiles: [],
      fileRules: [
        (value) => !!value || "Files are required",
        (value) =>
          value.every((file) => file.size <= 10 * 1024 * 1024) ||
          "File size must be less than 10MB each",
      ],
      uploading: false,
      uploadProgress: [],
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
  },
  computed: {
    fileTooLarge() {
      return this.file && this.file.size > this.maxFileSize;
    },
  },
  methods: {
    formatSize(size) {
      const units = ["B", "KB", "MB", "GB", "TB"];
      let unitIndex = 0;
      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }
      return size.toFixed(2) + " " + units[unitIndex];
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },

    handleUserSelected(userId) {
      this.user = userId;
    },

    handleUserProductSelected(userProductId) {
      this.userProduct = userProductId;
    },

    handleFileChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        // Add each selected file to the selectedFiles array
        this.selectedFiles.push(files[i]);

        this.uploadDocument(files[i]);
      }
    },

    async handleFileDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        this.uploadDocument(files[i]);
      }
    },
    async uploadFilesToPublito() {
      if (this.selectedFiles.length === 0) {
        return;
      }

      this.uploading = true;
      this.uploadProgress = new Array(this.selectedFiles.length).fill(0);
      let successfulUploads = 0;

      try {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          const file = this.selectedFiles[i];
          const uploadResult = await this.uploadFile(file);
          const document = {
            name: file.name,
            publitoDownloadUrl: uploadResult.url_download,
            publitoEmbeddUrl: uploadResult.url_embed,
            publitoUrl: uploadResult.url_preview,
            user: this.user,
            userProduct: this.userProduct,
            createdBy: this.me.id,
          };
          await this.uploadDocument(file, document);
          this.uploadProgress[i] = 100;
          successfulUploads++;
        }
        if (successfulUploads === this.selectedFiles.length) {
          this.$swal(
            "Upload Complete",
            "the document(s) was successfully uploaded",
            "success"
          );
          this.user = "";
          this.userProduct = "";
          this.selectedFiles = [];

          this.$emit("update-user-products");
        }
      } catch (error) {
        console.error("An error occurred during upload:", error);
        this.$swal("Upload Error", "An error occurred during upload", "error");
      } finally {
        this.uploading = false;
      }
    },

    async uploadFile(file) {
      try {
        const uploadResult = await this.publitio.uploadFile(file, "file");
        return uploadResult;
      } catch (error) {
        console.error("An error occurred during file upload:", error);
        throw error;
      }
    },

    async uploadDocument(file, document) {
      try {
        this.progress = 0;
        document.publitoDownloadUrl = document.publitoDownloadUrl;
        document.publitoEmbeddUrl = document.publitoEmbeddUrl;
        document.publitoUrl = document.publitoUrl;

        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation createDocument($document: DocumentCreateInput!) {
              createDocument(document: $document) {
                id
                name
                publitoDownloadUrl
                publitoEmbeddUrl
                publitoUrl
                userProduct
                user
                createdBy
              }
            }
          `,
          variables: {
            document,
          },
          uploadProgress: (progressEvent) => {
            this.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        });
        if (!response || response.errors) {
          console.log("error", response.errors);
          throw new Error("Something went wrong when creating the document!");
        }

        this.progress = null;
      } catch (e) {
        console.error(e);
      }
    },
  },
  created() {
    this.publitio = new PublitioAPI(
      process.env.VUE_APP_PUBLITO_API_KEY,
      process.env.VUE_APP_PUBLITO_API_SECRET
    );
    this.user = this.$route.params.id;
  },
};
</script>

<style>
#error-text {
  color: red;
}

/* Styling for the file input */
input[type="file"] {
  font-size: 16px; /* Adjust font size as needed */
  padding: 10px; /* Adjust padding as needed */
}
</style>
