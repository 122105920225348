<template>
  <v-card class="my-0">
    <v-card-text>
      <v-form ref="UserCreateForm" v-model="formValid">
        <base-loader :is-loading="isLoading" />
        <v-alert v-if="successMessage" type="success">
          {{ successMessage }}
        </v-alert>
        <v-alert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </v-alert>
        <v-row class="mx-1">
          <v-progress-linear
            :value="uploadProgress"
            color="secondary"
            height="25"
            reactive
            class="my-5"
          >
           <h1 color="#fff">
             <strong>{{ Math.ceil(uploadProgress) }}% ({{ completedRecords }} /
              {{ totalRecords }})</strong>
            </h1> 
          </v-progress-linear>
        </v-row>
        <v-row class="mx-1">
          <div class="mb-12 ml-4"><a href="https://media.publit.io/file/medsafu/csvs/UserAssignedProducts.png">Click here for a Sample CSV file</a></div>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-file-input
              v-model="userProductCSVFile"
              accept="text/csv"
              color="secondary"
              label="File input"
              show-size
              prepend-icon="mdi-paperclip"
              counter
              :full-width="true"
              outlined
              required
              :rules="[(v) => !!v || 'File is required']"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip color="secondary" dark label small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <v-btn
              :disabled="!userProductCSVFile"
              color="secondary"
              class="ma-1"
              @click="stageForUploading()"
            >
              Stage for Uploading
            </v-btn>
            <v-btn
              :disabled="
                !parsedCSVIsValid ||
                  parsedCSV.length === 0 ||
                  !userProductCSVFile
              "
              class="ma-1"
              color="secondary"
              :block="false"
              @click="startUpload()"
            >
              Start Upload
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-textarea
              v-model="stagedTextArea"
              color="secondary"
              rows="20"
              label="Staged Data"
              filled
              no-resize
              outlined
              :readonly="true"
            />
          </v-col>
        </v-row>
      </v-form>
      <!-- <small>*indicates required field</small> -->
    </v-card-text>
    <v-card-actions>
      <!-- <v-btn
        :disabled="!userProductCSVFile"
        color="secondary"
        class="mx-2"
        @click="stageForUploading()"
      >
        Save
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
// import { mapGetters } from 'vuex'
import { gql } from "apollo-boost";
var Papa = require("papaparse");
const Joi = require("joi");
const _ = require("lodash");
export default {
  name: "UserProductAssigningBulkCreate",
  data: () => ({
    isLoading: false,
    uploadingData: false,
    formValid: true,
    tenant: "",
    userProductCSVFile: null,
    parsedCSV: [],
    stagedTextArea: "",
    parsedCSVIsValid: false,
    uploadProgress: 0,
    totalRecords: 0,
    completedRecords: 0,
    successMessage: "",
    errorMessage: "",
  }),
  // computed: {
  //   ...mapGetters(),
  // },
  watch: {
    userProductCSVFile() {
      console.log("userProductCSVFile has changed");
      this.parsedCSV = [];
      this.stagedTextArea = "";
    },
  },
  async mounted() {},
  methods: {
    async stageForUploading() {
      const self = this;
      self.parsedCSV = [];
      this.stagedTextArea = "";
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";
      try {
        console.log("Parsing CSV File");
        Papa.parse(self.userProductCSVFile, {
          header: true,
          skipEmptyLines: true,
          step: (row) => {
            console.log("Row:", row.data);
            self.parsedCSV.push(row.data);
          },
          complete: () => {
            console.log("All done!");
            self.stagedTextArea = `There are ${self.parsedCSV.length} items in this file.\r\n`;
            this.checkDataIsValid();
            self.totalRecords = self.parsedCSV.length;
            self.isLoading = false;
          },
        });
      } catch (error) {
        self.errorMessage = `Could Not Stage File: ${Date.now()} / ${
          error.message
        }`;
        self.isLoading = false;
      }
    },
    checkDataIsValid() {
      console.log("Validating Objects in Array");
      const self = this;
      self.parsedCSVIsValid = false;
      const userProductObj = Joi.object().keys({
        email: Joi.alternatives().try(Joi.string()).required(),
        product: Joi.alternatives().try(Joi.string()).required(),
        policyNumber: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
        schemeJoin: Joi.alternatives().try(Joi.string(), Joi.number()),
        benefitDate: Joi.alternatives().try(Joi.string(), Joi.number()),
        dateResigned: Joi.alternatives().try(Joi.string(), Joi.number()),
        dependantCode: Joi.alternatives().try(Joi.string(), Joi.number()),
        beneficiaryType: Joi.alternatives().try(Joi.string(), Joi.number()),
        lateJoinerPenalty: Joi.alternatives().try(Joi.string(), Joi.number()),
        totalContrib: Joi.alternatives().try(Joi.string(), Joi.number()),
        inceptionDate: Joi.alternatives().try(Joi.string(), Joi.number()),
        membershipNumber: Joi.alternatives().try(Joi.string(), Joi.number()),
      });
      const userProductArr = Joi.array().items(userProductObj);
      // const test = Joi.validate(self.parsedCSV, userProductArr);
      const test = userProductArr.validate(self.parsedCSV);
      console.log(test);
      if (test.error) {
        self.errorMessage = `There are problems with your file ${test.error}`;
        self.parsedCSVIsValid = false;
      } else {
        self.successMessage = "Your file is valid";
        self.parsedCSVIsValid = true;
      }
      return self.parsedCSVIsValid;
    },
    async startUpload() {
      const self = this;
      self.totalRecords = self.parsedCSV.length;
      self.uploadProgress = 0;
      self.completedRecords = 0;
      await Promise.all(
        self.parsedCSV.map(async (userProduct) => {
          userProduct.email = userProduct.email.toLowerCase();
          userProduct.product = _.startCase(userProduct.product);
          self.stagedTextArea += `Creating ${userProduct.product} PolicyNumber ${userProduct.policyNumber} for ${userProduct.email}\r\n`;
          try {
            await self.saveUserProduct(userProduct);
            self.stagedTextArea += ` ${userProduct.product} PolicyNumber ${userProduct.policyNumber} for ${userProduct.email} created successfully\r\n`;
          } catch (error) {
            self.stagedTextArea += ` ${userProduct.product} PolicyNumber ${userProduct.policyNumber} for ${userProduct.email} not created: ${error.message}\r\n`;
          }
          self.completedRecords += 1;
          self.uploadProgress =
            (self.completedRecords / self.totalRecords) * 100;
        })
      );
    },
    async saveUserProduct(userProductObj) {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      try {
        console.log("userProductObj", userProductObj);
        const createdUserProduct = await this.$apollo.mutate({
          mutation: gql`
            mutation($userProductInput: UserProductCreateByRefInput!) {
              createUserProductByRefs(userProduct: $userProductInput) {
                id
              }
            }
          `,
          variables: { userProductInput: userProductObj },
          error(error) {
            console.log(JSON.stringify(error.message));
          },
        });
        console.log(createdUserProduct);
        return true;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style lang="sass"></style>
