var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-material-card',{attrs:{"title":"Users Created At Graph","color":"green","elevation":8}},[_c('base-bar',{staticStyle:{"height":"45vh"},attrs:{"chart-data":_vm.userGroupedByCreatedAt,"bind":true,"options":{
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: 'bottom' },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        ],
      },
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }