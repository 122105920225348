<template>
  <v-dialog v-model="existingDocumentsDialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Existing Documents</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="
                userProductModel.documents &&
                userProductModel.documents.length > 0
              "
            >
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(document, index) in userProductModel.documents"
                  :key="index"
                >
                  <v-expansion-panel-header>
                    <v-row class="justify-space-between">
                      <h3>{{ document.name }}</h3>
                      <v-btn
                        dark
                        small
                        color="black"
                        @click="downloadPdf(document)"
                      >
                        View/Print or Download PDF
                      </v-btn>
                      <v-btn
                        dark
                        small
                        class="mr-12"
                        @click="deleteDocument(document._id)"
                        color="error"
                      >
                        <v-icon>mdi-delete</v-icon>Delete
                      </v-btn>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <pdf
                      :src="document.documentLink"
                      :binary="true"
                      :download-options="downloadOptions"
                    />
                    <v-row>
                      <!-- <v-col cols="6">{{ currentPage }} / {{ pageCount }}</v-col> -->
                    </v-row>
                    <!-- Hidden anchor element for download -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="existingDocumentsDialog = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "ExistingProducts",
  props: {
    userProduct: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      existingDocumentsDialog: false,
      userProductModel: this.userProduct, // Initialize userProductModel with the received prop
    };
  },
  methods: {
    async downloadPdf(document) {
      // Extract the file name from the document object
      const fileName = document.name;

      this.downloadOptions.filename = fileName;
      // Convert the base64 string to a Uint8Array
      const base64Data = document.documentLink.split(",")[1];
      const uint8Array = Uint8Array.from(atob(base64Data), (c) =>
        c.charCodeAt(0)
      );

      // Create a PDF document from the Uint8Array
      const pdfDoc = await PDFDocument.load(uint8Array);

      // Get the PDF bytes
      const pdfBytes = await pdfDoc.save();

      // Create a blob from the PDF bytes
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

      // Create a URL for the blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new window for download
      window.open(pdfUrl, "_blank");

      // Clean up the URL object after the window is closed
      window.addEventListener("beforeunload", () => {
        URL.revokeObjectURL(pdfUrl);
      });
    },
    deleteDocument(id) {
      console.log("Deleting Document ", id);
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteDocument(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "Document was successfully deleted",
                "success"
              );
            }
          } catch (e) {
            console.log(`Deletion Failed ${JSON.stringify(e)}`);
          }
        }
      });
    },
  },
};
</script>
