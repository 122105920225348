<template>
  <v-autocomplete
    v-model="chosenDocClass"
    v-bind="$attrs"
    :items="formattedDocClasses"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "DocClassPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "personal";
      },
    },
  },
  data: () => ({
    chosenDocClass: "personal",
    isLoading: false,
    formattedDocClasses: [
      { text: "Personal", value: "personal" },
      { text: "Business", value: "business" },
      { text: "Knowledge Base", value: "knowledgebase" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenDocClass = this.initial;
    },
    chosenDocClass(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-doc-class",
          this.chosenDocClass ? this.chosenDocClass : "personal"
        );
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass"></style>
