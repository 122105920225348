<template>
  <v-container v-if="dependents.records">
    <v-row>
      <div class="ml-8 mb-4">
        <h1>Dependents:</h1>
      </div>
    </v-row>
    <v-row>
      <div class="ml-2 mb-4">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark color="primary" v-bind="attrs" v-on="on">
              <v-icon dark left> mdi-plus </v-icon> Add Dependent</v-btn
            >
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Adding a dependent</v-toolbar-title>
              <br />

              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog = false"
                  ><v-icon>mdi-close</v-icon> Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card class="my-0">
              <v-card-text>
                <v-form ref="DependentCreateForm" v-model="formValid">
                  <v-alert v-if="successMessage" type="success">
                    {{ successMessage }}
                  </v-alert>
                  <v-alert v-if="errorMessage" type="error">
                    {{ errorMessage }}
                  </v-alert>

                  <v-row class="mx-1">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="dependent.firstName"
                        color="primary"
                        label="First Name"
                        outlined
                        required
                        :rules="[(v) => !!v || 'First name is required']"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="dependent.lastName"
                        color="primary"
                        label="Last Name"
                        outlined
                        required
                        :rules="[(v) => !!v || 'Last name is required']"
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="dependent.depEmail"
                        color="primary"
                        label="Email"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <h3 class="ml-8">Client personal Information</h3>
                  </v-row>
                  <v-row class="mx-1">
                    <v-col cols="6">
                      <v-text-field
                        v-model.trim="dependent.idNumber"
                        color="primary"
                        label="ID Number"
                        outlined
                        clearable
                        @change="saveDob"
                      />
                    </v-col>
                    <v-col cols="6" v-if="dependent.idNumber === ''">
                      <v-text-field
                        v-model="dependent.passportNumber"
                        color="primary"
                        label="Passport Number"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dependent.dateOfBirth"
                              label="Birth date"
                              prepend-icon="mdi-calendar"
                              clearable
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dependent.dateOfBirth"
                            outlined
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                            min="1900-01-01"
                            @change="saveDob"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="6"
                      ><v-text-field
                        v-model="dependent.age"
                        outlined
                        label="Age"
                        disabled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mx-1">
                    <v-col>
                      <base-title-picker
                        color="primary"
                        :label="'Title'"
                        :initial="dependent.title"
                        :chosen-title.sync="dependent.title"
                        outlined
                      />
                    </v-col>
                    <v-col>
                      <base-race-picker
                        :label="'Race'"
                        :initial="dependent.race"
                        :chosen-race.sync="dependent.race"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mx-1">
                    <v-col>
                      <base-gender-picker
                        :label="'Gender'"
                        :initial="dependent.gender"
                        :chosen-gender.sync="dependent.gender"
                        outlined
                      />
                    </v-col>
                    <v-col>
                      <base-dependent-relationship-picker
                        :label="'Relationship'"
                        :initial="dependent.dependentRelationship"
                        :chosen-dependentRelationship.sync="
                          dependent.dependentRelationship
                        "
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mx-1">
                    <v-col cols="12" md="12">
                      <v-textarea
                        v-model="dependent.fullAddress"
                        color="primary"
                        label="Address"
                        counter
                        clearable
                        auto-grow
                        rows="4"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mx-1">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="dependent.telNumber"
                        color="primary"
                        label="Telephone Number"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="dependent.altNumber"
                        color="primary"
                        label="Alternative Telephone Number"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <h3 class="ml-8">Branch Information</h3>
                  </v-row>
                  <v-row class="mx-1">
                    <v-col cols="12" md="12">
                      <base-branch-dependent-picker
                        :chosen-branch.sync="dependent.branch"
                        :initial="dependent.branch"
                        label="Branch"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <h3 class="ml-8">Product information</h3>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <base-product-picker
                        :chosen-product.sync="dependent.product"
                        :initial="dependent.product"
                        label="Product"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="dependent.policyNumber"
                        color="primary"
                        label="Policy Number"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row> </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :disabled="!formValid"
                  color="primary"
                  class="mx-2"
                  @click="save()"
                >
                  Save
                </v-btn>
                <v-btn
                  :disabled="!formValid"
                  color="primary"
                  class="mx-2"
                  @click="refreshPage()"
                >
                  Refresh
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
    <v-row>
      <div class="tabs">
        <v-card class="my-12 ml-12" v-if="dependents.records.length > 0">
          <v-tabs v-model="tab" background-color="#feb809" dark>
            <v-tab v-for="(item, index) in dependents.records" :key="index">
              {{ item.firstName }} {{ item.lastName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(item, index) in dependents.records"
              :key="index"
            >
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h2 class="mb-4">Biographical Data</h2>
                      <p>
                        <strong>Full Name:</strong>
                        {{ item.title }} &nbsp; {{ item.firstName }} &nbsp;
                        {{ item.lastName }}
                      </p>
                      <p>
                        <strong>ID or Passport Number:</strong>
                        {{ item.idNumber }}
                      </p>
                      <!-- <p>
                        <strong>Date of Birth:</strong>
                        {{ item.dateOfBirth }}
                      </p> -->
                      <p><strong>Gender:</strong> {{ item.gender }}</p>
                      <p>
                        <strong>Relationship:</strong>
                        {{ item.dependentRelationship }}
                      </p>
                      <p><strong>Race:</strong> {{ item.race }}</p>
                    </v-col>
                    <v-col>
                      <h2 class="mb-4">Contact info</h2>
                      <p><strong>Address:</strong> {{ item.fullAddress }}</p>
                      <p><strong>Tel Number:</strong> {{ item.telNumber }}</p>
                      <p>
                        <strong>Alt. Tel Number:</strong> {{ item.altNumber }}
                      </p>

                      <p><strong>Email:</strong> {{ item.depEmail }}</p>
                    </v-col>
                    <v-col
                      ><h2 class="mb-4">Insurance info</h2>
                      <p v-if="item.product != ''">
                        <strong>Product:</strong> {{ item.productName }}
                      </p>
                      <p>
                        <strong>Policy number:</strong> {{ item.policyNumber }}
                      </p>
                    </v-col>
                  </v-row>

                  <p>
                    <strong>CreatedAt:</strong>
                    {{ item.createdAt | getFormattedDate }}
                  </p>
                  <v-spacer></v-spacer>
                  <p>
                    <strong>UpdatedAt:</strong>
                    {{ item.updatedAt | getFormattedDate }}
                  </p>
                </v-card-text>
                <div class="remove-btn">
                  <v-btn
                    class="mx-2"
                    dark
                    color="error"
                    @click="deleteItem(item)"
                  >
                    <v-icon dark left> mdi-delete </v-icon> Remove</v-btn
                  >
                  <v-dialog
                    v-model="editDialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        dark
                        color="secondary"
                        @click="showEditDependent(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left> mdi-account </v-icon> Edit</v-btn
                      >
                    </template>
                    <v-card>
                      <v-toolbar dark color="primary">
                        <v-toolbar-title>Editing a dependent</v-toolbar-title>
                        <br />
                        <v-subheader>Biographical Data</v-subheader>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn dark text @click="editDialog = false"
                            ><v-icon>mdi-close</v-icon> Close
                          </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>
                      <v-card class="my-0">
                        <v-card-text>
                          <v-form ref="DependentEditForm" v-model="formValid">
                            <base-loading :is-loading="isLoading" />
                            <v-alert v-if="successMessage" type="success">
                              {{ successMessage }}
                            </v-alert>
                            <v-alert v-if="errorMessage" type="error">
                              {{ errorMessage }}
                            </v-alert>

                            <v-row class="mx-1">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="dependentModel.firstName"
                                  color="primary"
                                  label="First Name"
                                  outlined
                                  required
                                  :rules="[
                                    (v) => !!v || 'First name is required',
                                  ]"
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="dependentModel.lastName"
                                  color="primary"
                                  label="Last Name"
                                  outlined
                                  required
                                  :rules="[
                                    (v) => !!v || 'Last name is required',
                                  ]"
                                />
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-text-field
                                  v-model="dependentModel.depEmail"
                                  color="primary"
                                  label="Email"
                                  outlined
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <h3 class="ml-8">Client personal Information</h3>
                            </v-row>
                            <v-row class="mx-1">
                              <v-col cols="6" md="6">
                                <v-text-field
                                  v-model="dependent.idNumber"
                                  color="primary"
                                  label="ID or Passport Number"
                                  outlined
                                  clearable
                                />
                              </v-col>
                              <!-- <v-col cols="6" md="6">
                                <div>
                                  <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="dependentModel.dateOfBirth"
                                        label="Birth date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template> -->
                              <!-- <v-date-picker
                                      v-model="dependentModel.dateOfBirth"
                                      outlined
                                      :max="
                                        new Date(
                                          Date.now() -
                                            new Date().getTimezoneOffset() *
                                              60000
                                        )
                                          .toISOString()
                                          .substr(0, 10)
                                      "
                                      min="1900-01-01"
                                      @change="saveDob"
                                    ></v-date-picker> 
                                  </v-menu>
                                </div>
                              </v-col> -->
                            </v-row>
                            <v-row class="mx-1">
                              <v-col cols="12" md="6">
                                <base-title-picker
                                  color="primary"
                                  :label="'Title'"
                                  :chosen-title.sync="dependentModel.title"
                                  :initial="dependentModel.title"
                                  outlined
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <base-race-picker
                                  :label="'Race'"
                                  :initial="dependentModel.race"
                                  :chosen-race.sync="dependentModel.race"
                                  outlined
                                />
                              </v-col>
                            </v-row>
                            <v-row class="mx-1">
                              <v-col cols="12" md="6">
                                <base-gender-picker
                                  :label="'Gender'"
                                  :initial="dependentModel.gender"
                                  :chosen-gender.sync="dependentModel.gender"
                                  outlined
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <base-dependent-relationship-picker
                                  :label="'Relationship'"
                                  :initial="
                                    dependentModel.dependentRelationship
                                  "
                                  :chosen-dependent-relationship.sync="
                                    dependentModel.dependentRelationship
                                  "
                                  outlined
                                />
                              </v-col>
                            </v-row>
                            <v-row class="mx-1">
                              <v-col cols="12" md="12">
                                <v-textarea
                                  v-model="dependentModel.fullAddress"
                                  color="primary"
                                  label="Address"
                                  counter
                                  clearable
                                  auto-grow
                                  rows="4"
                                  outlined
                                />
                              </v-col>
                            </v-row>
                            <v-row class="mx-1">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="dependentModel.telNumber"
                                  color="primary"
                                  label="Telephone Number"
                                  outlined
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="dependentModel.altNumber"
                                  color="primary"
                                  label="Alternative Telephone Number"
                                  outlined
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <h3 class="ml-8">Branch Information</h3>
                            </v-row>
                            <v-row class="mx-1">
                              <v-col cols="12" md="12">
                                <base-branch-dependent-picker
                                  :chosen-branch.sync="dependentModel.branch"
                                  :initial="dependentModel.branch"
                                  label="Branch"
                                  outlined
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <h3 class="ml-8">Product information</h3>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="6">
                                <base-product-picker
                                  :chosen-product.sync="dependentModel.product"
                                  :initial="
                                    dependentModel.product
                                      ? dependentModel.product
                                      : ''
                                  "
                                  label="Product"
                                  outlined
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="dependentModel.policyNumber"
                                  color="primary"
                                  label="Policy Number"
                                  outlined
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            :disabled="!formValid"
                            color="primary"
                            class="mx-2"
                            @click="save(item)"
                          >
                            Save
                          </v-btn>
                          <v-btn
                            :disabled="!formValid"
                            color="primary"
                            class="mx-2"
                            @click="refreshPage()"
                          >
                            Refresh
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-card>
                  </v-dialog>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { gql } from "apollo-boost";
import { Dependent } from "@/models/Dependent";

export default {
  name: "DynamicTabs",
  props: {
    userId: {
      type: String,
      default: function () {
        return "";
      },
    },
  },

  data() {
    return {
      isLoading: false,
      formValid: true,
      tab: null,
      dialog: false,
      editDialog: false,
      dependents: [],
      totalRecords: 0,
      page: 1,
      dependent: {
        firstName: "",
        lastName: "",
        idNumber: "",
        fullAddress: "",
        telNumber: "",
        altNumber: "",
        depEmail: "",
        title: "",
        gender: "",
        race: "",
        branch: "",
        referral: "Organic Website",
        product: "",
        productName: "",
        policyNumber: "",
        dependentRelationship: "",
        dateOfBirth: "",
        passportNumber: "",
      },
      successMessage: "",
      errorMessage: "",
      dependentModel: new Dependent(),
      me: "",
      menu: false,
    };
  },

  watch: {
    userId(newValue, oldValue) {
      this.dependentModel.user = this.userId;
    },
  },

  apollo: {
    me: {
      query: gql`
        query {
          me {
            role
          }
        }
      `,
    },
    dependents: {
      query: gql`
        query Dependents($limit: Int!, $skip: Int!, $query: JSON!) {
          dependents(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              firstName
              lastName
              idNumber
              fullAddress
              telNumber
              altNumber
              depEmail
              title
              gender
              race
              branch
              referral
              product
              productName
              policyNumber
              dependentRelationship
              dateOfBirth
              age
              passportNumber
              createdAt
              updatedAt
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        return {
          limit: 9999,
          skip: 0,
          query: { user: this.userId },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    calculateAge(value) {
      let age;
      let dateOfBirth;

      if (value && value.length === 13) {
        // Calculate date of birth from South African ID number
        const year = value.substr(0, 2);
        const month = value.substr(2, 2);
        const day = value.substr(4, 2);

        dateOfBirth = new Date(`${year}-${month}-${day}`);
      } else {
        // Use the provided date of birth
        dateOfBirth = new Date(value);
      }

      const birthDate = new Date(dateOfBirth);
      const today = new Date();
      age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return {
        dateOfBirth,
        age,
      };
    },

    saveDob() {
      const idNumber = this.dependent.idNumber;

      if (idNumber && idNumber.length === 13) {
        const { dateOfBirth, age } = this.calculateAge(idNumber);
        this.dependent.dateOfBirth = dateOfBirth;
        this.dependent.age = age.toString();
      }

      // ...other save logic
    },
    async save() {
      this.isLoading = true;
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.isEdit) {
        await this.updateDependent()
          .then()
          .catch((e) => {});
      } else {
        // create
        await this.createDependent()
          .then()
          .catch((e) => {});
      }
      this.isLoading = false;
    },
    async updateDependent() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateDependent($dependent: DependentUpdateInput!) {
              updateDependent(dependent: $dependent) {
                id
                updated
              }
            }
          `,
          variables: {
            dependent: {
              id: self.dependentModel.id,
              firstName: self.dependentModel.firstName,
              lastName: self.dependentModel.lastName,
              title: self.dependentModel.title,
              relationship: self.dependentModel.relationship,
              gender: self.dependentModel.gender,
              race: self.dependentModel.race,
              telNumber: self.dependentModel.telNumber,
              altNumber: self.dependentModel.altNumber,
              depEmail: self.dependentModel.depEmail,
              businessEmail: self.dependentModel.businessEmail,
              faxNumber: self.dependentModel.faxNumber,
              region: self.dependentModel.region,
              postalCode: self.dependentModel.postalCode,
              bio: self.dependentModel.bio,
              rating: self.dependentModel.rating,
              dateOfBirth: self.dependentModel.dateOfBirth,
              idNumber: self.dependentModel.idNumber,
              fullAddress: self.dependentModel.fullAddress,
              industry: self.dependentModel.industry,
              role: self.dependentModel.role,
              referral: self.dependentModel.referral,
              branch: self.dependentModel.branch,
              branchName: self.dependentModel.branchName,
              consultant: self.dependentModel.consultant,
              mailingPref: self.dependentModel.mailingPref,
              product: self.dependentModel.product
                ? self.dependentModel.product
                : "",
              policyNumber: self.dependentModel.policyNumber,
              dependentRelationship: self.dependentModel.dependentRelationship,
              passportNumber: self.dependentModel.passportNumber,
              age: self.dependentModel.age,
            },
          },
        });
        if (response) {
          this.$swal(
            "Success!",
            "Dependent is Updated successfully ",
            "success"
          );
        }
        if (!response) {
          this.$swal(
            "Error!",
            "something went wrong when trying to Update a Dependent records, check data and try again later!",
            "error"
          );
          throw "Something went wrong when creating the dependent!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createDependent() {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.DependentCreateForm.validate()) {
        try {
          const {
            firstName,
            lastName,
            idNumber,
            passportNumber,
            fullAddress,
            telNumber,
            altNumber,
            depEmail,
            title,
            gender,
            dateOfBirth,
            age,
            race,
            branch,
            referral,
            product,
            productName,
            policyNumber,
            dependentRelationship,
          } = self.dependent;
          const createdDependent = await this.$apollo.mutate({
            mutation: gql`
              mutation ($dependent: DependentCreateInput!) {
                createDependent(dependent: $dependent) {
                  id
                  dependentRelationship
                }
              }
            `,
            variables: {
              dependent: {
                user: this.userId,
                firstName,
                lastName,
                idNumber,
                passportNumber,
                fullAddress,
                telNumber,
                altNumber,
                depEmail,
                branch,
                title,
                gender,
                dateOfBirth,
                race,
                referral,
                product,
                productName,
                policyNumber,
                dependentRelationship,
                age,
              },
            },
            watchLoading(isLoading, countModifier) {
              self.isLoading = isLoading;
            },
          });
          if (createdDependent) {
            this.$swal(
              "Success!",
              "Dependent is Created successfully ",
              "success"
            );
          }
          if (!createdDependent) {
            this.$swal(
              "Error!",
              "something went wrong when trying to create a Dependent records, check data and try again later!",
              "error"
            );
            self.errorMessage = `Dependent Not Created/ All required fields must be completed`;
            return "Something went wrong when creating the dependent!";
          }
          self.successMessage = `Dependent Created Successfully`;
          self.$eventHub.$emit("dependent-updated");
        } catch (error) {
          self.errorMessage = `Dependent Not Created due to:  ${error.message}`;
        }
      } else {
        self.errorMessage = `Dependent Not Created/ All required fields must be completed.`;
      }
    },
    async refreshPage() {},
    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteDependent(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "Dependent was successfully deleted",
                "success"
              );
            }
          } catch (e) {
            self.errorMsg = e.message;
          }
        }
      });
    },

    refreshData() {
      this.$apollo.queries.dependents.refetch();
    },

    removeFalsy(obj) {
      const newObj = {};
      Object.keys(obj).forEach((prop) => {
        if (obj[prop]) {
          newObj[prop] = obj[prop];
        }
      });
      return newObj;
    },

    async showEditDependent(item) {
      this.isEdit = true;
      this.newDependent = [];
      this.dependentModel = new Dependent();
      this.dependentModel.id = item.id;
      this.dependentModel.user = this.userId;
      this.dependentModel.firstName = item.firstName;
      this.dependentModel.lastName = item.lastName;
      this.dependentModel.idNumber = item.idNumber;
      this.dependentModel.fullAddress = item.fullAddress;
      this.dependentModel.telNumber = item.telNumber;
      this.dependentModel.depEmail = item.depEmail;
      this.dependentModel.branch = item.branch;
      this.dependentModel.race = item.race;
      this.dependentModel.gender = item.gender;
      this.dependentModel.title = item.title;
      this.dependentModel.altNumber = item.altNumber;
      this.dependentModel.product = item.product;
      this.dependentModel.productName = item.productName;
      this.dependentModel.policyNumber = item.policyNumber;
      this.dependentModel.dependentRelationship = item.dependentRelationship;
      this.dependentModel.passportNumber = item.passportNumber;
      this.dependent.dateOfBirth = item.dateOfBirth;
      this.dependentModel.age = item.age;
      this.dependent.idNumber = item.idNumber;
      this.dependentModel.race = item.race;
      this.editDialog = true;
    },
  },
  computed: {
    dependentItemsToRender() {
      return _.omit(this.dependent, ["__typename", "id"]);
    },
  },
};
</script>
<style lang="scss" scoped>
div.remove-btn {
  margin-bottom: 10px;
}
.tabs {
  margin-top: -50px;
}
</style>
