<template>
  <v-autocomplete
    v-model="chosenGenderInternal"
    v-bind="$attrs"
    :items="formattedGenders"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "GenderPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
    chosenGender: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      formattedGenders: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
        { text: "Other", value: "Other" },
      ],
    };
  },
  computed: {
    chosenGenderInternal: {
      get() {
        return this.chosenGender;
      },
      set(value) {
        if (value !== this.chosenGender) {
          this.$emit("update:chosen-gender", value || "");
        }
      },
    },
  },
  watch: {
    initial: {
      immediate: true,
      handler(newValue) {
        this.chosenGenderInternal = newValue;
      },
    },
  },
  mounted() {
    this.chosenGenderInternal = this.initial;
  },
  methods: {},
};
</script>

<style lang="sass"></style>
