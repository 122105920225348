<template>
  <v-autocomplete
    v-model="chosenDocument"
    v-bind="$attrs"
    :items="formattedDocuments"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "DocumentPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return [];
      }
    }
  },
  data: () => ({
    chosenDocument: "",
    totalRecords: 0,
    isLoading: false,
    returnedDocuments: [],
    formattedDocuments: []
  }),
  apollo: {
    documents: {
      query: gql`
        query Documents($limit: Int!, $skip: Int!, $query: JSON!) {
          documents(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              title
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          skip: 0,
          query: {}
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedDocuments = data.documents.records;
        this.formattedDocuments = _.map(this.returnedDocuments, document => {
          return { text: `${document.title}`, value: document.id };
        });
      }
    }
  },
  watch: {
    chosenDocument(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-document",
          this.chosenDocument ? this.chosenDocument : ""
        );
      }
    }
  },
  async mounted() {
    this.initial
      ? (this.chosenDocument = this.initial)
      : (this.chosenDocument = "");
  },
  methods: {}
};
</script>

<style lang="sass"></style>
