export class KanbanTask {
    constructor() {
      this.id = "";
      this.user = null;
      this.userName = "";
      this.description = "";
      this.assignedTo = null;
      this.assignedToName = "";
      this.status = "";
    }
  }
  