<template>
  <v-autocomplete
    v-model="chosenEventStatus"
    v-bind="$attrs"
    :items="formattedEventStatuses"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "EventStatusPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "open";
      },
    },
  },
  data: () => ({
    chosenEventStatus: "",
    isLoading: false,
    formattedEventStatuses: [
      { text: "open", value: "open" },
      { text: "closed", value: "closed" },
      { text: "resolved", value: "resolved" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenEventStatus = this.initial;
    },
    chosenEventStatus(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-event-status",
          this.chosenEventStatus ? this.chosenEventStatus : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenEventStatus = this.initial)
      : (this.chosenEventStatus = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
