<template>
  <v-autocomplete
    v-model="chosenRace"
    v-bind="$attrs"
    :items="formattedRaces"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "RacePicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      chosenRace: "", // Initialize with an empty string
      isLoading: false,
      formattedRaces: [
        { text: "White", value: "White" },
        { text: "Black", value: "Black" },
        { text: "Indian", value: "Indian" },
        { text: "Coloured", value: "Coloured" },
        { text: "Chinese", value: "Chinese" },
        { text: "Asian", value: "Asian" },
      ],
    };
  },
  watch: {
    initial(newValue, oldValue) {
      this.chosenRace = this.initial;
    },
    chosenRace(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-race",
          this.chosenRace ? this.chosenRace : ""
        );
      }
    },
  },
  mounted() {
    this.chosenRace = this.initial; // Set the initial value on component mount
  },
};
</script>

<style lang="sass"></style>
