<template>
  <v-container>
    <v-file-input label="Choose a file" ref="fileInput">
      <template v-slot:prepend-inner>
        <v-btn text color="primary" @click="handleFileUpload">Upload</v-btn>
      </template>
    </v-file-input>
    <v-progress-linear
      v-if="uploadProgress !== null"
      :value="uploadProgress"
      color="primary"
      height="10"
    ></v-progress-linear>
  </v-container>
</template>

<script>
import axios from "axios";
import { compressPDF } from "../../helpers/functions.js";

export default {
  data() {
    return {
      uploadProgress: null,
    };
  },
  methods: {
    async handleFileUpload() {
      const file = this.$refs.fileInput.files[0];
      if (!file) {
        return;
      }
      const compressedFile = await compressPDF(file);
      this.uploadFile(compressedFile);
    },
    uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file);
      console.log(formData);

      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.uploadProgress = progress;
        },
      };

      axios
        .post("/upload", formData, config)
        .then((response) => {
          console.log("File uploaded successfully");
          this.uploadProgress = null; // Reset the progress bar
          // Handle any additional logic after successful upload
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          this.uploadProgress = null; // Reset the progress bar
          // Handle error cases
        });
    },
  },
};
</script>
