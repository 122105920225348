<template>
  <v-autocomplete
    v-model="chosenUser"
    v-bind="$attrs"
    :items="formattedUsers"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    color="blue-grey lighten-2"
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "EmailPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenUser: [],
    totalRecords: 0,
    isLoading: false,
    returnedUsers: [],
    formattedUsers: [],
  }),
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int!, $page: Int!, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              email
            }
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          page: 1,
          query: { role: { $in: ["admin", "consultant"] } },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedUsers = data.users.records;
        this.formattedUsers = _.map(this.returnedUsers, (user) => {
          return {
            text: `${user.firstName} ${user.lastName}-${user.email}`,
            value: user.email,
          };
        });
      },
    },
  },
  watch: {
    chosenUser(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-user",
          this.chosenUser ? this.chosenUser : ""
        );
      }
    },
  },
  async created() {
    this.$eventHub.$on("user-updated", this.refreshData);
  },
  async mounted() {
    this.initial ? (this.chosenUser = this.initial) : (this.chosenUser = "");
  },
  methods: {
    refreshData() {
      console.log("Refetching users");
      this.$apollo.queries.users.refetch();
    },
  },
};
</script>

<style lang="sass"></style>
