<template>
  <div>
    <div class="card-wrapper">
      <div class="card-header">
        <h2 class="card-title">
          {{ task.userName }}
        </h2>
      </div>

      <v-row>
        <div class="card-content" @click="openTaskDescription()">
          <p v-if="this.me.role === 'admin'">
            <strong>Assigned Consultant: </strong> {{ task.assignedToName }}
          </p>
          <p class="my-6">
            <strong>Description: </strong><br />
            {{ task.description }}
          </p>
        </div>
      </v-row>
      <v-row> </v-row>
      <div class="card-footer flex mt-4 justify-between items-center">
        <v-row>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                color="#feb809"
                v-on="on"
                style="margin-right: 10px"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <v-list light>
              <v-list-item @click="showEditKanbanTask(task)">
                <v-icon class="mr-2">mdi-account-edit</v-icon>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(task.id)">
                <v-icon class="mr-2">mdi-account-multiple-remove</v-icon>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-dialog v-model="eventDialog" max-width="800px">
            <template v-slot:activator="{ attrs }">
              <div class="mx-auto text-right">
                <v-btn
                  fab
                  x-small
                  class="mx-auto"
                  color="secondary"
                  v-bind="attrs"
                  @click="newEvent(task)"
                >
                  <v-icon>mdi-calendar-check</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5"
                  >{{ `${isEdit ? "Edit" : "New"} Event` }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <base-event-type-picker
                        :chosen-event-type.sync="event.type"
                        :initial="event.type"
                        label="Event Type"
                        required
                        outlined
                        :rules="[(v) => !!v || 'Event Type is required']"
                      />
                    </v-col>
                    <v-col cols="12">
                      <!--v-text-field
                        v-model="event.status"
                        label="Event Status"
                        outlined
                        disabled
                        required
                        color="primary"
                        :rules="[(v) => !!v || 'Event Status is required']"
                      /-->
                      <base-event-status-picker
                        :chosen-event-status.sync="event.status"
                        :initial="event.status"
                        label="Event Status"
                        required
                        disabled
                        outlined
                        :rules="[(v) => !!v || 'Event Status is required']"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="event.comment"
                        label="Comment"
                        outlined
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <base-date-time-picker
                        :label="'Start Date and Time'"
                        :epoch-date-time.sync="event.startDate"
                        :initial="event.startDate"
                      />
                    </v-col>
                    <v-col cols="12">
                      <base-date-time-picker
                        :label="'End Date and Time'"
                        :epoch-date-time.sync="event.endDate"
                        :initial="event.endDate"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="eventDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="secondary" @click="createEvent"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--user-contact x-small :me="me" :user="me" /-->
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <span
            class="task-date font-italic grey--text text-right"
            style="font-size: 12px; padding-right: 10px"
          >
            {{ task.createdAt | dateFormat(task.createdAt) }}
          </span>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="editFormModal" persistent max-width="550">
      <TaskEditForm
        v-if="editFormModal"
        :task="task"
        @closed="editFormModal = false"
      ></TaskEditForm>
    </v-dialog>
    <v-dialog v-model="taskDescModal" max-width="550">
      <TaskSummery
        v-if="taskDescModal"
        :task="task"
        @editTask="
          taskDescModal = false;
          formModal = true;
        "
      ></TaskSummery>
    </v-dialog>
    <ConfirmBox
      :message="confirmBox.message"
      v-if="confirmBox.show"
      @close="getConfirmBoxValue"
    ></ConfirmBox>
  </div>
</template>
<script>
import { gql } from "apollo-boost";
import TaskEditForm from "./TaskEditForm.vue";
import Chip from "../Chip.vue";
import ConfirmBox from "./ConfirmBox.vue";
import TaskSummery from "./TaskSummery.vue";
import { Event } from "@/models/Event";
import { KanbanTask } from "@/models/KanbanTask";
import UserContact from "../User/UserContact.vue";

export default {
  name: "TaskCard",
  components: {
    Chip,
    TaskEditForm,
    ConfirmBox,
    TaskSummery,
    UserContact,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    // user: {
    //   type: Object,
    //   default: function() {
    //     return {};
    //   },
    // },
  },
  data() {
    return {
      formModal: false,
      editFormModal: false,
      eventFormModal: false,
      taskDescModal: false,
      user: "",
      confirmBox: {
        show: false,
        message: null,
        payload: null,
      },
      event: {
        type: "",
        comment: "",
        user: "",
        status: "",
        startDate: Number,
        endDate: Number,
        createdBy: "",
      },
      me: "",
      user: "",
      eventDialog: false,
      isEdit: false,
      eventModel: new Event(),
      kanbanTaskModel: new KanbanTask(),
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            title
            relationship
            gender
            race
            telNumber
            altNumber
            email
            businessEmail
            faxNumber
            bio
            rating
            age
            dob
            idNumber
            fullAddress
            industry
            role
            referral
            branch
            branchName
            consultant
            # consultantName
            postalCode
            region
            mailingPref
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.userModel.id = this.user.id;
        this.userModel.firstName = this.user.firstName;
        this.userModel.lastName = this.user.lastName;
        this.userModel.title = this.user.title;
        this.userModel.relationship = this.user.relationship;
        this.userModel.gender = this.user.gender;
        this.userModel.race = this.user.race;
        this.userModel.telNumber = this.user.telNumber;
        this.userModel.altNumber = this.user.altNumber;
        this.userModel.email = this.user.email;
        this.userModel.businessEmail = this.user.businessEmail;
        this.userModel.faxNumber = this.user.faxNumber;
        this.userModel.rating = this.user.rating;
        this.userModel.bio = this.user.bio;
        this.userModel.dob = this.user.dob;
        // years = this.user.age;
        this.userModel.age = this.user.age;
        this.userModel.idNumber = this.user.idNumber;
        this.userModel.fullAddress = this.user.fullAddress;
        this.userModel.industry = this.user.industry;
        this.userModel.role = this.user.role;
        this.userModel.referral = this.user.referral;
        this.userModel.consultant = this.user.consultant;
        this.userModel.branch = this.user.branch;
        this.userModel.branchName = this.user.branchName;
        this.userModel.postalCode = this.user.postalCode;
        this.userModel.region = this.user.region;
        this.userModel.mailingPref = this.user.mailingPref;
        this.userModel.companyName = this.user.companyName;
      },
    },
  },
  computed: {},
  filters: {
    dateFormat(date) {
      let d = new Date(date);
      let datestring =
        ("0" + d.getDate()).slice(-2) +
        "." +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "." +
        d.getFullYear() +
        " " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2);
      return datestring;
    },
  },
  created() {
    this.event.user = this.task.user;
    this.event.createdBy = this.me.id;
    this.event.status = "open";
    // this.event.status = this.task.status;
    this.event.startDate = this.eventModel.startDate;
    this.event.endDate = this.eventModel.endDate;
  },
  watch: {},
  methods: {
    async createEvent() {
      const self = this;
      try {
        const { type, comment, user, status, startDate, endDate, createdBy } =
          self.event;
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createEvent($event: EventCreateInput!) {
              createEvent(event: $event) {
                id
              }
            }
          `,
          variables: {
            event: {
              type,
              comment,
              user,
              status,
              startDate,
              endDate,
              createdBy,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Event!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createEvent) {
          self.createEvent.id = response?.data?.createEvent?.id;
          this.$swal("Success!", "Event was successfully created", "success");
        }
      } catch (e) {
        this.$swal(
          "Error!",
          `Something went wrong when creating the Event, check data and try again later!`,
          "error"
        );
      }
    },
    async newEvent(task) {
      const self = this;
      if (!task.assignedTo) {
        self.$swal(
          "Error!",
          "Events cannot be added until this user is assigned to a Consultant",
          "error"
        );
        return;
      }
      self.isEdit = false;
      self.eventDialog = true;
    },
    editTask() {
      this.formModal = true;
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteKanbanTask(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "Task was successfully deleted",
                "success"
              );
            }
          } catch (e) {
            self.errorMsg = e.message;
          }
        }
      });
    },
    openTaskDescription() {
      this.taskDescModal = true;
    },
    closeForm() {
      if (this.task) {
        // this.task = this.$store.getters['tasks/GET_TASK_BY_ID'](this.task.id);
      }
      this.formModal = false;
    },
    close() {
      this.editFormModal = false;
    },
    async showEditKanbanTask(task) {
      this.isEdit = true;
      this.editFormModal = true;
    },
  },
};
</script>
<style lang="scss">
.card-wrapper {
  background-color: #fff;
  border-radius: 15px;
  border-width: 2px;
  padding: 12px 8px;
  box-shadow: 0px 21px 16px -19px #5a5a5a; // 0 8px 6px -6px #5a5a5a;
  padding: 0.25rem 0.75rem 0.75rem 0.75rem;
  color: #4a5568;
  cursor: move;
  /* border: 1px solid #2096e6; */
  .caption {
    font-family: "Baloo Tamma 2", cursive !important;
    font-size: 10px !important;
  }
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-title {
      cursor: pointer;
      font-weight: 700;
      margin-left: 10px;
      font-size: 18px;
      text-transform: capitalize;
    }
    .card-desc {
      font-weight: 400px;
    }
    img {
      border-radius: 9999px;
      margin-left: 12px;
      width: 32px;
      height: 32px;
      align-self: baseline;
    }
  }
  div.card-content {
    margin-top: 33px;
    margin-left: 20px;
  }
  .card-content p {
    margin-bottom: -20px;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: baseline;
    margin-left: 20px;
    margin-bottom: 30px;
    .task-date {
      font-size: 8px;
      font-style: italic;
      flex-grow: 1;
    }
    button {
      flex-shrink: 1;
      &:hover {
        &::before {
          opacity: 0;
        }
      }
    }
  }
}
.lane-title {
  text-decoration-line: none !important;
}
div#Needs\ Reviewing .lane-title {
  color: #4e4444 !important;
}
</style>
