<template>
  <div>
    <v-file-input
      v-model="selectedFile"
      label="Click here to select a PDF file (max 10MB)"
      accept=".pdf"
      :rules="fileRules"
      outlined
    >
      <template v-slot:append>
        <v-btn
          @click="uploadFileToPublito"
          :disabled="!selectedFile || uploading"
          v-if="selectedFile"
        >
          click here to upload
        </v-btn>
      </template>
    </v-file-input>
    <div class="my-4">
      <v-progress-linear
        v-if="uploading"
        :value="uploadProgress"
        height="11"
        rounded
      >
        <span class="my-2">{{ uploadProgress }}%</span>
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
import PublitioAPI from "publitio_js_sdk";
import { gql } from "apollo-boost";

export default {
  name: "NewFileUpload",
  props: {
    userProduct: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      publitio: null,
      selectedFile: null,
      fileRules: [
        (value) => !!value || "File is required",
        (value) =>
          !this.selectedFile ||
          this.selectedFile.size <= 10 * 1024 * 1024 ||
          "File size must be less than 10MB",
      ],
      uploading: false,
      uploadProgress: 0,
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
  },
  created() {
    console.log(
      "process.env.PUBLITO_API_KEY",
      process.env.VUE_APP_PUBLITO_API_KEY
    );
    console.log(
      "process.env.PUBLITO_API_SECRET",
      process.env.VUE_APP_PUBLITO_API_SECRET
    );
    // Initialize publitio using environment variables
    this.publitio = new PublitioAPI(
      process.env.VUE_APP_PUBLITO_API_KEY,
      process.env.VUE_APP_PUBLITO_API_SECRET
    );
  },
  methods: {
    async uploadFileToPublito() {
      if (!this.selectedFile) {
        return;
      }

      this.uploading = true;
      this.uploadProgress = 0;

      //       const publitio = new PublitioAPI(this.apiKey, this.secret);

      try {
        const file = this.selectedFile; // Keep the original selected file
        const uploadResult = await this.publitio.uploadFile(file, "file");

        const document = {
          name: this.selectedFile.name,
          publitoDownloadUrl: uploadResult.url_download,
          publitoEmbeddUrl: uploadResult.url_embed,
          publitoUrl: uploadResult.url_preview,
          user: this.$route.params.id,
          userProduct: this.userProduct.id,
          createdBy: this.me.id,
        };
        console.log("document object in uploadpublito", document);

        await this.uploadDocument(file, document);
      } catch (error) {
        console.error("An error occurred during upload:", error);
        this.$swal("Upload Error", "An error occurred during upload", "error");
      } finally {
        this.uploading = false;
        this.uploadProgress = 0;
      }
    },
    async uploadDocument(file, document) {
      try {
        console.log("this.userProduct for document:", this.userProduct);
        console.log("this.userProduct.id for document:", this.userProduct.id);
        this.progress = 0;

        // Assign values from document to respective properties
        document.publitoDownloadUrl = document.publitoDownloadUrl;
        document.publitoEmbeddUrl = document.publitoEmbeddUrl;
        document.publitoUrl = document.publitoUrl;

        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation createDocument($document: DocumentCreateInput!) {
              createDocument(document: $document) {
                id
                name
                publitoDownloadUrl
                publitoEmbeddUrl
                publitoUrl
                userProduct
                user
                createdBy
              }
            }
          `,
          variables: {
            document,
          },
          uploadProgress: (progressEvent) => {
            // Calculate and update the progress
            this.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        });
        if (!response || response.errors) {
          console.log("error", response.errors);
          throw new Error("Something went wrong when creating the document!");
        }
        console.log(`Uploaded ${file.name} Successfully`);
        this.$emit("document-uploaded", response.data.createDocument);
        this.progress = null;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style></style>
