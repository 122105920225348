<template>
  <v-autocomplete
    v-model="chosenLanguage"
    v-bind="$attrs"
    :items="formattedLanguages"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "LanguagePicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenLanguage: "",
    isLoading: false,
    formattedLanguages: [
      { text: "Zulu", value: "Zulu" },
      { text: "Xhosa", value: "Xhosa" },
      { text: "Afrikaans", value: "Afrikaans" },
      { text: "English", value: "English" },
      { text: "Sepedi", value: "Sepedi" },
      { text: "Tswana", value: "Tswana" },
      { text: "Sesotho", value: "Sesotho" },
      { text: "Tsonga", value: "Tsonga" },
      { text: "Swati", value: "Swati" },
      { text: "Venda", value: "Venda" },
      { text: "Ndebele", value: "Ndebele" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenLanguage = this.initial;
    },
    chosenLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-language",
          this.chosenLanguage ? this.chosenLanguage : ""
        );
      }
    },
  },
  mounted() {
    this.chosenLanguage = this.initial; // Set the initial value on component mount
  },
  methods: {},
};
</script>

<style lang="sass"></style>
