import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/styles/overrides.scss";
import "@/styles/overrides.sass";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#fab71d",
        secondary: "#1c3055",
        anchor: "#1c3055",
      }
    },
  },
});
