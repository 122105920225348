<template>
  <v-card class="my-0">
    <v-card-text>
      <v-form ref="UserCreateForm" v-model="formValid">
        <base-loading :is-loading="isLoading" />
        <v-alert v-if="successMessage" type="success">
          {{ successMessage }}
        </v-alert>
        <v-alert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </v-alert>

        <v-row>
          <h3 class="ml-8">Medsafu portal Information</h3>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.firstName"
              color="primary"
              label="First Name"
              outlined
              required
              :rules="[(v) => !!v || 'First name is required']"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.lastName"
              color="primary"
              label="Last Name"
              outlined
              required
              :rules="[(v) => !!v || 'Last name is required']"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.email"
              color="primary"
              label="Email"
              outlined
              required
              :rules="[
                (v) => !!v || 'Email is required',
                (v) =>
                  /.+@.+\..+/.test(v) || 'Email Address format must be valid',
              ]"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.password"
              color="primary"
              label="Password"
              outlined
              clearable
              required
              :rules="[(v) => !!v || 'Password is required']"
            />
          </v-col>
        </v-row>
        <v-row>
          <h3 class="ml-8">Branch Information</h3>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12" md="12">
            <base-branch-picker
              :chosen-branch.sync="user.branch"
              :initial="user.branch"
              label="Branch"
              outlined
            />
          </v-col>
          <v-col cols="12" md="12" class="consultant-picker">
            <user-edit-picker
              :label="'Assigned Consultant'"
              :initial="user.consultant"
              :chosen-user.sync="user.consultant"
              :role="'consultant'"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="!formValid"
        color="primary"
        class="mx-2"
        @click="[save(), refreshClientData()]"
      >
        Save
      </v-btn>
      <!-- <v-btn
        :disabled="!formValid"
        color="primary"
        class="mx-2"
        @click="refreshPage()"
      >
        Refresh
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { gql } from "apollo-boost";
import UserEditPicker from "../UserEditPicker.vue";
// import moment from 'moment'
export default {
  name: "UserCreate",
  documents: [],
  components: {
    UserEditPicker,
  },
  data: () => ({
    isLoading: false,
    formValid: true,
    user: {
      password: "",
      firstName: "",
      lastName: "",
      idNumber: "",
      passport: "",
      fullAddress: "",
      telNumber: "",
      email: "",
      role: "consultant",
      branch: "",
      race: "",
      gender: "",
      title: "",
      referral: "",
      consultant: "",
    },
    successMessage: "",
    errorMessage: "",
  }),
  computed: {},
  async mounted() {},
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      await this.saveUser()
        .then(() => {
          this.$swal("Success!", "User was created", "success");
          window.location.href = `/admin/consultants`;
        })
        .catch((e) => {
          console.log(e);
          this.$swal(
            "Error!",
            `Something went wrong when creating the User, check data and try again later!`,
            "error"
          );
        });
      this.isLoading = false;
    },
    async refreshClientData() {
      this.$apollo.queries.users.refetch();
    },
    async refreshPage() {},
    async saveUser() {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.UserCreateForm.validate()) {
        try {
          const {
            password,
            firstName,
            lastName,
            idNumber,
            passport,
            fullAddress,
            telNumber,
            altNumber,
            email,
            role,
            title,
            gender,
            race,
            branch,
            referral,
            consultant,
          } = self.user;
          const createdUser = await this.$apollo.mutate({
            mutation: gql`
              mutation ($user: UserCreateInput!) {
                createUser(user: $user) {
                  id
                }
              }
            `,
            variables: {
              user: {
                password,
                firstName,
                lastName,
                idNumber,
                passport,
                fullAddress,
                telNumber,
                altNumber,
                email,
                role,
                branch,
                title,
                gender,
                race,
                referral,
                consultant,
              },
            },
            watchLoading(isLoading, countModifier) {
              self.isLoading = isLoading;
            },
          });
          console.log(createdUser);
          if (!createdUser) {
            return "Something went wrong when creating the user!";
          }
          self.successMessage = `User Created Successfully`;
          self.$eventHub.$emit("user-updated");
        } catch (error) {
          self.errorMessage = `User Not Created / ${error.message}`;
        }
      } else {
        self.errorMessage = `User Not Created/ All required fields must be completed.`;
      }
    },
  },
};
</script>

<style lang="css">
.consultant-picker {
  display: none;
}
</style>
