<template>
  <div class="Label">
    <label>Date of Birth</label>
    <div class="FormDate" @keyup.capture="updateValue">
      <v-text-field
        v-if="showDay"
        ref="day"
        v-model="day"
        class="FormDate__input FormDate__input--day"
        placeholder="dd"
        type="number"
        @input="updateDay"
        @blur="day = day.padStart(2, 0)"
      />
      <span v-if="showDay && showMonth" class="FormDate__divider">/</span>
      <v-text-field
        v-if="showMonth"
        ref="month"
        v-model="month"
        class="FormDate__input FormDate__input--month"
        placeholder="mm"
        type="number"
        @input="updateMonth"
        @blur="month = month.padStart(2, 0)"
      />
      <span v-if="showYear && (showDay || showMonth)" class="FormDate__divider"
        >/</span
      >
      <v-text-field
        v-if="showYear"
        ref="year"
        v-model="year"
        class="FormDate__input FormDate__input--year"
        placeholder="yyyy"
        type="number"
        @blur="year = year.padStart(4, 0)"
      />
      <!-- <span
        v-show="errors.has('day')"
        class="text-danger"
      >{{ errors.first('day') }}</span> -->
      <!-- <span
        v-show="errors.has('month')"
        class="text-danger"
      >{{ errors.first('month') }}</span>  -->
      <!-- <span
        v-show="errors.has('year')"
        class="text-danger"
      >{{ errors.first('year') }}</span>  -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DateInput",
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    showDay: {
      type: Boolean,
      default: true,
    },
    showMonth: {
      type: Boolean,
      default: true,
    },
    showYear: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      day: `${this.value ? new Date(this.value).getDate() : ""}`,
      month: `${this.value ? new Date(this.value).getMonth() + 1 : ""}`,
      year: `${this.value ? new Date(this.value).getFullYear() : ""}`,
      //       currentYear: `${this.value ? Date().getFullYear() : ""}`,
      //       age: `${this.value ? Date().getFullYear() - year : ""}`,
      //       console.log(calculatedAge),
      dayRules: [
        (v) =>
          (v && v >= 1) || "The day you have chosen, can not be lower than 01",
        (v) =>
          (v && v <= 31) ||
          "The day you have chosen, must be less than 31 calender days",
      ],
      monthRules: [
        (v) =>
          (v && v >= 1) ||
          "The month you have chosen, can not be lower than 01",
        (v) =>
          (v && v <= 12) ||
          "The month you have chosen, must be less than or equal to 12",
      ],
      yearRules: [
        (v) =>
          (v && v >= 1990) ||
          "The year you have chosen, can not be lower than 1990",
        (v) =>
          (v && v <= 2030) ||
          "The year you have chosen, must be less than or equal to 2030",
      ],
    };
  },
  watch: {
    year(current, prev) {
      if (current > 9999) this.year = prev;
    },
  },
  methods: {
    // 	  calcAge() {
    // age=
    // 	  },
    updateDay() {
      if (!this.day.length || (parseInt(this.day, 10) < 4 && this.day < 31))
        return;
      if (this.showMonth) this.$refs.month.select();
      else if (this.showYear) this.$refs.year.select();
    },
    updateMonth() {
      if (!this.month.length || parseInt(this.month, 10) < 2) return;
      if (this.showYear) this.$refs.year.select();
    },
    updateValue() {
      const timestamp = JSON.stringify(
        `${this.year.padStart(4, 0)}-${this.month}-${this.day}`
      );

      if (Number.isNaN(timestamp)) return;

      this.$emit("input", timestamp);
    },
  },
};
</script>

<style lang="scss">
.Label {
  text-align: start;
  color: #cacaca;
}
.FormDate {
  $spacing: 0.75em;
  width: "100%";
  display: flex;

  position: relative;
  overflow: hidden;
  border: 1px solid #cacaca;
  border-radius: 0.25em;

  // 1. Hide the spinner button in Chrome, Safari and Firefox.
  &__input {
    padding: $spacing;
    padding-right: $spacing / 2;
    padding-left: $spacing / 2;
    border: none;
    text-align: center;

    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield; // 1

    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &:first-child {
      padding-left: $spacing;
    }

    &:last-child {
      padding-right: $spacing;
    }

    &:focus {
      outline: none;
    }

    &--day,
    &--month {
      width: 3em;
    }

    &--year {
      width: 4em;
    }
  }

  &__divider {
    padding-top: $spacing;
    padding-bottom: $spacing;
    pointer-events: none;
  }
}
</style>
