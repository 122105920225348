export class Dependent {
  constructor() {
    this.id = "";
    this.user = "";
    this.firstName = "";
    this.lastName = "";
    this.title = "";
    this.relationship = "";
    this.gender = "";
    this.race = "";
    this.telNumber = "";
    this.altNumber = "";
    this.depEmail = "";
    this.businessEmail = "";
    this.faxNumber = "";
    this.dateOfBirth = "";
    this.age = "";
    this.idNumber = "";
    this.passportNumber = "";
    this.fullAddress = "";
    this.postalCode = "";
    this.region = "";
    this.industry = null;
    this.bio = "";
    this.rating = 0;
    this.branch = "";
    this.product = "";
    this.productName = "";
    this.policyNumber = "";
    this.branchName = "";
    this.consultant = null;
    // this.consultantName = "";
    this.referral = null;
    this.relationship = null;
    this.existingProducts = null;
    this.productsInterestedIn = null;
    this.source = null;
    this.dependentRelationship = "";
  }

  get fullName() {
    var toArray = [this.firstName, this.lastName];
    return toArray
      .filter((item) => item)
      .join(" ")
      .trim();
  }
}
