<template>
  <base-stats-card
    :color="'red'"
    title="Average Premium Price"
    :value="averagePrice"
    :elevation="8"
    sub-icon="mdi-clock"
    :sub-text="subText"
    :is-loading="isLoading"
  />
</template>

<script>
  import Moment from 'moment'
  import { gql } from 'apollo-boost'
  export default {
    name: 'AveragePremiumCard',
    props: {
      initial: {
        type: String,
        default: function () {
          return ''
        },
      },
    },
    data: () => ({
      isLoading: false,
      subText: '',
      products: '',
    }),
    computed: {
      averagePrice () {
        var total = 0
        if (this.products && this.products.count > 0) {
          for (var i = 0; i < this.products.count; i++) {
            total = this.products.records[i].price
          }
          return 'R' + total / this.products.count
        }
        return 0
      },
    },
    apollo: {
      me: gql`query {
        me {
          id
          email
          role
        }
      }`,
      products: {
        query: gql`query Products($limit: Int! $skip: Int! $query: JSON!) {
          products(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              price
              name
            }
            count
          }
        }`,
        variables () {
          const limit = 100000
          const skip = 0
          var query = {}
          return {
            limit: limit,
            skip: skip,
            query: query,
          }
        },
        watchLoading (isLoading, countModifier) {
          this.isLoading = isLoading
        },
        result (data, key) {
          this.subText = 'Updated At ' + new Moment().format('HH:mm:ss')
          
        },
      },
    },
    async created () {
    },
    methods: {
    },
  }
</script>

<style lang="sass">
</style>
