<template>
    <v-container>
        <v-row>
            <v-col class="pa-0">
                <v-card>
                    <v-card-title>
                       <v-col cols="12" md="12">
                          <h1 class="ml-12">
                              Task Summary
                          </h1>
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>{{task.userName}}</h2>
                        </v-col>
                    </v-card-title>
                    <v-card-text>
                        <v-textarea
                            v-model="task.description"
                            label="Description"
                            outlined
                            disabled
                        ></v-textarea>
                        <v-text-field 
                            v-if="this.me.role === 'admin'"
                            v-model="task.assignedToName"
                            label="Assigned To" 
                            outlined 
                            disabled
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed color="error" @click="editTask()">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { gql } from "apollo-boost";

export default {
    name: 'TaskSummery',
    props: ['task'],
    data() {
        return {
            me: ""
        };
    },
    apollo: {
        me: gql`
        query {
            me {
            id
            role
            }
        }
        `,
    },
    methods: {
        editTask() {
            this.$emit('editTask');
        },
    },
};
</script>

<style lang="scss" scoped>
</style>