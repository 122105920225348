<template>
  <v-autocomplete
    v-model="chosenConsultant"
    v-bind="$attrs"
    :items="formattedConsultants"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "ConsultantPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
    role: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenConsultant: null,
    totalRecords: 0,
    isLoading: false,
    returnedConsultants: [],
    formattedConsultants: [],
  }),
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              branch
              branchName
            }
          }
        }
      `,
      variables() {
        var query = {};
        if (this.role) {
          query = { role: this.role };
        }
        return {
          limit: 9999,
          query: query,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedConsultants = data.users.records;
        this.formattedConsultants = _.map(this.returnedConsultants, (user) => {
          return { text: `${user.firstName} ${user.lastName} - ${user.branchName}`, value: user.id };
        });
      },
    },
  },
  watch: {
    /*initial(newValue, oldValue) {
      this.chosenUser = this.initial;
    },*/
    chosenConsultant(newValue) {
      this.$router.push("/admin/consultant/" + newValue + "/edit");
      console.log("/admin/consultant/" + newValue + "/edit");
      /* if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-user",
          //this.chosenUser ? this.chosenUser : null
        );
      }*/
    },
  },
  async mounted() {},
};
</script>

<style lang="sass"></style>
