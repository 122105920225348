<template>
  <v-dialog v-model="dialog" persistent scrollable light max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="buttonText" class="mx-2" v-bind="$attrs" v-on="on">
        {{ buttonText }}
      </v-btn>
      <v-btn
        v-else
        class="mx-2"
        v-bind="$attrs"
        color="secondary"
        :elevation="8"
        v-on="on"
      >
        <v-icon dark left> mdi-lock-reset </v-icon>
        Reset password
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Password Reset</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="PasswordRestForm" v-model="formValid">
          <base-loading :is-loading="isLoading" />
          <v-alert
            v-if="successMessage"
            type="success"
            icon="mdi-firework"
            dense
          >
            {{ successMessage }}
          </v-alert>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
          <span v-if="this.$route.path === '/admin/admins'"
            ><p>Email associated with crm: {{ initialEmailAddress }}</p></span
          >
          <base-email-picker
            :chosen-user.sync="toSendTo"
            :initial="initialEmailAddress"
            label="Email to send link to"
            outlined
          />
        </v-form>
        <!-- <small>*indicates required field</small> -->
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn color="primary" @click="dialog = false"> Close </v-btn>
        <v-btn :disabled="!formValid" color="primary" @click="resetPassword()">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "PasswordResetModal",
  props: {
    buttonText: {
      type: String,
      default: function () {
        return "";
      },
    },
    initialEmailAddress: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    dialog: false,
    formValid: true,
    isLoading: false,
    email: "",
    toSendTo: "",
    successMessage: "",
    errorMessage: "",
  }),

  async created() {
    if (this.initialEmailAddress) {
      this.email = this.initialEmailAddress;
    }
  },
  methods: {
    async resetPassword() {
      const self = this;
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";

      if (self.$refs.PasswordRestForm.validate()) {
        try {
          const resetPasswordLink = await this.$apollo.mutate({
            mutation: gql`
              mutation ($email: String!, $toSendTo: String!) {
                getResetPasswordLink(email: $email, toSendTo: $toSendTo)
              }
            `,
            variables: {
              email: this.initialEmailAddress,
              toSendTo: this.toSendTo,
            },
          });

          if (resetPasswordLink) {
            self.successMessage = `Password Reset Link for ${this.initialEmailAddress} emailed to  ${this.email}`;
            setTimeout(() => {
              this.dialog = false;
            }, 2000);
          }

          self.isLoading = false;
        } catch (error) {
          if (error.response) {
            self.errorMessage = `${error.response.data}`;
          } else {
            self.errorMessage = `Password reset link not sent: ${error.message}`;
          }
          self.isLoading = false;
        }
      } else {
        self.errorMessage =
          "Password reset link not sent: All required fields must be completed.";
        self.isLoading = false;
      }
    },
  },
};
</script>

<style lang="sass"></style>
