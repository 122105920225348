<template>
  <base-material-card
  class="mt-4"
    title="Product types per count offered"
    color="'#feb809'"
    :elevation="8"
  >
    <pie
      style="height:45vh;"
      :chart-data="productCategories"
      :bind="true"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: {position:'right'},
      }"
    />
  </base-material-card>
</template>

<script>
  import { gql } from 'apollo-boost'
  import Pie from './Pie.vue'

  export default {
    name: 'ProductCategoryGraph',
    components: {Pie},
    apollo: {
      me: gql`query {
        me {
          id
          email
          role
        }
      }`,
      products: {
        query: gql`query Products($limit: Int! $skip: Int! $query: JSON!) {
          products(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              category

            }
            count
          }
        }`,
        variables () {
          const limit = 100000
          const skip = 0
          var query = { }
          return {
            limit: limit,
            skip: skip,
            query: query,
          }
        },
        watchLoading (isLoading, countModifier) {
          this.isLoading = isLoading
        },
        result (data, key) {
          var productRecords = data.data.products.records
          var groupedByCategory = _.groupBy(productRecords, 'category')
          console.log(groupedByCategory)
          this.productsGroupedByCategory = groupedByCategory
        },
      },
    },
    data: () => ({
      selected: '',
      products: '',
      productsGroupedByCategory: [],
    }),
    computed: {
      labels () {
        return _.map(this.productsGroupedByCategory, (item, index) => {
          if (index !== 'null') {
            return index
          }
          return 'Not Assigned'
        })
      },
      chartdata () {
        return _.map(this.productsGroupedByCategory, (item, index) => {
          return item.length
        })
      },
      productCategories () {
        return {
          labels: this.labels,
          datasets: [
            {
              label: 'Product types',
              borderColor: '#888',
              backgroundColor: this.getRandomColors(),
              borderWidth: 2,
              data: this.chartdata,
            },
          ],
        }
      },
    },
    async mounted () {
    },
    methods: {
      getRandomColors () {
        return _.map(this.productsGroupedByCategory, (item) => {
          var letters = '0123456789ABCDEF'.split('')
          var color = '#'
          for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)]
          }
          return color
        })
      },
    },
  }
</script>

<style lang="sass">
</style>