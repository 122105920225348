<template>
  <v-dialog v-model="dialog2" persistent scrollable light max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="buttonText"
        class="mx-2"
        fab
        dark
        large
        v-bind="$attrs"
        v-on="on"
      >
        <v-icon> mdi-eye </v-icon>
      </v-btn>
      <v-btn
        v-else
        class="mx-2"
        fab
        dark
        large
        v-bind="$attrs"
        color="#01B0F1"
        :elevation="3"
        v-on="on"
      >
        <v-icon> mdi-eye </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <!-- <h1 class="heading my-5">
          {{ userId.firstName }} {{ userId.lastName }}
        </h1> -->
      </v-card-title>
      <v-card-text>
        <v-simple-table v-if="$route.params.id != 0">
          <tbody>
            <tr>
              <td>Product</td>
              <td>
                {{ userProductModel.product ? userProductModel.product : "" }}
              </td>
            </tr>

            <tr>
              <td>Scheme Joined</td>
              <td>
                {{
                  userProductModel.schemeJoin ? userProductModel.schemeJoin : ""
                }}
              </td>
            </tr>
            <tr>
              <td>Late Joiner Penalty</td>
              <td>
                {{
                  userProductModel.lateJoinerPenalty
                    ? userProductModel.lateJoinerPenalty
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td>Total Contributions</td>
              <td>
                {{
                  userProductModel.totalContrib
                    ? userProductModel.totalContrib
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td>Inception Date</td>
              <td>
                {{
                  userProductModel.inceptionDate
                    ? userProductModel.inceptionDate
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td>Beneficiary Type</td>
              <td>
                {{
                  userProductModel.beneficiaryType
                    ? userProductModel.beneficiaryType
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td>Dependant Code</td>
              <td>
                {{
                  userProductModel.dependantCode
                    ? userProductModel.dependantCode
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td>Date Resigned</td>
              <td>
                {{
                  userProductModel.dateResigned
                    ? userProductModel.dateResigned
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td>benefitDate</td>
              <td>
                {{
                  userProductModel.benefitDate
                    ? userProductModel.benefitDate
                    : ""
                }}
              </td>
            </tr>
          </tbody></v-simple-table
        ></v-card-text
      >

      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn color="primary" @click="dialog2 = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UserProductsInfoModal",
  props: {
    buttonText: {
      type: String,
      default: function () {
        return "";
      },
    },
    user: {
      type: String,
      default: function () {
        return "";
      },
    },
    userProduct: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    dialog2: false,
    isLoading: false,
  }),
  computed: {},
  async created() {},
  methods: {},
};
</script>

<style lang="sass"></style>
