<template>
  <div>
    <div v-if="user === null || me === null">Loading...</div>
    <div v-else>
      <v-autocomplete
        v-model="chosenRole"
        v-bind="$attrs"
        :items="formattedRoles"
        append-icon="mdi-magnify"
        :loading="isLoading"
        :disabled="isLoading"
      />
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { User } from "@/models/User";

export default {
  name: "RolePicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      chosenRole: "",
      isLoading: false,
      me: null,
      user: null,
    };
  },

  watch: {
    initial(newValue, oldValue) {
      this.chosenRole = this.initial;
    },
    chosenRole(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-role",
          this.chosenRole ? this.chosenRole : ""
        );
      }
    },
  },
  mounted() {
    // Perform initial query
    this.$apollo
      .query({
        query: gql`
          query {
            me {
              id
              role
              branch
              branchName
              firstName
              lastName
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        console.log("GraphQL response:", response.data);
        if (response.data) {
          this.me = response.data.me;
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
          this.user.branch = this.me.branch;
          this.user.branchName = this.me.branchName;
        }
      })
      .catch((error) => {
        console.error("GraphQL error:", error);
        this.errorMsg = error;
      });
  },
  computed: {
    formattedRoles() {
      if (this.me) {
        const route = this.$route.fullPath;

        if (
          route.includes("admin/user/") ||
          route.includes("consultant/user/")
        ) {
          return [
            { text: "Existing User", value: "user" },
            { text: "Lead", value: "lead" },
          ];
        }

        if (this.me.role === "admin") {
          return [
            { text: "Admin", value: "admin" },
            { text: "Consultant", value: "consultant" },
            { text: "Existing User", value: "user" },
            { text: "Lead", value: "lead" },
          ];
        } else if (this.me.role === "consultant") {
          return [
            { text: "Existing User", value: "user" },
            { text: "Lead", value: "lead" },
          ];
        } else if (this.me.role === "superUser") {
          return [
            { text: "Existing User", value: "user" },
            { text: "Admin", value: "admin" },
            { text: "Consultant", value: "consultant" },
            { text: "Lead", value: "lead" },
          ];
        }
      }

      return [];
    },
  },
};
</script>

<style lang="sass"></style>
