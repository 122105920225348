<template>
  <div>
    <div class="text-right mb-4">
      <add-product-to-client
        @update-user-product="handleRefresh"
        :user-product="userProductModel"
        :userId="this.userId"
        :me="this.me"
      />
      <client-file-upload-other
        @update-user-products="handleRefresh"
        @the-files="theFiles"
      />
    </div>

    <div>
      <v-row>
        <v-col>
          <div v-if="userProducts">
            <table
              :key="userProducts.records.length"
              v-if="!isLoadingData"
              class="product-table"
            >
              <tr>
                <th class="product-column">Description</th>
                <th
                  v-for="(item, index) in userProducts.records"
                  :key="index"
                  class="product-column"
                >
                  <span>Product-{{ index + 1 }}</span>
                  <span>
                    <v-btn
                      fab
                      x-small
                      color="error"
                      class="ml-8"
                      @click="deleteItem(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </span>
                </th>
              </tr>
              <tr>
                <td>Active?</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    <v-row
                      ><v-col cols="4"
                        ><v-switch v-model="item.productStatus" /></v-col
                      ><v-col cols="4"
                        ><span
                          :style="{
                            color: item.productStatus ? 'green' : 'red',
                          }"
                        >
                          {{
                            item.productStatus
                              ? "Product is active"
                              : "Product is inactive"
                          }}
                        </span>
                      </v-col></v-row
                    >
                  </td>
                </template>
              </tr>
              <tr>
                <td>Product</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    <v-text-field v-model="item.productName" />
                  </td>
                </template>
              </tr>
              <tr>
                <td>Policy Number</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    <v-text-field v-model="item.policyNumber" />
                  </td>
                </template>
              </tr>

              <tr>
                <td>Benefit date</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    <v-dialog v-model="benefitDateDialog" max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="item.benefitDate"
                          color="primary"
                          label="Benefit Date"
                          clearable
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.benefitDate"
                        color="primary"
                        no-title
                        :landscape="false"
                        @input="benefitDateDialog = false"
                      ></v-date-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="benefitDateDialog = false"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-dialog>
                  </td>
                </template>
              </tr>
              <tr>
                <td>Date resigned</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    <v-dialog v-model="dateResignedDialog" max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="item.dateResigned"
                          color="primary"
                          label="Date Resigned"
                          clearable
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.dateResigned"
                        color="primary"
                        no-title
                        :landscape="false"
                        @input="dateResignedDialog = false"
                      ></v-date-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="dateResignedDialog = false"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-dialog>
                  </td>
                </template>
              </tr>
              <tr>
                <td>Cancel Date</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    <v-dialog v-model="cancelDateDialog" max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="item.cancelDate"
                          color="primary"
                          label="Cancel Date"
                          clearable
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.cancelDate"
                        color="primary"
                        no-title
                        :landscape="false"
                        @input="cancelDateDialog = false"
                      ></v-date-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="cancelDateDialog = false"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-dialog>
                  </td>
                </template>
              </tr>
              <tr>
                <td>Scheme Joined</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    <v-text-field
                      v-model="item.schemeJoin"
                      label="Scheme Joined"
                      clearable
                    />
                  </td>
                </template>
              </tr>
              <tr>
                <td>Dependent code</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    <v-text-field
                      v-model="item.dependantCode"
                      label="Dependent Code"
                      clearable
                    />
                  </td>
                </template>
              </tr>
              <tr>
                <td>Documents</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index">
                    <div v-if="item.documents && item.documents.length > 0">
                      <div
                        v-for="(document, docIndex) in item.documents"
                        :key="docIndex"
                        class="my-6 ml-4"
                      >
                        <v-row class="justify-space-between">
                          <p
                            @click="
                              document.documentLink
                                ? openLink(document)
                                : openPublitoLink(document)
                            "
                          >
                            <u>{{ document.name }}</u>
                          </p>

                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn
                                dark
                                small
                                class="mr-4"
                                @click="deleteDocument(document.id)"
                                color="error"
                                v-on="on"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Document</span>
                          </v-tooltip>
                        </v-row>
                      </div>
                    </div>
                  </td>
                </template>
              </tr>

              <tr>
                <td>Created At</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    {{ item.createdAt | getFormattedDate }}
                  </td>
                </template>
              </tr>
              <tr>
                <td>Updated At</td>
                <template v-for="(item, index) in userProducts.records">
                  <td :key="index.id">
                    {{ item.updatedAt | getFormattedDate }}
                  </td>
                </template>
              </tr>
            </table>
            <div v-else>
              <v-progress-linear indeterminate color="blue" class="mb-0" dark />
            </div>
            <div class="text-right my-4">
              <v-btn @click="updateUserProduct()">Save</v-btn>
            </div>
          </div>
          <div v-else>No user products available.</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

import { UserProduct } from "@/models/UserProduct";
import { GET_USER_PRODUCTS } from "../../../../queries";
import AddProductToClient from "../AddProductToClient.vue";
import FileUploadWithClientPicker from "../FileUploadWithClientPicker.vue";
import ClientFileUploadOther from "./ClientFileUploadOther.vue";
export default {
  components: {
    AddProductToClient,
    FileUploadWithClientPicker,
    ClientFileUploadOther,
  },
  name: "UserProducts",
  props: {
    userId: {
      type: String,
      default: function () {
        return "";
      },
    },

    buttonText: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      downloadOptions: {
        filename: "",
      },
      documentIdToDelete: null,
      currentPage: 0,
      pageCount: 0,
      isLoadingData: false,
      userProductModel: new UserProduct(),
      benefitDateDialog: false,
      dateResignedDialog: false,
      modalOpen: false,
      cancelDateDialog: false,
      theFiles: [],
      isEdit: false,
      me: "",
      userProducts: [],
      newDocuments: [],
      totalRecords: 0,
      isLoading: false,
      snack: false,
      user_id: "",
    };
  },

  created() {
    this.fetchUserProducts();
  },

  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
  },

  methods: {
    isBase64DataStringEmptyOrNull(base64DataString) {
      return !base64DataString;
    },
    async handleRefresh() {
      await this.fetchUserProducts();
    },
    async handleDocumentUploaded(document) {
      try {
        this.isLoadingData = true; // Show loader while uploading document

        this.newDocuments.push(document);
        await this.updateUserProduct();
        await this.fetchUserProducts();
      } catch (error) {
        console.error("Failed to handle uploaded document:", error);
      } finally {
        this.isLoadingData = false; // Hide loader after document upload completes
      }
    },
    async fetchUserProducts() {
      try {
        this.isLoadingData = true;

        const response = await this.$apollo.query({
          query: GET_USER_PRODUCTS,
          variables: {
            limit: 10,
            skip: 0,
            query: { user: this.userId },
          },
          fetchPolicy: "network-only",
        });

        if (response && response.data) {
          this.userProducts = response.data.userProducts;
        } else {
          console.error("No response received.");
        }
      } catch (error) {
        console.error("Failed to fetch user products:", error);
        throw error;
      } finally {
        this.isLoadingData = false;
      }
    },

    async updateUserProduct() {
      try {
        for (const item of this.userProducts.records) {
          const existingDocuments = item.documents || []; // Get existing documents or initialize as an empty array

          let updatedDocuments = existingDocuments;

          // If documentIdToDelete is set, remove it from the array
          if (this.documentIdToDelete) {
            updatedDocuments = existingDocuments.filter(
              (doc) => doc.id !== this.documentIdToDelete
            );
          } else {
            // Combine existing documents and new documents
            updatedDocuments = existingDocuments.concat(this.newDocuments);
          }
          const response = await this.$apollo.mutate({
            mutation: gql`
              mutation update($userProduct: UserProductUpdateInput!) {
                updateUserProduct(userProduct: $userProduct) {
                  id
                  updated
                }
              }
            `,
            variables: {
              userProduct: {
                id: item.id,
                user: this.userId,
                product: item.product,
                policyNumber: item.policyNumber,
                membershipNumber: item.membershipNumber,
                schemeJoin: item.schemeJoin,
                benefitDate: item.benefitDate,
                dateResigned: item.dateResigned,
                dependantCode: item.dependantCode,
                beneficiaryType: item.beneficiaryType,
                lateJoinerPenalty: item.lateJoinerPenalty,
                totalContrib: item.totalContrib,
                inceptionDate: item.inceptionDate,
                startDate: item.startDate,
                cancelDate: item.cancelDate,
                productStatus: item.productStatus,
                documents: updatedDocuments,
              },
            },
          });

          if (!response) {
            throw new Error(
              "Something went wrong when updating the user product!"
            );
          }

          if (response.errors && response.errors.length > 0) {
            throw new Error(response.errors[0]);
          }
        }
        // After showing the success alert
        this.$swal(
          "Success!",
          "Client Product was successfully updated",
          "success"
        ).then(() => {
          this.documentIdToDelete = null; // Reset documentIdToDelete
          this.fetchUserProducts();
        });
      } catch (e) {
        throw e.message;
      }
    },

    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteUserProduct(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (
              response &&
              response.data &&
              response.data.deleteUserProduct.deleted
            ) {
              this.$swal({
                title: "Success!",
                text: "Client Product was successfully deleted",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              }).then(() => {
                this.fetchUserProducts();
              });
            } else {
              this.$swal(
                "Error!",
                "Failed to delete the client product",
                "error"
              );
            }
          } catch (e) {
            console.log(`Deletion Failed ${JSON.stringify(e)}`);
            this.$swal(
              "Error!",
              "Failed to delete the client product",
              "error"
            );
          }
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          // User clicked "No" or closed the swal
          // Do nothing, the swal is dismissed
        }
      });
    },

    deleteDocument(id) {
      this.documentIdToDelete = id;

      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteDocument(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: id,
              },
            });

            if (
              response &&
              response.data &&
              response.data.deleteDocument.deleted
            ) {
              this.$swal(
                "Success!",
                "Document was successfully deleted",
                "success"
              );
              this.updateUserProduct();
            } else {
              this.$swal("Error!", "Failed to delete the document", "error");
            }
          } catch (e) {
            console.log(`Deletion Failed ${JSON.stringify(e)}`);
            this.$swal("Error!", "Failed to delete the document", "error");
          }
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          // User clicked "No" or closed the swal
          // Do nothing, the swal is dismissed
        }
      });
    },
    async openPublitoLink(document) {
      if (!document.publitoEmbeddUrl) {
        console.log("!document.publitoEmbeddUrl");
        this.$swal(
          "Warning!",
          "The file origionally uploaded was done incorrectly, please delete the document and try upload again",
          "warning"
        );
      }
      window.open(document.publitoEmbeddUrl, "_blank");
    },

    async openLink(document) {
      window.open(document.documentLink, "_blank");
    },
    async removeDocument(file) {
      this.newDocuments = this.newDocuments.filter((f) => {
        return f != file;
      });
      await this.fetchUserProducts();
    },
  },
};
</script>
<style>
.product-table {
  border-collapse: collapse;
  width: 100%;
}

.product-table th,
.product-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.product-table th {
  background-color: #f2f2f2;
}

.product-column {
  /* Add your custom styling for the product columns here */
  /* For example: */
  text-align: center;
}
</style>
