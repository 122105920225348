<template>
  <div>
    <!-- Download Button -->
    <v-btn @click="downloadData()">Download</v-btn>

    <!-- Modal -->
    <v-dialog v-model="isModalVisible" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Select Date Range</span>
        </v-card-title>
        <v-card-text>
          <!-- Date Range Picker -->
          <v-date-picker v-model="selectedDateRange" range scrollable>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedDateRange"
                label="Date Range"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
          </v-date-picker>
        </v-card-text>
        <v-card-actions>
          <!-- Apply Filter Button -->
          <v-btn color="primary" @click="applyFilter()">Apply Filter</v-btn>
          <!-- Cancel Button -->
          <v-btn color="error" @click="closeModal()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { gql } from "apollo-boost";
import Swal from "sweetalert2";

export default {
  name: "LeadsDownload",
  components: {},
  data: () => ({
    isLoading: false,
    users: [],
    page: 1,
    queryParameter: {},
    isModalVisible: false,
    selectedDateRange: null,
  }),

  apollo: {
    users: {
      query: gql`
        query ExportData($queryParameter: JSON!) {
          exportData(queryParameter: $queryParameter) {
            success
            message
          }
        }
      `,
      variables() {
        return {
          queryParameter: this.queryParameter,
        };
      },
      skip() {
        // Skip the initial query execution if selectedDateRange is null
        return this.selectedDateRange === null;
      },
      result({ data }) {
        if (data.exportData.success) {
          // Handle success
          console.log("Export successful");
          this.isLoading = false;
        } else {
          // Handle failure
          console.log("Export failed. Error message:", data.exportData.message);
          this.isLoading = false;
        }
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    async downloadData() {
      if (this.isLoading) {
        Swal.fire({
          title: "Download in Progress",
          text: "Sorry, there is already a download in progress. Please try again later.",
          icon: "error",
        });
        return;
      }

      this.isLoading = true;
      this.isModalVisible = true;
    },
    async applyFilter() {
      if (this.selectedDateRange) {
        const [start, end] = this.selectedDateRange;
        const startDate = new Date(start);
        const endDate = new Date(end);

        this.queryParameter = {
          role: "lead",
          createdAt: {
            $gte: startDate.getTime(), // Convert to Unix timestamp
            $lte: endDate.getTime(), // Convert to Unix timestamp
          },
        };

        this.isModalVisible = false;

        Swal.fire({
          title: "Download in Progress",
          html: "Your download is in progress and will be emailed as an attachment to you shortly.",
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
          timer: 3000,
          timerProgressBar: true,
        });

        // The query will be executed automatically by the apollo block
        // No need to call `this.$apollo.query` again here
      }
    },

    closeModal() {
      this.isModalVisible = false;
    },

    async refreshClientData() {},
  },
};
</script>
<style>
.swal-progress-container {
  width: 100%;
  margin-top: 10px;
}

.swal-progress-bar {
  height: 10px;
  background-color: #ffc107;
  width: 0;
}
</style>
