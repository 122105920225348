<template>
  <div>
    <base-loader :isLoading="isLoading"></base-loader>

    <v-card>
      <v-card-text>
        <v-form ref="documentForm" @submit.prevent="save()">
          <v-file-input
            v-model="documentModel.documentLink"
            accept="xlsx"
            color="primary"
            label="Click here to add your Excel File"
            show-size
            prepend-inner-icon="mdi-file-outline"
            counter
            :full-width="true"
            outlined
            ref="fileInput"
            :rules="[(v) => !!v || 'Document Link is required']"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip color="secondary" dark label small>
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <v-btn type="submit" color="success">Save</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Document } from "@/models/Document";
import { gql } from "apollo-boost";
import * as XLSX from "xlsx";

export default {
  name: "ProductBulkCreate",
  data() {
    return {
      isLoading: false,
      documentModel: new Document(),
      documentLinkAsDataURL: null,
      jsonData: null,
      me: "",
      search: null,
    };
  },
  created() {
    this.isLoading = false;
  },

  watch: {
    "documentModel.documentLink": function (newValue) {
      this.convertExcelToJson(newValue);
    },
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
  },

  methods: {
    convertExcelToJson(documentLink) {
      const file = documentLink;

      if (!file) {
        // No file selected
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // Get the header row to check if 'createdAt' exists
        const headerRow = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          range: 0,
        })[0];

        if (headerRow.includes("createdAt")) {
          // Keep the 'createdAt' field as a string to preserve the date
          const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            raw: false,
          });
          this.jsonData = JSON.stringify(jsonData);
        } else {
          // Convert everything to JSON as usual if 'createdAt' is not in the header
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          this.jsonData = JSON.stringify(jsonData);
        }

        this.getFileAsDataURL(file);
      };

      reader.readAsArrayBuffer(file);
    },

    async save() {
      this.isLoading = true;
      if (!this.$refs.documentForm.validate()) {
        this.$swal("Error", "Validation failed", "error");
        this.isLoading = false;
        return;
      }

      await this.createDocument();

      this.isLoading = false;
    },

    async createDocument() {
      const filename = `Bulk upload-${
        new Date().toISOString().split("T")[0]
      }.xlsx`;
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation createDocument($document: DocumentCreateInput!) {
              createDocument(document: $document) {
                id
              }
            }
          `,
          variables: {
            document: {
              name: filename,
              product: this.documentModel.product,
              docClass: "Excel file upload",
              category: "Bulk Users",
              documentLink: this.documentLinkAsDataURL,
              documentFileName: this.documentModel.documentFileName,
              createdBy: this.me.id,
              rawJson: this.jsonData,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the document!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createDocument) {
          this.documentModel.id = response.data.createDocument.id;
        }

        this.$swal("Success!", "Document was imported", "success").then(() => {
          this.$emit("update-data");
        });
      } catch (e) {
        console.log(e);
        this.$swal(
          "Error!",
          "Something went wrong when creating the document, check data and try again later!",
          "error"
        );
      }
    },

    async getFileAsDataURL(documentLink) {
      if (typeof documentLink === "string") {
        return documentLink;
      }

      if (
        documentLink instanceof File &&
        documentLink.name &&
        documentLink.size > 0
      ) {
        this.documentModel.documentFileName = documentLink.name || "";
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const data = reader.result;
            this.documentLinkAsDataURL = data;
            resolve(data);
          };
          reader.onerror = (e) => {
            console.error(e);
            reject(e);
          };
          reader.readAsDataURL(documentLink);
        });
      }

      return null;
    },
  },
};
</script>
