import { render, staticRenderFns } from "./TotalInsurersStatisticCard.vue?vue&type=template&id=ff783b7c&"
import script from "./TotalInsurersStatisticCard.vue?vue&type=script&lang=js&"
export * from "./TotalInsurersStatisticCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports