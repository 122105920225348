<template>
  <v-autocomplete
    v-model="chosenReferral"
    v-bind="$attrs"
    :items="formattedReferrals"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
import { gql } from "apollo-boost";
import _ from "lodash"; // Ensure lodash is imported for mapping

export default {
  name: "ReferralPicker",
  props: {
    initial: {
      type: [String, Array], // Allow both String and Array types
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenReferral: "",
    isLoading: false,
    formattedReferrals: [],
    allReferralNames: [], // To store all referral names
  }),
  apollo: {
    referrals: {
      query: gql`
        query Referrals($limit: Int!, $skip: Int!, $query: JSON!) {
          referrals(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedReferrals = data.referrals.records;

        // Store all referral names in a separate array
        this.allReferralNames = _.map(
          this.returnedReferrals,
          (referral) => referral.name
        );

        // Format the referrals, adding "All" and "No referral" at the top
        const formattedRecords = _.map(this.returnedReferrals, (referral) => {
          return { text: `${referral.name}`, value: referral.name };
        });

        // Prepend "All" and "No referral" options
        this.formattedReferrals = [
          { text: "All", value: "all" },
          { text: "No referral", value: "" }, // Empty string for "No referral"
          ...formattedRecords,
        ];
      },
    },
  },
  watch: {
    chosenReferral(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Handle case when "All" is selected
        if (newValue === "all") {
          // Set chosenReferral to the array of all referral names
          this.$emit("update:chosen-referral", this.allReferralNames);
        } else {
          this.$emit("update:chosen-referral", newValue ? newValue : "");
        }
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenReferral = this.initial)
      : (this.chosenReferral = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
