<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    // This renders the chart when the component is mounted and data is available
    if (this.chartData) {
      this.renderChart(this.chartData, this.options);
    }
  },
  watch: {
    chartData: {
      handler(newData) {
        if (newData) {
          // Re-render the chart when chartData changes
          this.renderChart(newData, this.options);
        }
      },
      deep: true, // Ensures deep watching of the object for nested changes
    },
  },
};
</script>
