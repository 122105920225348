<template>
  <div>
    <v-card flat class=" mb-5 pa-5 picture-card" v-if="pictureAsDataURL">
      <fieldset aria-hidden="true">
        <legend :style="{ width: measureText(label) - 22 + 'px' }">
          <span>​&ZeroWidthSpace;</span>
        </legend>
      </fieldset>
      <label
        class="v-label theme--light v-label--active"
        style="left: 0px; right: auto; position: absolute;"
        >{{ label }}</label
      >
      <v-img height="200" :src="pictureAsDataURL"
        ><v-btn
          @click="clearPicture"
          class="mt-6"
          fab
          dark
          small
          absolute
          error
          top
          right
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn></v-img
      >
    </v-card>
    <v-file-input
      v-if="!pictureAsDataURL"
      v-model="picture"
      accept="image/png, image/jpeg, image/bmp"
      color="primary"
      :label="label"
      show-size
      prepend-icon="mdi-image-outline"
      counter
      :full-width="true"
      outlined
    >
      <template v-slot:selection="{ index, text }">
        <v-chip color="secondary" dark label small>
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>
  </div>
</template>

<script>
export default {
  name: "PicturePicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "personal";
      },
    },
    label: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    isLoading: false,
    picture: null,
    pictureAsDataURL: null,
  }),
  watch: {
    initial(newValue, oldValue) {
      this.pictureAsDataURL = this.initial;
    },
    async picture(newValue, oldValue) {
      var pictureAsDataURL = await this.getFileAsDataURL(newValue);
      this.pictureAsDataURL = pictureAsDataURL;
    },
    pictureAsDataURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:picture-as-data-url",
          this.pictureAsDataURL ? this.pictureAsDataURL : null
        );
      }
    },
  },
  mounted() {},
  methods: {
    clearPicture() {
      this.pictureAsDataURL = null;
      this.picture = null;
    },
    measureText(str, fontSize = 16) {
      const widths = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0.2796875,
        0.2765625,
        0.3546875,
        0.5546875,
        0.5546875,
        0.8890625,
        0.665625,
        0.190625,
        0.3328125,
        0.3328125,
        0.3890625,
        0.5828125,
        0.2765625,
        0.3328125,
        0.2765625,
        0.3015625,
        0.5546875,
        0.5546875,
        0.5546875,
        0.5546875,
        0.5546875,
        0.5546875,
        0.5546875,
        0.5546875,
        0.5546875,
        0.5546875,
        0.2765625,
        0.2765625,
        0.584375,
        0.5828125,
        0.584375,
        0.5546875,
        1.0140625,
        0.665625,
        0.665625,
        0.721875,
        0.721875,
        0.665625,
        0.609375,
        0.7765625,
        0.721875,
        0.2765625,
        0.5,
        0.665625,
        0.5546875,
        0.8328125,
        0.721875,
        0.7765625,
        0.665625,
        0.7765625,
        0.721875,
        0.665625,
        0.609375,
        0.721875,
        0.665625,
        0.94375,
        0.665625,
        0.665625,
        0.609375,
        0.2765625,
        0.3546875,
        0.2765625,
        0.4765625,
        0.5546875,
        0.3328125,
        0.5546875,
        0.5546875,
        0.5,
        0.5546875,
        0.5546875,
        0.2765625,
        0.5546875,
        0.5546875,
        0.221875,
        0.240625,
        0.5,
        0.221875,
        0.8328125,
        0.5546875,
        0.5546875,
        0.5546875,
        0.5546875,
        0.3328125,
        0.5,
        0.2765625,
        0.5546875,
        0.5,
        0.721875,
        0.5,
        0.5,
        0.5,
        0.3546875,
        0.259375,
        0.353125,
        0.5890625,
      ];
      const avg = 0.5279276315789471;
      return (
        str
          .split("")
          .map((c) =>
            c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg
          )
          .reduce((cur, acc) => acc + cur) * fontSize
      );
    },
    async getFileAsDataURL(picture) {
      const self = this;
      return new Promise((resolve, reject) => {
        console.log(picture);
        if (typeof picture === "string") {
          console.log("Picture was a String");
          resolve(picture);
        }

        if (typeof picture === "object") {
          console.log(picture.name);
          const reader = new FileReader();
          reader.onload = async (event) => {
            const data = reader.result;
            resolve(data);
          };
          reader.onerror = (e) => {
            console.error(e);
            reject(e);
          };
          reader.readAsDataURL(picture);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.picture-card label {
  height: 20px;
  line-height: 20px;
  letter-spacing: normal;
  top: 18px;
  font-size: 16px;

  transform: translateY(-28px) scale(0.75) !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.picture-card legend {
  line-height: 11px;
  padding: 0;
  transition: width 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-application--is-ltr .picture-card legend {
  text-align: left;
}

.v-application--is-ltr .picture-card fieldset {
  padding-left: 8px;
}

.picture-card fieldset {
  border-collapse: collapse;
  border-color: rgba(0, 0, 0, 0.37);
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: 0.3s;
  transition-property: color, border-width;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

.picture-card fieldset {
  border-radius: inherit;
}
</style>
