<template>
  <div>
    <p>Lead Added (From Date)</p>
    <default-date-picker
      :initial="fullLead.dateAdded"
      :date.sync="fullLead.dateAdded"
    ></default-date-picker>

    <p>Lead Added (To Date)</p>
    <default-date-picker />
    <default-date-picker
      :initial="fullLead.dateTo"
      :date.sync="fullLead.dateTo"
    ></default-date-picker>
  </div>
</template>
<script>
import DefaultDatePicker from "../Base/DefaultDatePicker.vue";
import { gql } from "apollo-boost";

export default {
  name: "DefaultFilter",
  components: { DefaultDatePicker },
  data() {
    return {
      fullLead: {},
    };
  },
  // apollo: {
  //   enquiries: {
  //     query: gql`
  //     query Enquiries($limit: Int!, $skip: Int!, $query: JSON!) {
  //       enquiries(limit: $limit, skip: $skip, query: $query) {
  //         records {
  //           id

  //         }
  //     }
  //   `,
  //     variables() {
  //       var createdAt = {};
  //       if (this.fullLead?.dateAdded || this.fullLead?.dateTo) {
  //         createdAt = {
  //           createdAt: {
  //             $gt: this.fullLead.dateAdded //gt is greater than
  //               ? this.fullLead.dateAdded //dates in unittimestamp
  //               : 0, //min date is 0 if not give
  //             $lt: this.fullLead.dateTo //lt is less than
  //               ? this.fullLead.dateTo
  //               : new Date().getTime(), //max date is now if not give
  //           },
  //         };

  //         return {
  //           limit: 0,
  //           skip: 0,
  //           query: createdAt,
  //         };
  //       }
  //     },
  //   },
  // },
};
</script>
