export class Document {
  constructor() {
    this.id = "";
    this.name = "";
    this.user = null;
    this.category = "";
    this.docClass = "";
    this.userProduct = null;
    this.userFicaProduct = null;
    this.documentLink = null;
    this.createdBy = "";
  }
}
