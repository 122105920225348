<template>
  <base-material-card
    title="Product Categories by User"
    color="green"
    :elevation="8"
  >
    <base-bar
      style="height:45vh;"
      :chart-data="productCategories"
      :bind="true"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
      }"
    />
  </base-material-card>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "productCategories",
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          role
        }
      }
    `,
    products: {
      query: gql`
        query Products($limit: Int!, $skip: Int!, $query: JSON!) {
          products(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              category
            }
            count
          }
        }
      `,
      variables() {
        const limit = 100000;
        const skip = 0;
        var query = {};
        if (this?.me?.role === "user") {
          query = { user: this.me.id, status: "resolved" };
        } else if (this?.me?.role === "lead") {
          query = { assignee: this.me.id, status: "resolved" };
        } else {
          query = { status: "resolved" };
        }
        return {
          limit: limit,
          skip: skip,
          query: query,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(data, key) {
        var productCategoryRecords = data.data.products.records;
        var groupedByUser = _.groupBy(productCategoryRecords, "category");
        console.log(groupedByUser);
        this.productCategoriessGroupedByUser = groupedByUser;
      },
    },
  },
  data: () => ({
    selected: "",
    products: "",
    productCategoriesGroupedByUser: [],
  }),
  computed: {
    labels() {
      return _.map(this.productCategoriesGroupedByUser, (item, index) => {
        if (index !== "null") {
          return index;
        }
        return "Not Assigned";
      });
    },
    chartdata() {
      return _.map(this.productCategoriesGroupedByUser, (item, index) => {
        return item.length;
      });
    },
    productCategories() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "Count",
            borderColor: "#888",
            backgroundColor: this.getRandomColors(),
            borderWidth: 2,
            data: this.chartdata,
          },
        ],
      };
    },
  },
  async mounted() {},
  methods: {
    getRandomColors() {
      return _.map(this.serviceLocationCostAnalysis, (item) => {
        var letters = "0123456789ABCDEF".split("");
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      });
    },
  },
};
</script>

<style lang="sass"></style>
