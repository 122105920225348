<template>
  <div style="height: auto" class="new-ser-picker">
    <v-text-field
      v-model="message"
      placeholder="Search Users"
      append-icon="mdi-magnify"
      :loading="isLoading"
      :disabled="isLoading"
    >
      <template v-slot:append>
        <div v-if="newItems.length > 1 && typing === false">
          <v-btn x-small fab dark color="black" @click="resetSearch"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div>
          <v-btn x-small color="primary" @click="searchUsers">Search</v-btn>
        </div>
      </template>
    </v-text-field>

    <div
      class="typing"
      v-text="typing ? 'is typing...' : 'Hits ' + counter"
    ></div>
    <v-list dense v-if="newItems.length > 0 && typing === false">
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in newItems"
          :key="i"
          @click="navigateToUser(item.id)"
        >
          {{ item.firstName }} {{ item.lastName }} {{ item.email }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { GET_USERS } from "../../../../queries";
import { gql } from "apollo-boost";

export default {
  name: "UserPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
    role: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenUser: null,
    isLoading: false,
    returnedUsers: [],
    formattedUsers: [],

    message: "",
    typing: false,
    tempValue: "",
    newItems: [],
    counter: 0,
  }),
  methods: {
    searchUsers() {
      this.tempValue = this.message;
      this.typing = false;
      this.$apollo.queries.users.refetch();
    },
    navigateToUser(userId) {
      if (this.me.role === "admin") {
        this.$router.push("/admin/user/" + userId);
      } else if (this.me.role === "consultant") {
        this.$router.push("/consultant/user/" + userId);
      }
      this.resetSearch();
    },
    resetSearch() {
      this.message = ""; // Clear the search input
      this.typing = false;
      this.tempValue = "";
      this.newItems = [];
      this.counter = 0;
    },
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
          }
        }
      `,
    },
    users: {
      query: GET_USERS,
      skip() {
        return this.tempValue.length === 0; // Skip the query if the search value is empty
      },
      variables() {
        const searchQuery = {
          $or: [
            { firstName: { $regex: this.tempValue, $options: "i" } },
            { lastName: { $regex: this.tempValue, $options: "i" } },
            { email: { $regex: this.tempValue, $options: "i" } },
          ],
        };
        return {
          page: 1,
          limit: 20000,
          query: searchQuery,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }) {
        this.counter = 0;
        if (this.tempValue.length > 0) {
          this.newItems = data.users.records;
          this.counter = this.newItems.length;
        } else {
          this.newItems = [];
        }
      },
    },
  },
  watch: {
    chosenUser(newValue) {
      if (this.me.role === "admin") {
        this.$router.push("/admin/user/" + newValue);
      } else if (this.me.role === "consultant") {
        this.$router.push("/consultant/user/" + newValue);
      }
    },
  },
};
</script>

<style lang="scss">
.new-ser-picker {
  padding: 12px 0px;
  margin-top: 0 !important;
  position: relative;
  overflow: visible;
  z-index: 100;
}

.new-ser-picker .typing {
  font-size: 12px;
  font-weight: bold;
}

.new-ser-picker .v-text-field__details {
  display: none;
}

.new-ser-picker .v-input {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 1px;
}

.new-ser-picker .v-list {
  max-height: 300px;
  overflow: auto;
  background-color: #eeeeee;
  z-index: 150;
  border-radius: 0px 0px 3px 3px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
}

.new-ser-picker .v-list .v-list-item--link:hover {
  color: black;
  background-color: #feb809;
}
</style>
