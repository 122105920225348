import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./plugins/base";
import VueSweetalert2 from "vue-sweetalert2";
import Vue2Filters from "vue2-filters";
import Moment from "moment";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import { VueMasonryPlugin } from "vue-masonry";
import JsonExcel from "vue-json-excel";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from "apollo-boost";
import VueApollo from "vue-apollo";

Vue.config.productionTip = false;

const config = Object.freeze({
  apiUrl: process.env.VUE_APP_API_URL,
});

window._ = require("lodash");

const plugin = {
  install(Vue, options) {
    Vue.prototype.$filters = Vue.options.filters;
    Vue.prototype.$eventHub = new Vue();
    Vue.prototype.$config = config;
    Vue.prototype.$moment = Moment;
  },
};

Vue.use(VueApollo);
Vue.use(VueSweetalert2);
Vue.use(Vue2Filters);
Vue.use(plugin);
Vue.use(Chartkick.use(Chart));
Vue.use(VueMasonryPlugin);
Vue.component("downloadExcel", JsonExcel);

const link = new HttpLink({ uri: config.apiUrl });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem("token");
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  // Call the next link in the middleware chain.
  return forward(operation);
});

const cache = new InMemoryCache();
const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(link),
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.filter("getFormattedDate", (value) => {
  const ret = new Moment(value).format("YYYY-MM-DD HH:mm:ss");
  return ret;
});

Vue.filter("getFormattedDateNoSeconds", (value) => {
  const ret = new Moment(value).format("YYYY-MM-DD HH:mm");
  return ret;
});

Vue.filter("getFormattedYYYYMMDD", (value) => {
  const ret = new Moment(value).format("YYYY-MM-DD");
  return ret;
});

Vue.filter("getFormattedYYYYMM", (value) => {
  const ret = new Moment(value).format("YYYY-MM");
  return ret;
});

Vue.filter("kb", (val) => {
  return Math.floor(val / 1024);
});

// import styles
import "sweetalert2/dist/sweetalert2.min.css";

new Vue({
  router,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
  // created() {
  //   fetch(process.env.VUE_APP_API_URL, {
  //     mode: "no-cors",
  //   })
  //     .then((response) => {
  //       // Process the response
  //     })
  //     .catch((error) => {
  //       // Handle the error
  //     });
  // },
}).$mount("#app");
