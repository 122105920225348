<template>
  <v-card class="my-0">
    <v-card-text>
      <v-form ref="UserDeleteForm" v-model="formValid">
        <base-loader :is-loading="isLoading" />
        <v-alert v-if="successMessage" type="success">
          {{ successMessage }}
        </v-alert>
        <v-alert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </v-alert>
        <v-row class="mx-1">
          <v-col cols="12" md="5">
            <!-- Delete Filter Dates -->
            <v-layout row wrap>
              <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Select Date"
                    readonly
                    :value="fromDateDisp"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="en-in"
                  :min="minDate"
                  :max="maxDate"
                  v-model="fromDateVal"
                  no-title
                  @input="fromDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-layout>
            <!-- Delete Action Buttons -->
            <v-btn
              :disabled="!hasDate"
              color="secondary"
              class="ma-1"
              @click="stageForUploading()"
            >
              Delete Filtered Records
            </v-btn>
            <v-checkbox
              label="Delete All Records"
              @click="activateDelete()"
            ></v-checkbox>
            <v-btn
              fab
              medium
              dark
              class="mr-2"
              :disabled="!checked"
              @click="deleteItem(item)"
              color="error"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <!-- Delete Progress bar -->
            <h1 class="mt-4">Delete progress</h1>
            <v-progress-linear
              :value="uploadProgress"
              color="secondary"
              height="25"
              reactive
              class="my-5"
            >
              <strong style="color: #fff"
                >{{ Math.ceil(deleteProgress) }}% ({{ completedRecords }} /
                {{ totalRecords }})</strong
              >
            </v-progress-linear>
            <!-- Refresh Page Buttons -->
            <v-btn class="ma-1" color="secondary" @click="refreshClientData()">
              <h1>Close & Refresh</h1>
            </v-btn>
          </v-col>
          <v-col cols="12" md="7">
            <v-textarea
              v-model="stagedTextArea"
              color="secondary"
              rows="20"
              label="Filter Records"
              filled
              no-resize
              outlined
              :readonly="true"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { gql } from "apollo-boost";
var Papa = require("papaparse");
const Joi = require("joi");
export default {
  name: "UserBulkDelete",
  data: () => ({
    isLoading: false,
    formValid: true,
    stagedTextArea: "",
    deleteProgress: 0,
    totalRecords: 0,
    completedRecords: 0,
    successMessage: "",
    errorMessage: "",
    fromDateMenu: false,
    fromDateVal: null,
    minDate: "2019-07-04",
    maxDate: "2022-08-30",
    hasDate: true,
    checked: false,
  }),
  computed: {
    fromDateDisp() {
      console.log("Date has changed");
      this.hasDate = !this.hasDate;
      alert(this.fromDateVal);
      return this.fromDateVal;
    },
  },
  watch: {},
  async mounted() {},
  methods: {
    activateDelete() {
      console.log("Triggered delete All");
      return (this.checked = !this.checked);
    },
    async refreshClientData() {},
  },
};
</script>

<style lang="sass"></style>
