<template>
  <v-card>
    <v-card-title>Company Metrics</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Leads Allocated </v-card-title>
            </v-card-item>

            <v-card-text>
              {{ companyStats.leadsAllocated }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Contacted Leads</v-card-title>
            </v-card-item>

            <v-card-text>
              {{ companyStats.contactedLeads }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Quotes done</v-card-title>
            </v-card-item>

            <v-card-text>
              {{ companyStats.quotesDone }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Sales Done</v-card-title>
            </v-card-item>

            <v-card-text>
              {{ companyStats.salesDone }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mx-auto my-8" elevation="16" max-width="344">
            <v-card-item>
              <v-card-title> Conversion Ratio</v-card-title>
            </v-card-item>

            <v-card-text>
              {{ companyStats.conversionRatio }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      companyStats: {
        leadsAllocated: 2000,
        contactedLeads: 1500,
        quotesDone: 900,
        salesDone: 600,
        conversionRatio: ((600 / 2000) * 100).toFixed(2),
      },
    };
  },
};
</script>
