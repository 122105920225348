<template>
  <v-card
    class="v-card--material pa-1"
  >
    <div>
      <v-sheet
        :class="'pa-4'"
        :color="color"
        :width="'100%'"
        class="text-start v-card--material__heading mb-n6"
        dark
        :elevation="8"
      >
        <div
          class="display-2 text-center"
          v-text="title"
        />
        <!-- <base-user-tracking v-if="title === 'Users'"/> -->
      </v-sheet>
      <div
        v-if="!isLoading"
        class="text-center pa-2"
      >
        <h3 class="display-3 text-center">
          <strong> {{ value }}</strong>
        </h3>
      </div>
      <div
        v-else
        class="text-center pa-2"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
      <v-col
        cols="12"
        class="px-0"
      >
        <v-divider />
      </v-col>
      <v-icon
        color="primary"
        size="16"
        class="ml-2 mr-1"
      >
        {{ subIcon }}
      </v-icon>
      <span
        :color="subTextColor"
        class="caption grey--text font-weight-light"
        v-text="subText"
      />
    </div>
  </v-card>
</template>

<script>
  import Card from './Card'

  export default {
    name: 'StatsCard',

    inheritAttrs: false,

    props: {
      ...Card.props,
      subIcon: {
        type: String,
        default: undefined,
      },
      subTextColor: {
        type: String,
        default: undefined,
      },
      subText: {
        type: String,
        default: undefined,
      },
      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      isLoading: {
        type: [Boolean, Number],
        default: undefined,
      },
      color: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
</style>
