<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-spacer></v-spacer>
        <div class="mx-auto text-right">
          <v-btn
            class="mx-auto"
            color="primary"
            v-bind="attrs"
            @click="showNewUserProductDialog"
            >Add Product to Client</v-btn
          >
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Product to Client</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="userProductModel.productStatus"
                  label="product status"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <base-product-picker
                  :chosen-product.sync="userProductModel.product"
                  :initial="userProductModel.product"
                  label="Product"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Product is required']"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="userProductModel.policyNumber"
                  color="primary"
                  label="Policy Number"
                  outlined
                  clearable
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="userProductModel.lateJoinerPenalty"
                  color="primary"
                  label="Late Joiner Penalty"
                  outlined
                  clearable
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="userProductModel.schemeJoin"
                  color="primary"
                  label="Scheme Joined"
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="userProductModel.totalContrib"
                  color="primary"
                  label="Total Contributions"
                  outlined
                  number
                  prefix="R"
                  clearable
                />
              </v-col>
              <v-col cols="6">
                <v-dialog v-model="inceptionDateDialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="userProductModel.inceptionDate"
                      color="primary"
                      label="Inception Date"
                      outlined
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="userProductModel.inceptionDate"
                    color="primary"
                    no-title
                    :landscape="false"
                    @input="inceptionDateDialog = false"
                  ></v-date-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="inceptionDateDialog = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="userProductModel.beneficiaryType"
                  color="primary"
                  label="Beneficiary Type"
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="userProductModel.dependantCode"
                  color="primary"
                  label="Dependent Code"
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="6">
                <v-dialog v-model="dateResignedDateDialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="userProductModel.dateResigned"
                      color="primary"
                      label="Date resigned"
                      outlined
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="userProductModel.dateResigned"
                    color="primary"
                    no-title
                    :landscape="false"
                    @input="dateResignedDateDialog = false"
                  ></v-date-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dateResignedDateDialog = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <v-dialog v-model="benefitDateDialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="userProductModel.benefitDate"
                      color="primary"
                      label="Benefit Date"
                      outlined
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="userProductModel.benefitDate"
                    color="primary"
                    no-title
                    :landscape="false"
                    @input="benefitDateDialog = false"
                  ></v-date-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="benefitDateDialog = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-dialog v-model="cancelDateDialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="userProductModel.cancelDate"
                      color="primary"
                      label="Cancel Date"
                      outlined
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="userProductModel.cancelDate"
                    color="primary"
                    no-title
                    :landscape="false"
                    @input="cancelDateDialog = false"
                  ></v-date-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="cancelDateDialog = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-dialog>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="12">
                <base-file-upload
                  :user-product="this.userProductModel"
                  @document-uploaded="handleDocumentUploaded"
                />
              </v-col>
            </v-row> -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialog = false"> Cancel </v-btn>
          <v-btn color="secondary" @click="createUserProduct"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { gql } from "apollo-boost";
import { UserProduct } from "@/models/UserProduct";

export default {
  components: {},
  name: "AddProductToClient",
  props: {
    userProduct: {
      type: Object,
      default: function () {
        return {};
      },
    },
    userId: {
      type: String,
      default: function () {
        return "";
      },
    },
    me: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      dialog: false,
      userProductModel: {},
      cancelDateDialog: false,
      inceptionDateDialog: false,
      dateResignedDateDialog: false,
      benefitDateDialog: false,
      documents: [],
      newDocuments: [],
    };
  },
  created() {
    this.userProductModel.user = this.userId;
    // Initialize userProductModel with the value of userProduct prop
    this.userProductModel = { ...this.userProduct };
  },
  methods: {
    handleDocumentUploaded(document) {
      console.log("Document uploaded:", document);
      this.newDocuments.push(document);
    },

    showNewUserProductDialog() {
      this.isEdit = false;
      this.newDocuments = [];
      this.userProductModel = new UserProduct();
      this.userProductModel.user = this.userId;
      this.dialog = true;
    },
    async createUserProduct() {
      try {
        const documents = this.newDocuments.map((document) => ({
          id: document.id,
          name: document.name,
          documentLink: document.documentLink,
        }));
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation createUserProduct($userProduct: UserProductCreateInput!) {
              createUserProduct(userProduct: $userProduct) {
                id
              }
            }
          `,
          variables: {
            userProduct: {
              user: this.userProductModel.user,
              product: this.userProductModel.product,
              policyNumber: this.userProductModel.policyNumber,
              createdBy: this.me.id,
              membershipNumber: this.userProductModel.membershipNumber,
              schemeJoin: this.userProductModel.schemeJoin,
              benefitDate: this.userProductModel.benefitDate,
              dateResigned: this.userProductModel.dateResigned,
              dependantCode: this.userProductModel.dependantCode,
              beneficiaryType: this.userProductModel.beneficiaryType,
              lateJoinerPenalty: this.userProductModel.lateJoinerPenalty,
              totalContrib: this.userProductModel.totalContrib,
              inceptionDate: this.userProductModel.inceptionDate,
              productStatus: this.userProductModel.productStatus,
              startDate: this.userProductModel.startDate,
              cancelDate: this.userProductModel.cancelDate,
              documents: documents,
            },
          },
        });

        if (!response) {
          throw new Error("Something went wrong when creating the Product!");
        }

        if (response.errors && response.errors.length > 0) {
          throw new Error(response.errors[0]);
        }

        if (response && response.data.createUserProduct) {
          this.userProductModel.id = response.data.createUserProduct.id;
          this.$swal(
            "Success!",
            "Client Product was successfully created",
            "success"
          ).then(() => {
            this.$emit("update-user-product");
            this.dialog = false;
          });
        }
      } catch (e) {
        this.$swal(
          "Error!",
          "Something went wrong when adding the Product to the Client, check data and try again later!",
          "error"
        );
        console.log(e.message);
      }
    },
  },
};
</script>
