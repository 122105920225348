<template>
  <base-stats-card v-if="users.paginator"
    :color="'#0a2752'"
    title="No. of Consultants"
    :value="users.paginator.userCount"
    :elevation="8"
    sub-icon="mdi-clock"
    :sub-text="subText"
    :is-loading="isLoading"
  />
</template>

<script>
  import Moment from 'moment'
  import { gql } from 'apollo-boost'
  import StatsCard from '../StatsCard.vue'
  import Card from '../Card.vue'
  export default {
    name: 'TotalConsultantsStatisticsCard',
     components: {
    StatsCard,
    Card
  },
    props: {
      initial: {
        type: String,
        default: function () {
          return ''
        },
      },
    },
    data: () => ({
      isLoading: false,
      subText: '',
      users: '',
    }),
    apollo: {
      users: {
        query: gql`query Users($limit: Int $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              email
              role
            }
            paginator {
              userCount
            }
          }
        }`,
        variables () {
          return {
            limit: 10000,
            query: { role: 'consultant' },
          }
        },
        watchLoading (isLoading, countModifier) {
          this.isLoading = isLoading
        },
        result (data, key) {
          this.subText = 'Updated At ' + new Moment().format('HH:mm:ss')
        },
      },
    },
    async created () {
    },
    methods: {
    },
  }
</script>

<style lang="sass">
</style>