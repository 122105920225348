<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row>
      <v-col cols="8">
        <v-text-field
          :label="label"
          :value="date ? `${date}` : ''"
          readonly
          outlined
          :clearable="true"
          @click="dateDialog = true"
          prepend-inner-icon="mdi-calendar"
          @click:clear="clearDateQuery"
          :error="!date && typeof time === 'number' && time >= 0"
      /></v-col>
      <v-col cols="4">
        <v-autocomplete
          label="Time"
          v-model="time"
          clearable
          :items="formattedTimes"
          prepend-inner-icon="mdi-clock"
          outlined
          :error="date && !(typeof time === 'number' && time >= 0)"
          @click:clear="clearTimeQuery"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="dateDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ label }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="text-center">
              <v-col cols="12">
                <v-date-picker v-model="date" show-current />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="
              date = '';
              dateDialog = false;
            "
          >
            Clear
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="!date"
            @click="dateDialog = false"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Moment from "moment";
export default {
  name: "TimePicker",
  props: {
    label: {
      type: String,
      default: function () {
        return {};
      },
    },
    initial: {
      type: Number,
      default: function () {
        return null;
      },
    },
  },
  data: () => ({
    dateDialog: false,
    date: null,
    time: null,
    formattedTimes: [
      { text: "00:00", value: 0 },
      { text: "00:30", value: 30 },
      { text: "01:00", value: 60 },
      { text: "01:30", value: 90 },
      { text: "02:00", value: 120 },
      { text: "02:30", value: 150 },
      { text: "03:00", value: 180 },
      { text: "03:30", value: 210 },
      { text: "04:00", value: 240 },
      { text: "04:30", value: 270 },
      { text: "05:00", value: 300 },
      { text: "05:30", value: 330 },
      { text: "06:00", value: 360 },
      { text: "06:30", value: 390 },
      { text: "07:00", value: 420 },
      { text: "07:30", value: 450 },
      { text: "08:00", value: 480 },
      { text: "08:30", value: 510 },
      { text: "09:00", value: 540 },
      { text: "09:30", value: 570 },
      { text: "10:00", value: 600 },
      { text: "10:30", value: 630 },
      { text: "11:00", value: 660 },
      { text: "11:30", value: 690 },
      { text: "12:00", value: 720 },
      { text: "12:30", value: 750 },
      { text: "13:00", value: 780 },
      { text: "13:30", value: 810 },
      { text: "14:00", value: 840 },
      { text: "14:30", value: 870 },
      { text: "15:00", value: 900 },
      { text: "15:30", value: 930 },
      { text: "16:00", value: 960 },
      { text: "16:30", value: 990 },
      { text: "17:00", value: 1020 },
      { text: "17:30", value: 1050 },
      { text: "18:00", value: 1080 },
      { text: "18:30", value: 1110 },
      { text: "19:00", value: 1140 },
      { text: "19:30", value: 1170 },
      { text: "20:00", value: 1200 },
      { text: "20:30", value: 1230 },
      { text: "21:00", value: 1260 },
      { text: "21:30", value: 1290 },
      { text: "22:00", value: 1320 },
      { text: "22:30", value: 1350 },
      { text: "23:00", value: 1380 },
      { text: "23:30", value: 1410 },
    ],
  }),
  computed: {
    epochDateTime() {
      if (this.date && typeof this.time === "number" && this.time >= 0) {
        return Moment(this.date).add(this.time, "minutes").valueOf();
      }
      return null;
    },
    getError() {
      if (this.date && typeof this.time === "number" && this.time >= 0) {
        return null;
      } else if (
        this.date &&
        !(typeof this.time === "number" && this.time >= 0)
      ) {
        return "Please select a time!";
      } else if (
        !this.date &&
        typeof this.time === "number" &&
        this.time >= 0
      ) {
        return "Please select a date!";
      }
      return "";
    },
  },
  watch: {
    date(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitUpdates();
      }
    },
    time(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitUpdates();
      }
    },
    initial(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        var date = new Moment(this.initial).format("YYYY-MM-DD");
        this.date = date;
        var time = new Moment(this.initial).format("HH:mm");
        var timeValue = _.find(this.formattedTimes, ["text", time]);
        this.time = timeValue.value;
      } else {
        this.date = null;
        this.time = null;
      }
    },
  },
  async created() {
    if (this.initial !== null) {
      // Check if initial prop is provided
      const initialMoment = new Moment(this.initial);

      if (initialMoment.isValid()) {
        this.date = initialMoment.format("YYYY-MM-DD");
        const timeValue = _.find(this.formattedTimes, [
          "text",
          initialMoment.format("HH:mm"),
        ]);

        if (timeValue) {
          this.time = timeValue.value;
        }
      }
    }
  },

  methods: {
    clearDateQuery() {
      this.date = null;
    },
    clearTimeQuery() {
      this.time = null;
    },
    emitUpdates() {
      this.$emit("update:date", this.date ? this.date : null);
      this.$emit(
        "update:time",
        this.date && typeof this.time === "number" && this.time >= 0
          ? this.time
          : null
      );
      this.$emit(
        "update:epoch-date-time",
        this.date && typeof this.time === "number" && this.time >= 0
          ? Moment(this.date).add(this.time, "minutes").valueOf()
          : null
      );
    },
  },
};
</script>

<style lang="sass"></style>
