<template>
  <v-autocomplete
    v-model="chosenUser"
    v-bind="$attrs"
    :items="formattedUsers"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "KanbanPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
    role: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenUser: null,
    totalRecords: 0,
    isLoading: false,
    returnedUsers: [],
    formattedUsers: [],
    me: "",
  }),
  apollo: {
    me: gql`
      query {
        me {
          id
          branch
          email
          firstName
          lastName
          role
          branch
          branchName
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int $query: JSON!) {
          users(limit: $limit query: $query) {
            records {
              id
              firstName
              lastName
            }
          }
        }
      `,
      variables() {
        var query = {};
        if(this.me.role === 'consultant'){
          return {
            limit: 9999,
            query: { consultant: this.me.id },
          };
        }
        return {
          limit: 9999,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedUsers = data.users.records;
        this.formattedUsers = _.map(this.returnedUsers, (user) => {
          return { text: `${user.firstName} ${user.lastName}`, value: user.id };
        });
      },
    },
  },
  watch: {
    chosenUser(newValue) { 
        this.$emit(
          "update:chosen-user",
          this.chosenUser ? this.chosenUser : null
        );
    },
  },
  async mounted() {},
};
</script>

<style lang="sass"></style>
