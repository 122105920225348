<template>
  <v-card>
    <v-card-title>
      Sales Tracking - Last 3 Months
      <v-spacer></v-spacer>
      <v-select
        v-model="selectedCategory"
        :items="categories"
        label="Product Category"
        @change="filterGraph"
      />
    </v-card-title>
    <v-card-text>
      <!-- Ensure chartData is passed and initialized -->
      <line-chart
        v-if="chartData"
        :chart-data="chartData"
        :options="chartOptions"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import LineChart from "./LineChart.vue";

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      selectedCategory: "Overall",
      categories: ["Overall", "Short-term", "Health", "Gap", "Life", "Invest"],
      chartData: null, // Start with null but update in mounted hook
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };
  },
  mounted() {
    // Ensure chartData is initialized with default data
    this.updateChartData();
  },
  methods: {
    filterGraph() {
      this.updateChartData();
    },
    updateChartData() {
      const overallData = [
        { label: "Insurer A", data: [100, 150, 200], borderColor: "#FF5733" },
        { label: "Insurer B", data: [80, 120, 160], borderColor: "#33FF57" },
      ];

      // Initialize with the overall data, or change based on selectedCategory
      this.chartData = {
        labels: ["Month 1", "Month 2", "Month 3"],
        datasets: overallData, // Dummy data, replace with API call if needed
      };
    },
  },
};
</script>
