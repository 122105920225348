<template>
  <v-autocomplete
    v-model="chosenProduct"
    v-bind="$attrs"
    :items="formattedProducts"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "ProductPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenProduct: "",
    totalRecords: 0,
    isLoading: false,
    returnedProducts: [],
    formattedProducts: [],
  }),
  apollo: {
    products: {
      query: gql`
        query Products($limit: Int!, $skip: Int!, $query: JSON!) {
          products(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              insurerName
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: this.totalRecords > 0 ? this.totalRecords : 250, // Initially set to 250, but will be updated
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.totalRecords = data.products.count; // Update totalRecords with the count value
        this.returnedProducts = data.products.records;
        this.formattedProducts = this.returnedProducts.map((product) => {
          return {
            text: `${product.name} - ${product.insurerName}`,
            value: product.id,
          };
        });

        // Re-fetch products with the updated limit
        if (this.returnedProducts.length < this.totalRecords) {
          this.$apollo.queries.products.refetch({
            limit: this.totalRecords,
            skip: 0,
            query: {},
          });
        }
      },
    },
  },
  watch: {
    chosenProduct(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-product",
          this.chosenProduct ? this.chosenProduct : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenProduct = this.initial)
      : (this.chosenProduct = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
