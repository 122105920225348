<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              {{ `${isEdit ? "Edit" : "New"} Document` }}
            </h4>
            <v-form ref="documentForm" @submit.prevent="save()">
              <v-text-field
                v-model="documentModel.name"
                label="Name"
                required
                outlined
                :rules="[(v) => !!v || 'Document Name is required']"
              ></v-text-field>
              <base-user-picker
                :chosen-user.sync="documentModel.user"
                :initial="me.id"
                label="User"
                :rules="[(v) => !!v || 'User is required']"
                required
                outlined
              />
              <v-file-input
                v-if="!documentModel.documentLink"
                v-model="documentModel.documentLink"
                accept="application/pdf"
                color="primary"
                label="Click here to add your PDF Document"
                show-size
                prepend-icon="mdi-file-outline"
                counter
                :full-width="true"
                outlined
                :rules="[(v) => !!v || 'Document Link is required']"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip color="secondary" dark label small>
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-text-field
                v-model="documentModel.documentFileName"
                label="Document File Name"
                required
                outlined
                readonly
                :rules="[(v) => !!v || 'Document File Name is required']"
              ></v-text-field>
              <!-- <v-textarea
                v-model="documentLinkAsDataURL"
                label="Base64Data"
                outlined
              ></v-textarea> -->
              <base-doc-class-picker
                :chosen-doc-class.sync="documentModel.docClass"
                :initial="documentModel.docClass"
                label="Document Class"
                :rules="[(v) => !!v || 'Document Class is required']"
                required
                outlined
              />
              <base-document-type-picker
                :chosen-doc-type.sync="documentModel.docClass"
                :initial="documentModel.docClass"
                label="Document Class"
                :rules="[(v) => !!v || 'Document Class is required']"
                required
                outlined
              />
              <base-document-category-picker
                :chosen-document-category.sync="documentModel.category"
                :initial="documentModel.category"
                :doc-class="documentModel.docClass"
                label="Document Category"
                required
                outlined
                :rules="[(v) => !!v || 'Document Category is required']"
              />
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Document } from "@/models/Document";
import { gql } from "apollo-boost";
export default {
  name: "FicaCreate",
  data() {
    return {
      isEdit: false,
      isLoading: false,
      documentModel: new Document(),
      documentLinkAsDataURL: null,
      me: "",
      search: null,
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {},
  watch: {
    "documentModel.documentLink": async function(newValue, oldValue) {
      var documentLinkAsDataURL = await this.getFileAsDataURL(newValue);
      this.documentLinkAsDataURL = documentLinkAsDataURL;
    },
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    document: {
      query: gql`
        query Document($id: ID!) {
          document(id: $id) {
            id
            name
            user
            docClass
            category
            documentLink
            documentFileName
            createdBy
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.documentModel.id = this.document.id;
        this.documentModel.name = this.document.name;
        this.documentModel.user = this.document.user;
        this.documentModel.docClass = this.document.docClass;
        this.documentModel.category = this.document.category;
        this.documentModel.documentLink = this.document.documentLink;
        this.documentModel.documentFileName = this.document.documentFileName;
        this.documentModel.createdBy = this.document.createdBy;
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.documentForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        // update
        await this.updateDocument()
          .then(() => {
            this.$swal("Success!", "Document was updated", "success");
          })
          .catch((e) => {
            console.log(e);
            this.$swal(
              "Error!",
              "something went wrong when trying to update the document, check data and try again later!",
              "error"
            );
          });
      } else {
        // create
        await this.createDocument()
          .then(() => {
            this.$swal("Success!", "Document was created", "success");
            window.location.href = `/admin/document/${self.documentModel.id}/edit`;
          })
          .catch((e) => {
            console.log(e);
            this.$swal(
              "Error!",
              `Something went wrong when creating the document, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateDocument() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($document: DocumentUpdateInput!) {
              updateDocument(document: $document) {
                id
                updated
              }
            }
          `,
          variables: {
            document: {
              id: self.documentModel.id,
              name: self.documentModel.name,
              user: self.documentModel.user,
              docClass: self.documentModel.docClass,
              category: self.documentModel.category,
              documentLink: self.documentLinkAsDataURL,
              documentFileName: self.documentModel.documentFileName,
              createdBy: self.me.id,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the document!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createDocument() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createDocument($document: DocumentCreateInput!) {
              createDocument(document: $document) {
                id
              }
            }
          `,
          variables: {
            document: {
              name: self.documentModel.name,
              user: self.documentModel.user,
              docClass: self.documentModel.docClass,
              category: self.documentModel.category,
              documentLink: self.documentLinkAsDataURL,
              documentFileName: self.documentModel.documentFileName,
              createdBy: self.me.id,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the document!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createDocument) {
          self.documentModel.id = response?.data?.createDocument?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
    async getFileAsDataURL(documentLink) {
      const self = this;
      return new Promise((resolve, reject) => {
        console.log(documentLink);
        if (typeof documentLink === "string") {
          console.log("Document was a String");
          resolve(documentLink);
        }

        if (
          //          documentLink instanceof File &&
          typeof documentLink === "object"
          // documentLink.name &&
          // documentLink.size > 0
        ) {
          console.log(documentLink.name);
          self.documentModel.documentFileName = documentLink.name
            ? documentLink.name
            : "";
          console.log("Document was a File");
          const reader = new FileReader();
          reader.onload = async (event) => {
            const data = reader.result;
            resolve(data);
          };
          reader.onerror = (e) => {
            console.error(e);
            reject(e);
          };
          reader.readAsDataURL(documentLink);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
