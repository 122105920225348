export class Company {
  constructor() {
    this.id = "";
    this.name = "";
    this.createdBy = "";
    this.status = false;
    this.companyContactPerson = "";
    this.companyContactPersonEmail = "";
    this.companyContactPersonNumber = "";
    this.notes = "";
    this.updatedBy = "";
    this.fullGoogleAddress = "";
    this.companyReg = "";
  }
}
