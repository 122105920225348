<template>
  <v-dialog v-model="dialog" persistent scrollable light max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="buttonText" class="mx-2" v-bind="$attrs" v-on="on" elevation="3"  fab x-small>
        {{ buttonText }}
      </v-btn>
      <v-btn
        v-else
        class="mx-2"
        fab
        x-small
        v-bind="$attrs"
        color="success"
        :elevation="3"
        v-on="on"
      >
        <v-icon>
          mdi-email
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h2 class="heading my-2">
          Contact {{ user.firstName }} {{ user.lastName }}
        </h2>
        <v-spacer /><v-spacer />
        <v-btn
          color="primary"
          fab
          dark
          class="text-right"
          @click="dialog = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="UserContactForm" v-model="formValid">
          <base-loading :is-loading="isLoading" />
          <v-alert v-if="successMessage" type="success">
            {{ successMessage }}
          </v-alert>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
          <h3 class="mb-4">
            Would you like to send email correspondence?
          </h3>
          <v-text-field
            v-model="to"
            color="warning"
            :label="`To ${user.firstName} ${user.lastName}`"
            outlined
            required
            :readonly="true"
            :rules="[
              (v) => !!v || 'To Email Address is required',
              (v) =>
                /.+@.+\..+/.test(v) || 'Email Address format must be valid',
            ]"
          />
          <v-text-field
            v-model="from"
            color="warning"
            :label="`From ${me.firstName} ${me.lastName}`"
            outlined
            required
            :readonly="true"
            :rules="[
              (v) => !!v || 'From Email Address is required',
              (v) =>
                /.+@.+\..+/.test(v) || 'Email Address format must be valid',
            ]"
          />
          <v-textarea
            v-model="message"
            color="primary"
            label="Message"
            outlined
            counter
            required
            :rules="[(v) => !!v || 'Message is required']"
          />
        </v-form>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />

        <v-btn
          v-if="user.branch === '60f67355297f461ee095764b'"
          :disabled="!formValid"
          color="#feb809"
          @click="MedsafuContactUser()"
        >
          Send Message using Medsafu Email
        </v-btn>

        <v-btn
          v-else-if="user.branch === '60f6736e297f461ee095764c'"
          :disabled="!formValid"
          color="#feb809"
          @click="MedsafuContactUser()"
        >
          Send Message using Medsafu Email
        </v-btn>
        <v-btn
          v-else-if="user.branch === ''"
          :disabled="!formValid"
          color="#feb809"
          @click="MedsafuContactUser()"
        >
          Send Message using Medsafu
        </v-btn>

        <v-btn
          v-else
          :disabled="!formValid"
          color="'#0a2752'"
          @click="OnecallContactUser()"
        >
          Send Message using 1 Call
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "UserContact",
  props: {
    buttonText: {
      type: String,
      default: function() {
        return "";
      },
    },
    user: {
      type: Object,
      default: function() {
        return {};
      },
    },
    me: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data: () => ({
    dialog: false,
    formValid: true,
    isLoading: false,
    to: "",
    from: "",
    message: "",
    successMessage: "",
    errorMessage: "",
  }),
  computed: {},
  async created() {
    if (this.user.email) {
      this.to = this.user.email;
    }
    if (this.user.email) {
      this.from = this.me.email;
    }
  },
  methods: {
    async OnecallContactUser() {
      const self = this;
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.UserContactForm.validate()) {
        try {
          const send1CallToUserEmailMessage = await this.$apollo.mutate({
            mutation: gql`
              mutation($to: ID!, $from: ID!, $message: String!) {
                send1CallToUserEmailMessage(
                  to: $to
                  from: $from
                  message: $message
                )
              }
            `,
            variables: {
              to: this.user.id,
              from: this.me.id,
              message: this.message,
            },
          });
          if (send1CallToUserEmailMessage) {
            self.successMessage = `Message sent to ${this.user.email}`;
          }
          self.isLoading = false;
        } catch (error) {
          if (error.response) {
            self.errorMessage = `${error.response.data}`;
          } else self.errorMessage = `Message not sent: ${error.message}`;
          self.isLoading = false;
        }
      } else {
        self.errorMessage =
          "Message not sent: All required fields must be completed.";
        self.isLoading = false;
      }
    },
    async MedsafuContactUser() {
      const self = this;
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.UserContactForm.validate()) {
        try {
          const sendMedsafuToUserEmailMessage = await this.$apollo.mutate({
            mutation: gql`
              mutation($to: ID!, $from: ID!, $message: String!) {
                sendMedsafuToUserEmailMessage(
                  to: $to
                  from: $from
                  message: $message
                )
              }
            `,
            variables: {
              to: this.user.id,
              from: this.me.id,
              message: this.message,
            },
          });
          if (sendMedsafuToUserEmailMessage) {
            self.successMessage = `Message sent to ${this.user.email}`;
          }
          self.isLoading = false;
        } catch (error) {
          if (error.response) {
            self.errorMessage = `${error.response.data}`;
          } else self.errorMessage = `Message not sent: ${error.message}`;
          self.isLoading = false;
        }
      } else {
        self.errorMessage =
          "Message not sent: All required fields must be completed.";
        self.isLoading = false;
      }
    },
  },
};
</script>

<style lang="sass"></style>
