import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  '@/components/Base', true, /\.vue$/,
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
  )
  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig)
})

//Pickers
const requirePickerComponent = require.context("@/components/Base/Pickers", true, /\.vue$/);

requirePickerComponent.keys().forEach((fileName) => {
  const pickerComponentConfig = requirePickerComponent(fileName);

  const pickerComponentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
  );
  Vue.component(`Base${pickerComponentName}`,pickerComponentConfig.default || pickerComponentConfig);
});