<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      isLoading: false,
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
