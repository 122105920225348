<template>
  <v-autocomplete
    v-model="chosenCategory"
    v-bind="$attrs"
    :items="formattedCategories"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "CategoryPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return null;
      },
    },
    docClass: {
      type: String,
      default: function() {
        return null;
      },
    },
  },
  data: () => ({
    chosenCategory: null,
    totalRecords: 0,
    isLoading: false,
    returnedCategories: [],
    formattedCategories: [],
  }),
  apollo: {
    categories: {
      query: gql`
        query Categories($limit: Int!, $skip: Int!, $query: JSON!) {
          categories(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          skip: 0,
          query: { docClass: this.docClass },
        };
      },
      skip() {
        return !this.docClass;
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedCategories = data.categories.records;
        this.formattedCategories = _.map(
          this.returnedCategories,
          (category) => {
            return { text: `${category.name}`, value: category.id };
          }
        );
      },
    },
  },
  watch: {
    docClass(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.chosenCategory = null;
        this.$apollo.queries.categories.refresh();
      }
    },
    chosenCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-category",
          this.chosenCategory ? this.chosenCategory : null
        );
      }
    },
  },
  async created() {
    this.initial
      ? (this.chosenCategory = this.initial)
      : (this.chosenCategory = null);
  },
  methods: {},
};
</script>

<style lang="sass"></style>
