<template>
  <div>
    <v-row class="">
      <v-col cols="12" sm="12" md="12" lg="12"> </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-select
          :items="picker_items"
          label="What data do you need?"
          item-text="label"
          item-value="label"
          v-model="picker_items_option"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-select
          :items="picker_quiries"
          label="What filter?"
          v-model="picked"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-btn :loading="isLoading" elevation="2" @click="filterOptions()">
          Generate Report
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card class="mt-12">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="isLoading"
              :hide-default-footer="false"
              transition="scale-transition"
              class="new-detault-table client-view"
            >
              <template
                v-slot:top
                v-if="this.picked == 'Clients per consultant'"
              >
                <v-row class="my-6 mx-16" justify="center">
                  <v-col cols="6" sm="6" md="6" lg="6">
                    <v-select
                      v-model="search"
                      :items="branches.records"
                      item-text="name"
                      item.value="id"
                      append-icon="mdi-magnify"
                      :loading="isLoading"
                      :disabled="isLoading"
                      label="Filter By Branch"
                      outlined
                    />
                  </v-col>
                  <!--v-col cols="6" sm="6" md="6" lg="6">
                      <v-select
                        v-model="search"
                        label="Filter By consultant"
                        outlined
                      />
                    </!--v-col-->
                </v-row>
              </template>
              <template v-slot:item.name="{ item }">
                <p color="primary">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.value="{ item }">
                <p color="primary">
                  {{ item.value ? item.value : "N/A" }}
                </p>
              </template>
              <template v-slot:item.rows="{ item }">
                <p color="primary">
                  {{ item.rows }}
                </p>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <download-excel :data="items">
          <v-btn>Download {{ picked }} report</v-btn>
        </download-excel>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Moment from "moment";
import { gql } from "apollo-boost";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

export default {
  name: "AllStatsCard",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    //New Props
    items: [],
    headers: [
      {
        text: "No Data Selected",
        align: "start",
        sortable: false,
        value: "name",
      },
    ],
    //data arrays
    users: "",
    branches: "",
    search: "",

    qrs_set: [],
    qrs_set_val: "",
    the_report: [],

    filter: ["2021-01-01", new Moment(new Date()).format("YYYY-MM-DD")],
    start_show: "none",
    end_show: "none",

    picker_items: [
      {
        label: "clients",
        options: [
          {
            type: "counts",
            qrs: ["Clients per consultant", "Leads data per source"],
          },
          { type: "detailed", qrs: ["", ""] },
          { type: "graph", qrs: ["", ""] },
        ],
      },
      {
        label: "admin",
        options: [
          { type: "counts", qrs: ["Number of users per type", ""] },
          { type: "detailed", qrs: ["", ""] },
          { type: "graph", qrs: ["", ""] },
        ],
      },
      {
        label: "insurers",
        options: [
          { type: "counts", qrs: ["Products per insurer", ""] },
          { type: "detailed", qrs: ["", ""] },
          { type: "graph", qrs: ["", ""] },
        ],
      },
    ],
    picker_items_option: [],
    picker_quiries: [],
    start_show: "none",
    end_show: "none",
    range: "",
    filterby: "",
    picked: "No report Selected",
    showFilter: [],
    filter2: "",

    switched: "",
    // New Changes
    usersGroupedByConsultant: [],
    usersGroupedByProduct: [],
    leadsGroupedBySource: [],
    clientPerConsultant: {},
    leadPerSource: {},
    filterByRole: {},
    productPerInsurer: {},
    isLoading: false,
  }),
  apollo: {
    // clientPerConsultant: {
    //   query: gql`
    //     query clientPerConsultant($limit: Int!, $page: Int!, $query: JSON!) {
    //       clientPerConsultant(limit: $limit, page: $page, query: $query) {
    //         records {
    //           consultant
    //           consultantName{
    //             firstName
    //             lastName
    //           }
    //           users{
    //             firstName
    //             lastName
    //             branch
    //             branchName
    //             createdAt
    //           }
    //         }
    //       }
    //     }
    //   `,
    //   variables() {
    //     const limit = 100000;
    //     const page = 1;
    //     var query = { role: "user" };
    //     return {
    //       limit: limit,
    //       page: page,
    //       query: query,
    //     };
    //   },
    //   watchLoading(isLoading, countModifier) {
    //     this.isLoading = isLoading;
    //   },
    // },
    // leadPerSource: {
    //   query: gql`
    //     query leadPerSource($limit: Int!, $page: Int!, $query: JSON!) {
    //       leadPerSource(limit: $limit, page: $page, query: $query) {
    //         records {
    //           referral
    //           users{
    //             firstName
    //             lastName
    //             branch
    //             branchName
    //             createdAt
    //           }
    //         }
    //       }
    //     }
    //   `,
    //   variables() {
    //     const limit = 100000;
    //     const page = 1;
    //     var query = { role: "lead" };
    //     return {
    //       limit: limit,
    //       page: page,
    //       query: query,
    //     };
    //   },
    //   watchLoading(isLoading, countModifier) {
    //     this.isLoading = isLoading;
    //   },
    // },
    // filterByRole: {
    //   query: gql`
    //     query filterByRole($limit: Int!, $page: Int!, $query: JSON!) {
    //       filterByRole(limit: $limit, page: $page, query: $query) {
    //         records {
    //           role
    //           users{
    //             id
    //             branchName
    //           }
    //         }
    //       }
    //     }
    //   `,
    //   variables() {
    //     const limit = 100000;
    //     const page = 1;
    //     var query = {};
    //     return {
    //       limit: limit,
    //       page: page,
    //       query: query,
    //     };
    //   },
    //   watchLoading(isLoading, countModifier) {
    //     this.isLoading = isLoading;
    //   },
    // },
    // productPerInsurer: {
    //   query: gql`
    //     query productPerInsurer($limit: Int!, $skip: Int!, $query: JSON!) {
    //       productPerInsurer(limit: $limit, skip: $skip, query: $query) {
    //         records {
    //           insurer
    //           insurerName{
    //             name
    //           }
    //           products{
    //             name
    //           }
    //         }
    //       }
    //     }
    //   `,
    //   variables() {
    //     const limit = 100000;
    //     const skip = 0;
    //     var query = {};
    //     return {
    //       limit: limit,
    //       skip: skip,
    //       query: query,
    //     };
    //   },
    //   watchLoading(isLoading, countModifier) {
    //     this.isLoading = isLoading;
    //   },
    // },
    users: {
      query: gql`
        query users($limit: Int!, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
            }
          }
        }
      `,
      variables() {
        const limit = 100000;
        // const page = 1;
        var query = {};
        return {
          limit: limit,
          // page: page,
          query: query,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
    branches: {
      query: gql`
        query Branches($limit: Int!, $skip: Int!, $query: JSON!) {
          branches(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 10000,
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  computed: {},
  watch: {
    picker_items_option() {
      var a = this.picker_items.find(
        ({ label }) => label === this.picker_items_option
      ).options;
      var b = [];
      a.forEach((element, i) => {
        element.qrs.forEach((element2, ii) => {
          if (element2) {
            b.push(element2);
          }
        });
      });
      this.picker_quiries = b;
    },
  },
  methods: {
    async filterOptions() {
      this.items = [];
      if (this.picked == "Clients per consultant") {
        this.headers = [
          {
            text: "Consultant",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Count", align: "center", value: "value" },
          { text: "Client Name", align: "center", value: "rows" },
          { text: "Branch Name", align: "End", value: "branch" },
        ];
        await this.ClientPerCons();
      } else if (this.picked == "Leads data per source") {
        (this.headers = [
          {
            text: "Lead Source",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Count", align: "center", value: "value" },
          { text: "Lead Name", align: "End", value: "rows" },
        ]),
          await this.LeadPerSource();
      } else if (this.picked == "Number of users per type") {
        this.headers = [
          {
            text: "Account Type",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Count", align: "center", value: "value" },
        ];
        await this.AccountPerType();
      } else if (this.picked == "Products per insurer") {
        (this.headers = [
          {
            text: "Insurer",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Count", align: "center", value: "value" },
          { text: "Products Name", align: "End", value: "rows" },
        ]),
          await this.ProductPerInsurer();
      }
    },
    async ClientPerCons() {
      this.the_report = this.clientPerConsultant.records;
      this.the_report.forEach((item) => {
        var consultant = "";
        if (item.consultantName === null || undefined) {
          consultant = "Not Yet Assigned";
        } else {
          consultant =
            item.consultantName.firstName + " " + item.consultantName.lastName;
        }
        var branch = "";
        var users = "";
        item.users.forEach((item) => {
          users += item.firstName + " " + item.lastName + ", ";
          branch += item.branchName + ", ";
        });
        this.items.push({
          name: consultant,
          value: item.users.length,
          rows: users,
          branch: branch,
        });
        return this.items;
      });
    },
    async LeadPerSource() {
      this.the_report = this.leadPerSource.records;
      this.the_report.forEach((item) => {
        var users = [];
        item.users.forEach((item) => {
          users += item.firstName + " " + item.lastName + ", ";
        });
        this.items.push({
          name: item.referral,
          value: item.users.length,
          rows: users,
        });
        return this.items;
      });
    },
    async AccountPerType() {
      this.the_report = this.filterByRole.records;
      this.the_report.forEach((item) => {
        this.items.push({
          name: item.role == "user" ? "client" : item.role,
          value: item.users.length,
        });
        return this.items;
      });
    },
    async ProductPerInsurer() {
      this.the_report = this.productPerInsurer.records;
      this.the_report.forEach((item) => {
        var products = "";
        item.products.forEach((item) => {
          products += item.name + ", ";
        });
        this.items.push({
          name: item.insurerName.name,
          value: item.products.length,
          rows: products,
        });
        return this.items;
      });
    },
  },
};
</script>
<style>
.v-card.v-picker--date.custom_date_picker {
  position: absolute !important;
  z-index: 3 !important;
  margin-top: -20px !important;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 64%) !important;
}
.v-window-item.v-window-item--active {
  min-height: 600px;
}
</style>
