<template>
  <v-dialog v-model="isLoading" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text class="text-center white--text">
        Loading...
        <v-progress-linear indeterminate color="white" class="mb-0" dark />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
