<template>
  <div class="ma-4">
    <p class="ml-2">
      <b>Step 2: Select which product the document belongs to: </b>
    </p>
    <v-autocomplete
      v-model="chosenUserProduct"
      :items="formattedUserProducts"
      small-chips
      color="blue-grey lighten-2"
      clearable
      outlined
      @change="emitSelectedUserProduct"
    />
    <v-progress-linear
      v-if="isLoading"
      color="amber"
      indeterminate
    ></v-progress-linear>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

export default {
  name: "UserProductPicker",
  props: {
    user: {
      type: String,
      default: "",
    },
    company: {
      type: String,
      default: "",
    },
    initial: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chosenUserProduct: null,
      totalRecords: 0,
      isLoading: false,
      returnedUserProducts: [],
      formattedUserProducts: [],
    };
  },
  apollo: {
    userProducts: {
      query: gql`
        query UserProducts($limit: Int!, $skip: Int!, $query: JSON!) {
          userProducts(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              user
              company
              product
              productName
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 600,
          skip: 0,
          query: this.user ? { user: this.user } : {},
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result({ data }) {
        this.returnedUserProducts = data.userProducts.records;
        this.formattedUserProducts = this.returnedUserProducts.map(
          (userProduct) => {
            return { text: userProduct.productName, value: userProduct.id };
          }
        );
      },
    },
  },
  watch: {
    initial: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.formattedUserProducts.length > 0) {
          this.chosenUserProduct = newValue;
        }
      },
    },
    chosenUserProduct(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:chosen-userProduct", newValue || "");
      }
    },
  },
  created() {
    if (this.initial) {
      this.chosenUserProduct = this.initial;
    }
    this.$eventHub.$on("userProduct-updated", this.refreshData);
  },
  methods: {
    refreshData() {
      this.$apollo.queries.userProducts.refetch();
    },
    emitSelectedUserProduct() {
      if (this.chosenUserProduct) {
        this.$emit("selected-user-product", this.chosenUserProduct);
      }
    },
  },
};
</script>

<style lang="sass"></style>
