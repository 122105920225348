<template>
  <v-autocomplete
    v-model="chosenInventoryItemType"
    v-bind="$attrs"
    :items="formattedInventoryItemTypees"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "InventoryItemTypePicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "battery";
      },
    },
  },
  data: () => ({
    chosenInventoryItemType: "personal",
    isLoading: false,
    formattedInventoryItemTypees: [
      { text: "Battery", value: "battery" },
      { text: "Invertor", value: "invertor" },
      { text: "Solar Panel", value: "solar_panel" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenInventoryItemType = this.initial;
    },
    chosenInventoryItemType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-inventory-item-type",
          this.chosenInventoryItemType
            ? this.chosenInventoryItemType
            : "battery"
        );
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass"></style>
