<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <p>Last Week Users</p>
            {{ userSummary.lastWeekUsersCount }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-sheet class="v-sheet--offset mx-auto" color="cyan" elevation="12">
            <v-sparkline
              color="green"
              line-width="2"
              padding="8"
              label-size="16"
            ></v-sparkline>
          </v-sheet>
          <v-card-text>
            <p>Last Month Users</p>
            {{ userSummary.lastMonthUsersCount }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card dark :color="bg_col">
      <v-card-title>
        <v-layout>
          <v-flex xs12 sm6>
            <h1 class="font-weight-light">
              Users | <span class="grey--text">{{ monthTxt }}</span>
            </h1>
          </v-flex>
          <v-flex xs12 offset-sm3 sm3>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="month"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="month"
                  label="Date"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="month" type="month" :color="bg_col">
                <v-spacer></v-spacer>
                <v-btn dark :color="bg_col" @click="modal = false"
                  >Cancel</v-btn
                >
                <v-btn dark :color="bg_col" @click="setMonth(month)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-card-title>
      <line-chart
        :data="userSummary.graphData.usersByDate"
        :colors="[text_col]"
        :library="{
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false, //<- set this
                },
                ticks: {
                  fontColor: text_col,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false, //<- set this
                },
                ticks: {
                  fontColor: text_col,
                },
              },
            ],
          },
        }"
      ></line-chart>
    </v-card>

  </div>
</template>

<script>
import { gql } from "apollo-boost";
import SummaryCard from "@/components/Shared/Cards/Summary";
export default {
  components: {
    SummaryCard,
  },
  data() {
    return {
      bg_col: "#43434c",
      text_col: "#c79477",
      modal: false,
      month: new Date().toISOString().substr(0, 7),
      monthTxt: this.$moment(this.month).format("MMMM"), // To display which month the graph is
      date: Date.now(),
      createdAtRange: {   // To filter the data per month
        gte: this.$moment()
          .startOf("month")
          .unix(),
        lte: this.$moment()
          .endOf("month")
          .unix(),
      },
      userSummary: {
        usersCount: 0,
        lastWeekUsersCount: 0,
        lastMonthUsersCount: 0,
        userCreatedAtData: [],
        graphData: {
          usersByDate: {},
          sparklineWeek: [],
          sparklineTotal: {
            labels: [],
            values: [],
          },
        },
      },
    };
  },
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int!, $skip: Int!, $query: JSON!) {
          users(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              role
              createdAt
            }
            count
          }
        }
      `,
      variables() {
        const self = this;
        return {
          limit: 9999,
          skip: 0,
          query: {
            role: 'lead',// Filters the data to only show leads
          },
        };
      },
      result() {
        var self = this;
        const users = this.users.records.filter(
          (item) =>
            item.createdAt / 1000 >= this.createdAtRange.gte &&
            item.createdAt / 1000 <= this.createdAtRange.lte
        );
        console.log('Loggin users',users) //Displays a list of leads from query

        if (users.length) {
          console.log('users.length',users.length) //If users isnt an empty array
          this.userSummary.usersCount = this.users.count;
          console.log('this.users.count :',this.users.count)
          this.userSummary.lastWeekUsersCount = users.filter( //Filters only the users from last week
            (item) =>
              item.createdAt / 1000 >
              this.$moment()
                .days(-7)
                .unix()
          ).length;
          console.log('this.userSummary.lastWeekUsersCount',this.userSummary.lastWeekUsersCount)
          this.userSummary.lastMonthUsersCount = users.filter( //Filters only the users from last month
            (item) =>
              item.createdAt / 1000 >
              this.$moment()
                .days(-31)
                .unix()
          ).length;

          console.log('this.userSummary.lastMonthUsersCount:',this.userSummary.lastMonthUsersCount)
          
          this.userSummary.userCreatedAtData = _.groupBy(users, (item) =>
            self.$moment.unix(item.createdAt / 1000).format("YYYY-MM-DD")
          );
          console.log('this.userSummary.userCreatedData:',this.userSummary.userCreatedData)
          

          const entries = Object.entries(this.userSummary.userCreatedAtData).reverse();
          console.log('Logging userCreatedAtData ',userCreatedAtData)
          console.log('Logging entries: ',entries)

          this.userSummary.graphData.sparklineTotal.labels = [];
          this.userSummary.graphData.sparklineTotal.values = [];
          for (const [date, values] of entries) {
            this.userSummary.graphData.sparklineTotal.labels.push(
              self.$moment(date).format("DD MMM")
            );
            this.userSummary.graphData.sparklineTotal.values.push(
              values.length
            );
          }

          // this.setDocCountSummary();

          // this.userSummary.totalDocuments = users
          //   .map((val) => val.products.length)
          //   .reduce((a, b) => a + b);

         {this.userSummary.graphData.usersByDate = {}};

          const dim = this.$moment().daysInMonth();
          for (var i = 1; i <= dim; i++) {
            const currentDay = this.$moment(
              this.$moment().year() +
                "-" +
                (this.$moment().month() + 1) +
                "-" +
                i
            ).format("YYYY-MM-DD");
            this.userSummary.graphData.usersByDate[currentDay] = 0;
          }
          console.log('Log users by date',this.userSummary.graphData.usersByDate)

          for (const [date, value] of Object.entries(this.userSummary.userCreatedAtData)) {
            this.userSummary.graphData.usersByDate[date] = value.length
            }
          this.setUsersByDate();
        } 
        else {
          this.userSummary.graphData.usersByDate = []; //Else this array is empty
          this.setUsersByDate();
        }
      },
    },
  },
  methods: {
    setMonth(month) {
      this.$refs.dialog.save(month);
      this.monthTxt = this.$moment(month).format("MMMM");
      this.createdAtRange = {
        gte: this.$moment(month)
          .startOf("month")
          .unix(),
        lte: this.$moment(month)
          .endOf("month")
          .unix(),
      };
      this.$apollo.queries.users.refetch();
    },
    setDocCountSummary() {
      const entries = Object.entries(this.userSummary.userCreatedAtData).reverse();
      this.userSummary.graphData.sparklineTotal.labels = [];
      this.userSummary.graphData.sparklineTotal.values = [];
      for (const [date, values] of entries) {
        this.userSummary.graphData.sparklineTotal.labels.push(
          this.$moment(date).format("DD MMM")
        );
        this.userSummary.graphData.sparklineTotal.values.push(values.length);
      }
    },
    setUsersByDate() {
      const dim = this.$moment().daysInMonth();
      for (var i = 1; i <= dim; i++) {
        const currentDay = this.$moment(
          this.$moment().year() + "-" + (this.$moment().month() + 1) + "-" + i
        ).format("YYYY-MM-DD");
        this.userSummary.graphData.usersByDate[currentDay] = 0;
      }
    for (const [date, value] of Object.entries(
        this.userSummary.userCreatedAtData
      )) {
        this.userSummary.graphData.usersByDate[date] = value.length;
      }  
    }
  } 
};
</script>

<style lang="scss" scoped></style>
