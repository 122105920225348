<template>
  <div>
    <v-text-field
      v-if="!chosenProduct"
      v-model="searchQuery"
      label="Search for a product"
      @click:clear="clearSelectedProduct"
    >
      <!-- Only show the search button if no product is selected -->
      <template #append>
        <v-btn x-small @click="performSearch">Search</v-btn>
      </template>
    </v-text-field>

    <!-- When a product is selected, display it as a chip instead of the text field -->
    <v-text-field v-else>
      <template #prepend-inner>
        <v-chip close @click:close="clearSelectedProduct">{{
          chosenProductText
        }}</v-chip>
      </template>
    </v-text-field>
    <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>

    <!-- Show the v-list only after the search button is clicked and hide it once a product is selected -->
    <div v-if="formattedProducts.length > 0 && !isLoading && !chosenProduct">
      <v-list>
        <v-list-item
          v-for="product in formattedProducts"
          :key="product.value"
          @click="selectProduct(product)"
        >
          <v-list-item-content>{{ product.text }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

export default {
  name: "NewProductPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      searchQuery: "",
      chosenProduct: "",
      chosenProductText: "",
      totalRecords: 0,
      isLoading: false,
      returnedProducts: [],
      formattedProducts: [],
    };
  },
  apollo: {
    products: {
      query: gql`
        query Products($limit: Int!, $skip: Int!, $query: JSON!) {
          products(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              insurerName
              category
              description
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: this.totalRecords > 0 ? this.totalRecords : 250,
          skip: 0,
          query: {
            name: { $regex: this.searchQuery, $options: "i" },
          },
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result({ data }) {
        this.totalRecords = data.products.count;
        this.returnedProducts = data.products.records;
        this.formattedProducts = this.returnedProducts.map((product) => {
          return {
            text: `${product.name} - ${product.insurerName}-${product.category}-${product.description}`,
            value: product.id,
          };
        });
      },
    },
  },
  watch: {
    chosenProduct(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-product",
          this.chosenProduct ? this.chosenProduct : ""
        );
      }
    },
  },
  mounted() {
    if (this.initial) {
      this.chosenProduct = this.initial;
    }
  },
  methods: {
    performSearch() {
      this.refreshData(); // Trigger search
    },
    refreshData() {
      this.$apollo.queries.products.refetch();
    },
    selectProduct(product) {
      this.chosenProduct = product.value;
      this.chosenProductText = product.text;
      this.formattedProducts = []; // Hide the list after selection
      this.searchQuery = ""; // Clear the search query
      this.$emit("selected-product", this.chosenProduct);

      // Fetch the user products after selecting a product
      this.getUserProducts();
    },
    clearSelectedProduct() {
      this.chosenProduct = "";
      this.chosenProductText = "";
      this.searchQuery = "";
    },
  },
};
</script>

<style lang="sass"></style>
