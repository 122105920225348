<template>
  <v-card class="my-0">
    <v-card-text>
      <v-form ref="UserCreateForm" v-model="formValid">
        <base-loading :is-loading="isLoading" />
        <v-alert v-if="successMessage" type="success">
          {{ successMessage }}
        </v-alert>
        <v-alert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </v-alert>

        <v-row>
          <h3 class="ml-8">Medsafu portal Information</h3>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12">
            <base-role-picker
              :label="'Role'"
              :initial="user.role"
              :chosen-role.sync="user.role"
              outlined
            />
          </v-col>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.firstName"
              color="primary"
              outlined
              label="FirstName"
              required
              :rules="[(v) => !!v || 'First name is required']"
            >
              <template #label>
                <span class="red--text">First Name<strong>* </strong></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.lastName"
              color="primary"
              outlined
              label="Surname"
              required
              :rules="[(v) => !!v || 'Last name is required']"
            >
              <template #label>
                <span class="red--text">Last Name<strong>* </strong></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.email"
              color="primary"
              outlined
              label="Email"
              required
              :rules="[
                (v) => !!v || 'Email is required',
                (v) =>
                  /.+@.+\..+/.test(v) || 'Email Address format must be valid',
              ]"
            >
              <template #label>
                <span class="red--text">Email<strong>* </strong></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="user.role === 'user' || user.role === 'lead'">
          <h3 class="ml-8">Client personal Information</h3>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12" md="12">
            <v-text-field
              v-if="user.role === 'user' || user.role === 'lead'"
              v-model="user.idNumber"
              color="primary"
              label="ID Number"
              outlined
              clearable
            />
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field
              v-if="user.role === 'user' || user.role === 'lead'"
              v-model="user.passport"
              color="primary"
              label="Passport Number"
              outlined
              clearable
            />
          </v-col>
        </v-row>
        <v-row class="mx-1">
          <v-col>
            <base-title-picker
              v-if="user.role === 'user' || user.role === 'lead'"
              color="primary"
              :label="'Title'"
              :chosen-title.sync="user.title"
              :initial="user.title"
              v-model="user.title"
              outlined
            />
          </v-col>
          <v-col>
            <base-race-picker
              v-if="user.role === 'user' || user.role === 'lead'"
              :label="'Race'"
              :initial="user.race"
              :chosen-race.sync="user.race"
              outlined
            />
          </v-col>
        </v-row>
        <v-row class="mx-1">
          <base-gender-picker
            v-if="user.role === 'user' || user.role === 'lead'"
            :label="'Gender'"
            :initial="user.gender"
            :chosen-gender.sync="user.gender"
            outlined
          />
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12" md="12">
            <v-textarea
              v-if="user.role === 'user' || user.role === 'lead'"
              v-model="user.fullAddress"
              color="primary"
              label="Address"
              counter
              clearable
              auto-grow
              rows="4"
              outlined
            />
          </v-col>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12" md="6">
            <v-text-field
              v-if="user.role === 'user' || user.role === 'lead'"
              v-model="user.telNumber"
              color="primary"
              label="Telephone Number"
              outlined
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-if="user.role === 'user' || user.role === 'lead'"
              v-model="user.altNumber"
              color="primary"
              label="Alternative Telephone Number"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <h3 class="ml-8" v-if="user.role === 'user' || user.role === 'lead'">
            Insurance Information
          </h3>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              v-if="user.role === 'user' || user.role === 'lead'"
              class="mb-5 ml-2"
              v-model="interestQuestion"
              label="Have you used Medsafu before?"
              outlined
            />
          </v-col>
          <v-col cols="6" v-if="!interestQuestion">
            <base-product-category-picker
              v-if="user.role === 'user' || user.role === 'lead'"
              :chosen-product-category.sync="user.productsInterestedIn"
              label="Which product range are you interested in?"
              multiple
              outlined
            />
          </v-col>
          <v-col cols="6" v-if="interestQuestion">
            <base-product-picker
              v-if="user.role === 'user' || user.role === 'lead'"
              :chosen-product.sync="user.existingProducts"
              label="Which products do you currently own?"
              multiple
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <h3 class="ml-8">Branch Information</h3>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12" md="12">
            <base-branch-picker
              :chosen-branch.sync="user.branch"
              :initial="user.branch"
              label="Branch"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
            v-if="user.role === 'user' || user.role === 'lead'"
          >
            <user-edit-picker
              :label="'Assigned Consultant'"
              :initial="user.consultant"
              :chosen-user.sync="user.consultant"
              :role="'consultant'"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <h3 class="ml-8" v-if="user.role === 'user' || user.role === 'lead'">
            Lead Information
          </h3>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
            v-if="user.role === 'user' || user.role === 'lead'"
          >
            <base-referral-picker
              :label="'Where the lead came from'"
              :initial="user.referral"
              :chosen-referral.sync="user.referral"
              outlined
            />
          </v-col>
          <v-col cols="12" md="12" v-if="user.role === 'lead'">
            <v-text-field
              v-model="user.referralNumber"
              color="primary"
              label="Referral Number"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="!formValid"
        color="primary"
        class="mx-2"
        @click="[save()]"
      >
        Save
      </v-btn>
      <!-- <v-btn
        :disabled="!formValid"
        color="primary"
        class="mx-2"
        @click="refreshPage()"
      >
        Refresh
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { gql } from "apollo-boost";
import UserEditPicker from "./UserEditPicker.vue";
// import moment from 'moment'
export default {
  name: "UserCreate",
  documents: [],
  components: {
    UserEditPicker,
  },
  data: () => ({
    isLoading: false,
    formValid: true,
    interestQuestion: false,
    user: {
      password: "Test1234!!",
      pin: "123456",
      firstName: "",
      lastName: "",
      idNumber: "",
      passport: "",
      fullAddress: "",
      telNumber: "",
      email: "",
      role: "",
      branch: "",
      race: "",
      gender: "",
      title: "",
      referral: "",
      consultant: "",
      productsInterestedIn: "",
      existingProducts: "",
      referralNumber: "",
    },
    successMessage: "",
    errorMessage: "",
  }),

  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      await this.saveUser()
        .then(() => {
          // this.$swal("Success!", "User was created", "success");
          // this.$router.replace(`/admin/users`);
        })
        .catch((e) => {
          console.log(e);
          /*this.$swal(
            "Error!",
            `Something went wrong when creating the User, check data and try again later!`,
            "error"
          );*/
        });
      this.isLoading = false;
    },
    async refreshClientData() {
      this.$apollo.queries.users.refetch();
    },
    async refreshPage() {},
    async saveUser() {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.UserCreateForm.validate()) {
        try {
          const {
            password,
            pin,
            firstName,
            lastName,
            idNumber,
            passport,
            fullAddress,
            telNumber,
            altNumber,
            email,
            role,
            title,
            gender,
            race,
            branch,
            referral,
            consultant,
            productsInterestedIn,
            existingProducts,
            referralNumber,
          } = self.user;
          const createdUser = await this.$apollo.mutate({
            mutation: gql`
              mutation ($user: UserCreateInput!) {
                createUser(user: $user) {
                  id
                }
              }
            `,
            variables: {
              user: {
                password: "Test1234!!",
                pin: "123456",
                firstName,
                lastName,
                idNumber,
                passport,
                fullAddress,
                telNumber,
                altNumber,
                email,
                role,
                branch,
                title,
                gender,
                race,
                referral,
                consultant,
                productsInterestedIn,
                existingProducts,
                referralNumber,
              },
            },
            watchLoading(isLoading, countModifier) {
              self.isLoading = isLoading;
            },
          });
          console.log(createdUser);
          if (!createdUser) {
            return "Something went wrong when creating the user!";
          }
          this.$swal({
            title: "Success!",
            text: "User was created",
            type: "success",
          }).then(function () {});

          self.$eventHub.$emit("user-created");
          const currentRoute = this.$route.path;
          // Emit the event based on the current route
          if (currentRoute === "/admin/users") {
            self.$eventHub.$emit("user-created");
            this.$router.replace(`/admin/users`);
          } else if (currentRoute === "/admin/leads") {
            self.$eventHub.$emit("user-created");
            this.$router.replace(`/admin/leads`);
          } else if (currentRoute === "/admin/admins") {
            self.$eventHub.$emit("user-created");
            window.location.reload();
          } else if (currentRoute === "/consultants/users") {
            self.$eventHub.$emit("user-created");
            this.$router.replace(`/consultants/users`);
          } else if (currentRoute === "/consultant/users/call-back-quoted") {
            self.$eventHub.$emit("user-created");
            this.$router.replace(`/consultants/users`);
          } else if (currentRoute === "/admin/consultants") {
            self.$eventHub.$emit("user-created");
            this.$router.replace(`/admin/users`);
          } else {
            window.location.reload(); // Default to reloading the current route
          }
        } catch (error) {
          this.$swal({
            title: "Error!",
            text: "Something went wrong when creating the User, check data and try again later!",
            type: "error",
          }).then(function () {});
          self.errorMessage = `User Not Created / ${error.message}`;
        }
      } else {
        self.errorMessage = `User Not Created/ All required fields must be completed.`;
      }
    },
  },
};
</script>

<style lang="sass"></style>
