<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="filteredClientsProducts"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row class="mt-4">
            <v-col cols="4">
              <v-select
                class="mt-4"
                v-model="selectedFilter"
                :items="filterOptions"
                label="Filter"
                outlined
                @change="fetchClientsProductsData"
              ></v-select>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-btn color="primary" @click="downloadData">Download </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";

const GET_CLIENTS_PRODUCTS = gql`
  query exportClientsProducts($query: JSON!) {
    exportClientsProducts(query: $query) {
      createdAtDate
      insurerName
      productName
      client
      consultantName
      role
      referralNumber
    }
  }
`;

export default {
  name: "ClientsProducts",
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      isLoading: false,
      clientsProducts: [],
      selectedFilter: ["lead", "user"],
      filterOptions: [
        { text: "All", value: ["lead", "user"] },
        { text: "Existing clients", value: ["user"] },
        { text: "Leads", value: ["lead"] },
      ],
      tableHeaders: [
        { text: "Created At", value: "createdAtDate" },
        { text: "Referral", value: "referralNumber" },
        { text: "Role", value: "role" },
        { text: "Insurer Name", value: "insurerName" },
        { text: "Product Name", value: "productName" },
        { text: "Client", value: "client" },
        { text: "Consultant Name", value: "consultantName" },
      ],
    };
  },
  computed: {
    startDateFormatted() {
      return this.startDate ? this.startDate.toISOString().substr(0, 10) : "";
    },
    endDateFormatted() {
      return this.endDate ? this.endDate.toISOString().substr(0, 10) : "";
    },
    filteredClientsProducts() {
      return this.clientsProducts.filter((item) =>
        this.selectedFilter.includes(item.role)
      );
    },
  },
  mounted() {
    this.fetchClientsProductsData();
  },
  methods: {
    updateStartDateFormatted() {
      this.startDateFormatted = this.startDate
        ? this.startDate.toISOString().substr(0, 10)
        : "";
    },
    updateEndDateFormatted() {
      this.endDateFormatted = this.endDate
        ? this.endDate.toISOString().substr(0, 10)
        : "";
    },
    async fetchClientsProductsData() {
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.query({
          query: GET_CLIENTS_PRODUCTS,
          variables: {
            query: { role: { $in: this.selectedFilter } },
          },
        });
        this.clientsProducts = data.exportClientsProducts;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadData() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Clients Products Data");
      const headers = this.tableHeaders.map((header) => header.text);
      worksheet.addRow(headers);

      this.clientsProducts.forEach((item) => {
        worksheet.addRow([
          item.createdAtDate,
          item.referralNumber,
          item.updatedAtDate,
          item.insurerName,
          item.productName,
          item.client,
          item.consultantName,
          item.role,
        ]);
      });

      workbook.xlsx
        .writeBuffer()
        .then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, "clients_products_data.xlsx");

          swal({
            title: "Success!",
            text: "Excel file has been created and downloaded.",
            icon: "success",
            button: "OK",
          });
        })
        .catch((error) => {
          swal({
            title: "Error!",
            text: "There was an error creating the Excel file.",
            icon: "error",
            button: "OK",
          });
          console.error("Error creating Excel file:", error);
        });
    },
  },
};
</script>

<style scoped>
.elevation-1 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
