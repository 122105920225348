<template>
  <v-card class="mt-3">
    <v-data-table
      :headers="headers"
      :items="userProducts.records"
      :loading="isLoading"
      :search="search"
      transition="scale-transition"
    >
      <template v-slot:top></template>
      <template v-slot:item.productStatus="{ item }">
        <v-switch
          class="mb-1 ml-2"
          v-model="item.productStatus"
          @change="updateUserProductStatus(item)"
        />
      </template>
      <template v-slot:item.documents="{ item }">
        <v-btn
          color="'#0a2752'"
          @click="showEditUserProductDialog(item)"
          medium
          fab
          dark
          class="ma-2"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn
          color="warning"
          medium
          fab
          dark
          @click="showExistingDocumentsDialog(item)"
        >
          <v-icon>mdi-file</v-icon>
          ({{ item.documents.length }})
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="error" fab small @click="deleteItem(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <p>
          <span>Created At:</span>
          <span color="primary">{{ item.createdAt | getFormattedDate }}</span>
        </p>
        <p>
          <span color="primary">Updated At:</span>
          <span color="primary">{{ item.updatedAt | getFormattedDate }}</span>
        </p>
      </template>
      <template v-slot:item.membershipNumber="{ item }">
        <h3 color="primary">
          {{
            item.membershipNumber ? item.membershipNumber : "Not yet Filled in"
          }}
        </h3>
      </template>
      <template v-slot:item.management="{ item }" class="text-center">
        <v-dialog v-model="editUserProductDialog" width="857" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red lighten-2"
              dark
              fab
              v-bind="attrs"
              @click="EditUserProductDialog(item)"
              v-on="on"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Client Products
            </v-card-title>

            <v-card-text>
              <v-form ref="userProductForm">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>Product:</td>
                      <td>
                        <base-product-picker
                          :chosen-product.sync="userProductModel.product"
                          :initial="userProductModel.product"
                          label="Product"
                          required
                          outlined
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="secondary" text @click="save(item)">
                <v-icon left>mdi-content-save</v-icon>Save
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="editUserProductDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { gql } from "apollo-boost";
import { GET_USER_PRODUCTS } from "../../../queries";
import { UserProduct } from "@/models/UserProduct";
export default {
  name: "UserProductTable",

  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: "Product",
          align: "start",
          sortable: false,
          value: "productName",
        },
        {
          text: "Policy Number",
          align: "start",
          sortable: false,
          value: "policyNumber",
        },
        {
          text: "Inactive / Active",
          align: "start",
          sortable: false,
          value: "productStatus",
        },
        {
          text: "Documents",
          sortable: false,
          value: "documents",
        },
        { text: "View Data", value: "management" },
        { text: "Date", value: "createdAt" },

        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      search: "",
      snack: false,
      snackText: "",
      snackColor: "",
      userProducts: [],
      editUserProductDialog: false,
      userProductModel: new UserProduct(),
    };
  },
  created() {
    this.fetchUserProducts();
  },
  methods: {
    async fetchUserProducts() {
      try {
        this.isLoading = true;

        const response = await this.$apollo.query({
          query: GET_USER_PRODUCTS,
          variables: {
            limit: 10,
            skip: 0,
            query: { user: this.userId },
          },
          fetchPolicy: "network-only",
        });

        this.isLoading = false;

        if (
          response &&
          response.data &&
          response.data.userProducts &&
          response.data.userProducts.records
        ) {
          this.userProducts = response.data.userProducts.records;
        }
      } catch (error) {
        console.error("Error fetching user products:", error);
        this.isLoading = false;
        // Handle error scenario
      }
    },

    async EditUserProductDialog(item) {
      this.isEdit = true;
      this.newUserProduct = [];
      this.userProductModel = new UserProduct();
      this.userProductModel.id = item.id;
      this.userProductModel.user = this.userId;
      this.userProductModel.product = item.product;
      this.userProductModel.policyNumber = item.policyNumber;
      this.userProductModel.membershipNumber = item.membershipNumber;
      this.userProductModel.schemeJoin = item.schemeJoin;
      this.userProductModel.benefitDate = item.benefitDate;
      this.userProductModel.dateResigned = item.dateResigned;
      this.userProductModel.dependantCode = item.dependantCode;
      this.userProductModel.beneficiaryType = item.beneficiaryType;
      this.userProductModel.lateJoinerPenalty = item.lateJoinerPenalty;
      this.userProductModel.totalContrib = item.totalContrib;
      this.userProductModel.inceptionDate = item.inceptionDate;
      this.userProductModel.startDate = item.startDate;
      this.userProductModel.cancelDate = item.cancelDate;
      this.editUserProductDialog = true;
    },
    async showEditUserProductDialog(item) {
      this.isEdit = true;
      this.newDocuments = [];
      this.userProductModel = new UserProduct();
      this.userProductModel.id = item.id;
      this.userProductModel.user = this.userId;
      this.userProductModel.product = item.product;
      this.userProductModel.documents = item.documents;
      this.docDialog = true;
    },
    async showExistingDocumentsDialog(item) {
      this.isEdit = true;
      this.newDocuments = [];
      this.userProductModel = new UserProduct();
      this.userProductModel.id = item.id;
      this.userProductModel.user = this.userId;
      this.userProductModel.product = item.product;
      this.userProductModel.documents = item.documents;
      this.existingDocumentsDialog = true;
    },
  },
};
</script>
