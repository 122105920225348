<template>
  <base-stats-card
    :color="'#feb809'"
    title="No. of Resolved events"
    :value="events.count"
    :elevation="8"
    sub-icon="mdi-clock"
    :sub-text="subText"
    :is-loading="isLoading"
  />
</template>

<script>
  import Moment from 'moment'
  import { gql } from 'apollo-boost'
  export default {
    name: 'TotalResolvedEventsStatisticsCard',
    props: {
      initial: {
        type: String,
        default: function () {
          return ''
        },
      },
    },
    data: () => ({
      isLoading: false,
      subText: '',
      events: '',
    }),
    apollo: {
      events: {
        query: gql`query Events($limit: Int! $skip: Int! $query: JSON!) {
          events(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              type
              status
            }
            count
          }
        }`,
        variables () {
          return {
            limit: 10000,
            skip: 0,
            query: {status:'resolved'},
          }
        },
        watchLoading (isLoading, countModifier) {
          this.isLoading = isLoading
        },
        result (data, key) {
          this.subText = 'Updated At ' + new Moment().format('HH:mm:ss')
          
        },
      },
    },
    async created () {
    },
    methods: {
    },
  }
</script>

<style lang="sass">
</style>
