<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn x-small color="primary" @click="dialog = true">
          Create Company
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Company</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="dialogCompanyForm" @submit.prevent="saveFromDialog()">
            <v-row>
              <v-col cols="12">
                <v-switch label="Status" v-model="companyModel.status" />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="companyModel.name"
                  label="Company Name"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Company Name is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="companyModel.companyReg"
                  label="Company Registration Number"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Company Reg is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="companyModel.companyContactPerson"
                  label="Contact Person Full Name"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Contacts fullname is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="companyModel.companyContactPersonEmail"
                  label="Contact's Email"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Contact\'s email is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="companyModel.fullGoogleAddress"
                  label="Companies Address"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Companies address is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="companyModel.notes"
                  outlined
                  counter
                />
              </v-col>
            </v-row>
            <v-btn type="submit" color="success">Save</v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { Company } from "@/models/Company";

export default {
  name: "CreateCompany",
  data() {
    return {
      dialog: false,
      companyModel: new Company(),
      me: "",
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
  },
  methods: {
    async saveFromDialog() {
      this.dialog = false;
      await this.save();
    },
    async save() {
      const self = this;
      if (!self.$refs.dialogCompanyForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        return;
      }
      self.isLoading = true;
      await this.createCompany()
        .then(() => {
          this.$swal("Success!", "Company was created", "success");
          this.$emit("companies-updated");
        })
        .catch((e) => {
          this.$swal(
            "Error!",
            `Something went wrong when creating the company, check data and try again later!`,
            "error"
          );
        });
      self.isLoading = false;
    },
    async createCompany() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createCompany($company: CompanyCreateInput!) {
              createCompany(company: $company) {
                id
              }
            }
          `,
          variables: {
            company: {
              name: self.companyModel.name,
              createdBy: self.me.id,
              status: self.companyModel.status,
              companyContactPerson: self.companyModel.companyContactPerson,
              companyContactPersonEmail:
                self.companyModel.companyContactPersonEmail,
              notes: self.companyModel.notes,
              fullGoogleAddress: self.companyModel.fullGoogleAddress,
              companyReg: self.companyModel.companyReg,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Company Item!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createCompany) {
          self.companyModel.id = response.data.createCompany.id;
          this.$swal(
            "Success!",
            "Company was successfully created, redirecting you",
            "success"
          );
          this.dialog = false;
          this.$emit("companies-updated");
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>
