export class User {
  constructor() {
    this.id = "";
    this.firstName = "";
    this.lastName = "";
    this.title = "";
    this.relationship = "";
    this.gender = null;
    this.race = null;
    this.telNumber = "";
    this.altNumber = "";
    this.email = "";
    this.businessEmail = "";
    this.progress = "";
    this.faxNumber = "";
    this.dob = "";
    this.age = 0;
    this.idNumber = "";
    this.passport = "";
    this.fullAddress = "";
    this.postalCode = "";
    this.region = "";
    this.industry = null;
    this.bio = "";
    this.rating = 0;
    this.branch = "";
    this.branchName = "";
    this.role = "";
    this.consultant = null;
    this.consultantName = "";
    this.consultantEmail = "";
    this.referral = null;
    this.relationship = null;
    this.existingProducts = null;
    this.productsInterestedIn = [];
    this.referralNumber = "";
    this.source = null;
    this.company = "";
    this.companyName = "";
    this.updatedBy = null;
    this.updatedByName = "";
    this.profileStatus = null;
  }

  get fullName() {
    var toArray = [this.firstName, this.lastName];
    return toArray
      .filter((item) => item)
      .join(" ")
      .trim();
  }
}
