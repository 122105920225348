<template>
  <div>
    <v-row class="ma-2">
      <v-col cols="3"></v-col>
      <v-col cols="3">
        <referral-picker
          :label="'Source Picker'"
          :initial="source"
          :chosen-referral.sync="source"
          outlined
          @update:chosen-referral="fetchData"
        />
      </v-col>
      <v-col cols="3">
        <div class="text-right">
          <v-btn class="mb-2" @click="openFilterDialog">Filter by Date</v-btn>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="text-right">
          <v-btn class="mb-2" color="green" @click="downloadExcel"
            >Download</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="filterDialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-form>
            <v-date-picker
              v-model="dateRange"
              range
              label="Date Range"
            ></v-date-picker>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="applyDateFilter">Apply</v-btn>
          <v-btn @click="cancelDateFilter">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="blue"
      class="mb-2"
      dark
    />
    <table class="ma-2">
      <thead>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>Client Name</th>
          <!-- <th>Client Email</th>
          <th>Client Phone</th>
          <th>Client Role</th> -->
          <th>Client Source</th>
          <th>Client Referral Number</th>
          <th>Client Progress</th>

          <th>Consultant Name</th>
          <th>Lead Type</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(task, index) in apolloData" :key="index">
          <td>{{ task.date }}</td>
          <td>{{ task.description }}</td>
          <td>{{ task.clientName }}</td>
          <!-- <td>{{ task.clientEmail }}</td>
          <td>{{ task.clientPhone }}</td>
          <td>{{ task.clientRole }}</td> -->
          <td>{{ task.clientSource }}</td>
          <td>{{ task.clientReferralNumber }}</td>
          <td>{{ task.clientProgress }}</td>

          <td>{{ task.consultantName }}</td>
          <td>{{ task.LeadType }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { gql } from "graphql-tag";
import ReferralPicker from "../Pickers/ReferralPicker.vue";
import ExcelJS from "exceljs";

export default {
  components: { ReferralPicker },
  name: "ReportWithToDo",
  data() {
    return {
      apolloData: [],
      dateRange: [],
      filterDialog: false,
      source: "Medshield",
      startDate: null,
      endDate: null,
      loading: false,
    };
  },
  methods: {
    openFilterDialog() {
      this.filterDialog = true;
    },
    applyDateFilter() {
      if (this.dateRange && this.dateRange.length === 2) {
        this.startDate = this.dateRange[0];
        this.endDate = this.dateRange[1];
      }
      this.fetchData();
      this.filterDialog = false;
    },

    cancelDateFilter() {
      this.filterDialog = false;
    },
    async fetchData() {
      this.loading = true;

      if (this.dateRange && this.dateRange.length === 2) {
        const [start, end] = this.dateRange;
        this.startDate = start;
        this.endDate = end;
      } else {
        const today = new Date();
        const currentYear = today.getFullYear();
        this.startDate = new Date(`${currentYear}-01-01`);
        this.endDate = new Date(today.toISOString().slice(0, 10));
      }

      try {
        const response = await this.$apollo.query({
          query: gql`
            query ReportWithToDoList(
              $startDate: String!
              $endDate: String!
              $source: String!
            ) {
              reportWithToDoList(
                startDate: $startDate
                endDate: $endDate
                source: $source
              ) {
                records {
                  description
                  clientName
                  clientEmail
                  clientPhone
                  clientRole
                  clientSource
                  clientReferralNumber
                  clientProgress
                  date
                  consultantName
                  LeadType
                }
              }
            }
          `,
          variables: {
            startDate: this.startDate,
            endDate: this.endDate,
            source: this.source,
          },
          fetchPolicy: "network-only",
        });
        this.apolloData = response.data.reportWithToDoList.records;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false;
      }
    },
    async downloadExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Report");

      // Define headers and directly set the columns
      const headers = [
        { header: "Description", key: "description", width: 20 },
        { header: "Client Name", key: "clientName", width: 20 },
        { header: "Client Email", key: "clientEmail", width: 20 },
        { header: "Client Phone", key: "clientPhone", width: 20 },
        { header: "Client Role", key: "clientRole", width: 20 },
        { header: "Client Source", key: "clientSource", width: 20 },
        {
          header: "Client Referral Number",
          key: "clientReferralNumber",
          width: 20,
        },
        { header: "Client Progress", key: "clientProgress", width: 20 },
        { header: "Date", key: "date", width: 15 },
        { header: "Consultant Name", key: "consultantName", width: 20 },
        { header: "Lead Type", key: "leadType", width: 15 },
      ];

      worksheet.columns = headers;

      // Add rows from apolloData
      this.apolloData.forEach((task) => {
        worksheet.addRow({
          description: task.description,
          clientName: task.clientName,
          clientEmail: task.clientEmail,
          clientPhone: task.clientPhone,
          clientRole: task.clientRole,
          clientSource: task.clientSource,
          clientReferralNumber: task.clientReferralNumber,
          clientProgress: task.clientProgress,
          date: task.date,
          consultantName: task.consultantName,
          leadType: task.LeadType,
        });
      });

      // Buffer and download the workbook
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Report.xlsx";
      link.click();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
</style>
