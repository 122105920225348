<template>
  <v-dialog v-model="docDialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Upload Documents</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <file-upload :user-product="userProductModel" />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="prepareAndSaveDocuments"> Save </v-btn>
        <v-btn color="secondary" @click="docDialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { gql } from "apollo-boost";
import FileUpload from "./FileUpload.vue";
import { GET_USER_PRODUCTS } from "../../../queries";

export default {
  name: "ProductDocUpload",
  components: {
    FileUpload,
  },
  props: {
    userProduct: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      docDialog: false,
      userProductModel: this.userProduct, // Initialize userProductModel with the received prop
    };
  },
  methods: {
    async prepareAndSaveDocuments() {
      this.isLoading = true;

      if (this.newDocuments) {
        await Promise.all(
          this.newDocuments.map(async (document) => {
            await this.createDocument(document.name, documentAsDataURL);
          })
        );

        this.isLoading = false;
        this.$swal("Success!", "Documents Uploaded", "success");

        // Refetch Apollo query using this.$apollo.query
        const response = await this.$apollo.query({
          query: GET_USER_PRODUCTS,
          variables: {
            limit: 10,
            skip: 0,
            query: {},
            id: this.userProductModel.id,
          },
          fetchPolicy: "network-only",
        });

        // Access the data from the response
        const userProducts = response.data.userProducts;
        console.log(userProducts);
      } else {
        this.isLoading = false;
        console.log("No documents need to be uploaded.");
      }
    },
  },
};
</script>
