<template>
  <div>
    <v-file-input
      ref="fileInput"
      v-model="file"
      accept=".pdf"
      @change="handleFileChange"
      @drop="handleFileDrop"
      @dragover.prevent
      outlined
      label="Drag and drop a PDF file or click here to browse"
      :max-size="maxFileSize"
    ></v-file-input>

    <p v-if="progress !== null && !fileTooLarge">
      Uploading document, please wait...
    </p>

    <v-progress-linear
      v-if="progress !== null && !fileTooLarge"
      :value="progress"
      buffer-color="primary"
    ></v-progress-linear>

    <p v-if="fileTooLarge" class="error-text">
      The uploaded document is too large. Please select a file smaller than
      {{ formatSize(maxFileSize) }}.
    </p>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

export default {
  name: "FileUpload",
  props: {
    userProduct: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      file: null,
      progress: null,
      maxFileSize: 10 * 1024 * 1024, // 10 MB
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          role
        }
      }
    `,
  },
  computed: {
    fileTooLarge() {
      return this.file && this.file.size > this.maxFileSize;
    },
  },
  methods: {
    formatSize(size) {
      const units = ["B", "KB", "MB", "GB", "TB"];
      let unitIndex = 0;
      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }
      return size.toFixed(2) + " " + units[unitIndex];
    },

    handleFileChange() {
      const fileInput = this.$refs.fileInput.$el.querySelector("input");
      const selectedFile = fileInput.files[0];
      if (selectedFile) {
        this.uploadDocument(selectedFile);
      }
    },
    async handleFileDrop(event) {
      event.preventDefault();
      const droppedFile = event.dataTransfer.files[0];
      if (droppedFile) {
        this.uploadDocument(droppedFile);
      }
    },
    async uploadDocument(file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const dataUrl = reader.result;

        try {
          console.log("this.userProduct for document:", this.userProduct);
          console.log("this.userProduct.id for document:", this.userProduct.id);
          this.progress = 0;
          const response = await this.$apollo.mutate({
            mutation: gql`
              mutation createDocument($document: DocumentCreateInput!) {
                createDocument(document: $document) {
                  id
                  name
                  documentLink
                  base64DataString
                  userProduct
                  user
                  createdBy
                }
              }
            `,
            variables: {
              document: {
                name: file.name,
                documentLink: dataUrl,
                base64DataString: dataUrl,
                user: this.$route.params.id,
                userProduct: this.userProduct.id,
                createdBy: this.me.id,
              },
            },
            uploadProgress: (progressEvent) => {
              // Calculate and update the progress
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          });
          if (!response || response.errors) {
            console.log("error", response.errors);
            // this.showSnack("error", "Document not Created");
            throw new Error("Something went wrong when creating the document!");
          }
          console.log(`Uploaded ${file.name} Successfully`);
          this.$emit("document-uploaded", response.data.createDocument); // Emit the event with the document object
          this.progress = null; // Reset progress to null (completed)
        } catch (e) {
          console.error(e);
          // this.showSnack("error", "Document not Created");
        }
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style>
.error-text {
  color: red;
}
</style>
