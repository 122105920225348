<template>
  <div>
    <div v-if="filterModal" class="modal">
      <div class="modal-content">
        <h2 class="modal-title">Date Filter</h2>
        <div class="date-picker">
          <label for="startDate">Start Date</label>
          <input type="date" id="startDate" v-model="startDate" />
        </div>
        <div class="date-picker">
          <label for="endDate">End Date</label>
          <input type="date" id="endDate" v-model="endDate" />
        </div>
        <div class="modal-v-btns">
          <v-btn class="apply-v-btn" @click="fetchReferralCounts">Apply</v-btn>
          <v-btn class="cancel-v-btn" @click="closeFilterModal">Cancel</v-btn>
        </div>
      </div>
    </div>
    <div>
      <table class="referral-table">
        <thead>
          <tr>
            <th>Medshield Lead Source</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="referral in referralCounts" :key="referral.referralNumber">
            <td>{{ referral.referralNumber }}</td>
            <td>{{ referral.count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-right mt-6">
      <v-btn small @click="openFilterModal">Filter</v-btn>
    </div>
  </div>
</template>

<script>
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";

export default {
  name: "ReferralCount",
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const endDate = `${year}-${month}-${day}`;
    return {
      referralCounts: [],
      startDate: "2023-01-01",
      endDate: endDate,
      filterModal: false,
    };
  },
  methods: {
    async fetchReferralCounts() {
      const response = await this.$apollo.query({
        query: gql`
          query CountByReferral($startDate: String!, $endDate: String!) {
            countByReferral(startDate: $startDate, endDate: $endDate) {
              referralNumber
              count
            }
          }
        `,
        variables: {
          startDate: this.startDate,
          endDate: this.endDate,
        },
      });

      this.referralCounts = response.data.countByReferral;
    },
    openFilterModal() {
      this.filterModal = true;
    },
    closeFilterModal() {
      this.filterModal = false;
    },
  },
  mounted() {
    this.fetchReferralCounts();
  },
};
</script>

<style scoped>
.filter-v-btn {
  margin-bottom: 1rem;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.modal-content {
  width: 400px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 4px;
}
.modal-title {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
.date-picker {
  margin-bottom: 1.5rem;
}
.modal-v-btns {
  display: flex;
  justify-content: flex-end;
}
.apply-v-btn {
  margin-left: 1rem;
}
.referral-table {
  width: 100%;
  border-collapse: collapse;
}
.referral-table th,
.referral-table td {
  padding: 0.5rem;
  border: 1px solid #ccc;
}
</style>
