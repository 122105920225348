<template>
  <v-select
    v-model="chosenCompany"
    :items="formattedCompanies"
    color="blue-grey lighten-2"
    outlined
    @change="emitCompanyId"
  />
</template>

<script>
import { gql } from "apollo-boost";

export default {
  name: "AltCompanyPicker",
  props: {
    initial: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chosenCompany: null,
      totalRecords: 0,
      isLoading: false,
      returnedCompanies: [],
      formattedCompanies: [],
    };
  },
  apollo: {
    companies: {
      query: gql`
        query Companies($limit: Int!, $skip: Int!, $query: JSON!) {
          companies(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          limit: 30,
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result({ data }) {
        this.returnedCompanies = data.companies.records;
        this.formattedCompanies = [
          { text: "All companies", value: null },
        ].concat(
          this.returnedCompanies.map((company) => {
            return { text: `${company.name}`, value: company.name };
          })
        );
      },
    },
  },
  watch: {
    initial: {
      immediate: true, // Run the watcher immediately on component mount
      handler(newValue) {
        if (newValue && this.formattedCompanies.length > 0) {
          this.chosenCompany = newValue;
        }
      },
    },
    chosenCompany(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:chosen-company", newValue || null);
      }
    },
  },
  created() {
    if (this.initial) {
      this.chosenCompany = this.initial;
    }
    this.$eventHub.$on("company-updated", this.refreshData);
  },
  methods: {
    refreshData() {
      console.log("Refetching companies");
      this.$apollo.queries.companies.refetch();
    },
    emitCompanyId() {
      this.$emit("company-selected", this.chosenCompany);
    },
  },
};
</script>

<style lang="sass"></style>
