<template>
  <v-autocomplete
    v-model="chosenUser"
    v-bind="$attrs"
    :items="formattedUsers"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "ConsultantUserPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
    role: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenUser: null,
    totalRecords: 0,
    isLoading: false,
    returnedUsers: [],
    formattedUsers: [],
  }),
  apollo: {
    me: gql`
      query {
        me {
          id
          branch
          email
          firstName
          lastName
          role
          branch
          branchName
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              consultant
            }
          }
        }
      `,
      variables() {
        //var query = {};
        /*if (this.role) {
          query = { role: this.role };
        }*/
        return {
          limit: 9999,
          query: { consultant: this.me.id },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedUsers = data.users.records;
        // console.log(this.returnedUsers)
        this.formattedUsers = _.map(this.returnedUsers, (user) => {
          return { text: `${user.firstName} ${user.lastName}`, value: user.id };
        });
      },
    },
  },
  watch: {
    /*initial(newValue, oldValue) {
      this.chosenUser = this.initial;
    },*/
    chosenUser(newValue) {
      this.$router.push(`/consultant/user/${newValue}/edit`);
      console.log("/consultant/user/" + newValue + "/edit");
    },
  },
  async mounted() {},
};
</script>

<style lang="sass"></style>
